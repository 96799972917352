import React, { useEffect , useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from 'primereact/dialog';
import * as yup from "yup";
import { Button } from 'reactstrap';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import config from '../../../config/config';
import DoInputField from '../../Form/Fields/DoInputField';
import DoSelectField from '../../Form/Fields/DoSelectField';
import DoDateField from '../../Form/Fields/DoDateField';
import Signature from '../../Form/Fields/Signature';
import DoTextareaField from '../../Form/Fields/DoTextareaField';

const TreatmentPlanDialog = ({ visible, onHide, selectedRowData, onSave }) => {
    
    const validationSchema = yup.object().shape({
        code: yup.string().required('Code is a required field'),
        description: yup.string().required('Description is a required field'),
        fee: yup.number().typeError('Fee must be a number').required('Fee is a required field'),
        treatmentStatus: yup.string().required('Treatment Status is a required field'),
        dateTp: yup.date().required('Creation date is a required field'),
        teethNumber: yup.string().required('Teeth Number is a Required field'),    
        priority: yup.string().required('Priority is a required field'),    
        provider: yup.object().required('Provider is a required field'),
        referral: yup.string().required('Referral is a required field'),    
        autoNote: yup.string().required('Auto Note is a required field'),    
        // signature: yup.string().required('Signature is a required field'),
        user: yup.string().required('User is a required field'),        
    });

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        formState: { errors },
        control,
    } = useForm(
        {
            resolver: yupResolver(validationSchema)
        }
    );

    useEffect(() => {
        if (selectedRowData) {
            reset({
                description: selectedRowData.description || '',
                treatmentStatus: selectedRowData.treatmentStatus || '',
                dateTp: new Date(selectedRowData.created) || '',
                note: selectedRowData.note || '',
                teethNumber: selectedRowData.teethNumber || '',
                priority: selectedRowData.priority || '',
                code: selectedRowData.code || '',
                fee: selectedRowData.fee || '',
                provider: selectedRowData.provider || null,
                referral: selectedRowData.referral || '',
                autoNote: selectedRowData.autoNote || '',
            });
        }
    }, [selectedRowData, reset]);
    
    const statusOptions = [
        { label: 'Treatment Planned', value: 'Treatment Planned' },
        { label: 'Complete', value: 'Complete' },
        { label: 'Existing-Current Prov', value: 'Existing-Current Prov' },
        { label: 'Existing-other Prov', value: 'Existing-other Prov' },
        { label: 'Referred Out', value: 'Referred Out' },
        { label: 'Condition', value: 'Condition' },
    ];
    const priorityOptions = [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: 'Next', value: 'Next' },
        { label: 'Low', value: 'Low' },
        { label: 'Recmend', value: 'Recmend' },
        { label: 'Wait', value: 'Wait' },
    ]

    const formFields = [
        { name: 'dateTp', type: 'date', show: true, label: 'Date TP', placeholder: "Date TP", id: "dateTp", width: "130px", required: true, disabled: false, dateFormat: "MM-DD-YYYY", fieldType: "Date", },
        { name: 'code', type: 'text', show: true, label: 'Code', placeholder: "Code", id: "code", width: "130px", required: true, disabled: false },
        { name: 'description', type: 'text', show: true, label: 'Description', placeholder: "Description", id: "description", width: "130px", required: true, disabled: false },
        { name: 'teethNumber', type: 'text', show: true, label: 'TTH', placeholder: "TTH", id: "teethNumber", width: "130px", required: true, disabled: false },
        { name: 'fee', type: 'text', show: true, label: 'Fee', placeholder: "Fee", id: "fee", width: "130px", required: true, disabled: false },
        { name: 'priority', type: 'dropdown', options: priorityOptions, show: true, label: 'Priority', placeholder: "Priority", id: "priority", width: "130px", required: true, disabled: false },
        { name: 'treatmentStatus', type: 'dropdown', options: statusOptions, show: true, label: 'Procudure Status', placeholder: "Procudure Status", id: "treatmentStatus", width: "130px", required: true, disabled: false },
        {
            name: 'provider', type: 'relateAutocomplete', show: true, placeholder: "Provider", id: "provider", label: 'Provider', width: "130px", searchApi: "users", searchField: "name", required: true, disabled: false,
            defaultFilterCriteria: [{ "key": 'role', "value": config.doctorRole, "type": "regexOr" }], providerFilter: true,
        },
        { name: 'user', type: 'text', show: true, label: 'User', placeholder: "User", id: "user", width: "130px", required: true, disabled: false },
        { name: 'referral', type: 'text', show: true, label: 'Referral', placeholder: "Referral", id: "referral", width: "130px", required: true, disabled: false },
        { name: 'autoNote', type: 'textarea', show: true, label: 'Notes', placeholder: "Notes", id: "autoNote", width: "130px", required: true, disabled: false },
        { name: 'signature', type: 'signature', show: true, label: 'Signature', placeholder: "Sser", id: "signature", width: "130px", required: true, disabled: false },


    ]
    
    const onSubmit = (data) => {
        onSave(data);
        onHide();
    };
    const dialogFooter = (
        <div className="p-d-flex p-jc-end">
            <Button color="primary" onClick={onHide}>Cancel</Button>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>Update</Button>
        </div>
    );

    let getAutoComplete = (i, item) => {
        const itemTemplate = (option) => {
            const displayText = item.showField
                ? `${option[item.searchField]} (Name: ${option[item.showField]})`
                : option[item.searchField];

            return (
                <div className="autocomplete-item">
                    <div>{displayText}</div>
                </div>
            );
        };

        return (
            <div className='col-md-6 p-1'>
                <Controller
                    name={item.name}
                    rules={{ required: `${item.label} is required` }}
                    control={control}
                    render={({ field, fieldState }) => (
                        <>
                            <DoAutoCompleteField
                                markReq={item.required}
                                input={field}
                                id={field.id}
                                name={field.name}
                                field={field}
                                filterField={item.filterField}
                                filterValue={item.filterValue}
                                filterType={item.isNotEq}
                                multiple={item.isMultiple}
                                fieldState={fieldState}
                                errors={errors}
                                screen={'Users'}
                                searchApi={item.searchApi}
                                itemTemplate={itemTemplate}
                                searchField={item.searchField}
                                // allow={props.allowDuplicates}
                                filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                                placeholder={item.placeholder}
                                label={item.label}
                                item={item}
                                formType={"add"}
                                disabled={item.disabled}
                                populateValue={item.searchField}
                                populateField={item.showField}
                                providerFilter={item.providerFilter && item.providerFilter}
                            />
                        </>
                    )}
                />
            </div>
        )
    }

    let getDate = (i, item) => {

        return (

            <div className='col-md-6 p-1'>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoDateField
                            markReq={item.required}
                            input={field}
                            item={item}
                            label={item.label}
                            id={field.id}
                            name={field.name}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                            placeholder={item.placeholder ? item.placeholder : "date"}
                        //   formType={props.formType}
                        // onChange={onChange} 
                        />)}
                />
            </div>
        )
    }

    let getDropdown = (i, item) => {
        return (
            <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6 `} p-1`}>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoSelectField
                            markReq={item.required}
                            input={field}
                            id={field.id}
                            item={item}
                            name={field.name}
                            field={field}
                            label={item.label}
                            fieldState={fieldState}
                            errors={errors}
                            options={item.options}
                            optionLabel={item.optionLabel ? item.optionLabel : 'label'}
                            placeholder={item.placeholder}
                        // onChange={e => handleFnEnableControlsBasedOnValue2(e, item.dependent)}
                        />)}
                />
            </div>
        )
    }

    let getDefault = (i, item) => {
        return (
            <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6 `} p-1`}>
                <Controller
                    name={item.name ? item.name : null}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoInputField
                            markReq={item.required}
                            input={field}
                            item={item}
                            id={field.id}
                            name={field.name}
                            label={item.label}
                            field={field}
                            type={item.type ? item.type : "text"}
                            fieldState={fieldState}
                            errors={errors}
                            placeholder={item.placeholder ? item.placeholder : null}
                        />)}
                />
            </div>
        )
    }
    const getSignature = (index, item) => {

        return (
            <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6 `} p-1`}>

                <Controller
                    name={item.name ? item.name : null}
                    control={control}
                    render={({ field, fieldState }) => (
                        <Signature
                            item={item}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                        />)}
                />
            </div>
        )
    }

    let getTextArea = (i, item) => {
        return (
          <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6`} p-1`}>
            <Controller
              name={item.name ? item.name : null}
              control={control}
              render={({ field, fieldState }) => (
                <DoTextareaField
                  markReq={item.required}
                  input={field}
                  id={field.id}
                  label={item.label}
                  item={item}
                  rows={5}
                  name={field.name}
                  field={field}
                  fieldState={fieldState}
                  errors={errors}
                  placeholder={item.placeholder ? item.placeholder : null}
                />)}
            />
          </div>
        )
    
      }
    


    // get Individual form field to display
    const getField = (item, i) => {

        return item.type === "relateAutocomplete" ? getAutoComplete(i, item)
            : item.type === "date" ? getDate(i, item)
                : item.type === "dropdown" ? getDropdown(i, item)
                    : item.type === "signature" ? getSignature(i, item)
                        :item.type === "textarea" ? getTextArea(i,item)
                         : item.type === "text" ? getDefault(i, item) : null
    }

    // get form fields
    const getFields = () => {
        return (<div>
            <div className=''>
                <div className='form  mb-2 mt-4'>
                    {formFields && formFields.map(getField)}
                </div>
            </div>
        </div>)
    }

    return (
        <>
            <Dialog
                header="Edit Procedure"
                visible={visible}
                headerClassName="modal-header"
                style={{ width: '65vw' , height:'90vh'}}
                footer={dialogFooter}
                onHide={onHide}
                modal
            >
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>

                    <div
                        className={`row`}
                    >
                        {getFields()}
                    </div>

                </form>













                
                
                
                
               






















                {/* <form>
                <div className="p-fluid">
                    <div className="custom-treatment-plan-form">
                        <div style={{ paddingTop: '10px' }}>
                            <label htmlFor="teethNumber">TTH</label>
                            <Controller
                                name="teethNumber"
                                control={control}
                                render={({ field }) => <InputText {...field} id="teethNumber" disabled />}
                            />
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                            <label htmlFor="priority">Priority</label>
                            <Controller
                                name="priority"
                                control={control}
                                render={({ field }) => <InputText {...field} id="priority" disabled />}
                            />
                        </div>
                        <div>
                            <label htmlFor="code">Code</label>
                            <Controller
                                name="code"
                                control={control}
                                render={({ field }) => <InputText {...field} id="code" disabled />}
                            />
                        </div>
                        <div>
                            <label htmlFor="fee">Fee</label>
                            <Controller
                                name="fee"
                                control={control}
                                render={({ field }) => <InputText {...field} id="fee" disabled />}
                            />
                        </div>
                        <div className='procedures-edit'>
                            {getAutoComplete(1, { name: 'provider', type: 'text', label: 'Provider', searchApi: "users", searchField: "name",
                            defaultFilterCriteria :[{ "key": 'role', "value": config.doctorRole,"type": "regexOr"} ] })}
                        </div>
                        <div>
                            <label htmlFor="referral">Referral</label>
                            <Controller
                                name="referral"
                                control={control}
                                render={({ field }) => <InputText {...field} id="referral" />}
                            />
                        </div>
                        <div className="full-width">
                            <label htmlFor="treatmentStatus">Procedure Status</label>
                            <Controller
                                name="treatmentStatus"
                                control={control}
                                render={({ field }) => (
                                    <Dropdown {...field} options={statusOptions} placeholder="Select a Status" />
                                )}
                            />
                        </div>
                        <div className="full-width">
                            <label htmlFor="note">Notes</label>
                            <Controller
                                name="note"
                                control={control}
                                render={({ field }) => <InputTextarea {...field} id="description" rows={3} />}
                            />
                        </div>
                    </div>
                </div>
            </form> */}
            </Dialog>
        </>);
};
export default TreatmentPlanDialog;