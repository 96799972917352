import React, { useRef, useEffect } from 'react';
import { PDFDocument } from 'pdf-lib';

function PDFViewer({ url, canvasStyle }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    async function loadAndDisplayPDF(url, canvasElement) {
      if (!canvasElement) return;

      try {
        console.log('Fetching PDF from:', url);
        const pdfFetch = await fetch(url);
        if (!pdfFetch.ok) {
          throw new Error(`Failed to fetch PDF: ${pdfFetch.statusText}`);
        }

        const arrayBuffer = await pdfFetch.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);

        const page = pdfDoc.getPage(0);
        const scale = 1.5;
        const viewport = page.getViewport({ scale });
        const context = canvasElement.getContext('2d');
        canvasElement.width = viewport.width;
        canvasElement.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        const renderTask = page.render(renderContext);
        await renderTask.promise;
        console.log('PDF rendered successfully');
      } catch (error) {
        console.error('Error loading or rendering PDF:', error);
      }
    }

    loadAndDisplayPDF(url, canvasRef.current);
  }, [url]);

  return <canvas ref={canvasRef} style={canvasStyle} />;
}

export default PDFViewer;




// import React, { useState } from 'react';
// import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// function PDFViewer({ url }) {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1); // Default to the first page

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }

//   const baseUrl = url.substring(0, url.lastIndexOf("/") + 1);
//   const fileName = url.substring(url.lastIndexOf("/") + 1);
//   const encodedFileName = encodeURIComponent(fileName);
//   const encodedUrl = baseUrl + encodedFileName;

//   console.log("encoded url",encodedUrl)
//   return (
//     <div style={{ marginLeft: '27%' }}>
//       <Document
//         file={encodedUrl}
//         onLoadSuccess={onDocumentLoadSuccess}
//       >
//         <Page pageNumber={pageNumber} />
//       </Document>
//       <p>
//         Page {pageNumber} of {numPages}
//       </p>
//     </div>
//   );
// }

// export default PDFViewer;



// import React, { useRef, useEffect } from 'react';
// import { PDFDocument } from 'pdf-lib';

// function PDFViewer({ url, canvasStyle }) {
//   const canvasRef = useRef(null);

//   useEffect(() => {
//     async function loadAndDisplayPDF(url, canvasElement) {
//       if (!canvasElement) return;

//       try {
//         const pdfFetch = await fetch(url);
//         const arrayBuffer = await pdfFetch.arrayBuffer();
//         const pdfDoc = await PDFDocument.load(arrayBuffer);
//         const page = pdfDoc.getPage(0);
//         const context = canvasElement.getContext('2d');
//         const viewport = page.getViewport({ scale: 1.5 });
//         canvasElement.width = viewport.width;
//         canvasElement.height = viewport.height;

//         const renderContext = {
//           canvasContext: context,
//           viewport: viewport,
//         };

//         await page.render(renderContext).promise;
//       } catch (error) {
//         console.error('Error loading or rendering PDF:', error);
//       }
//     }

//     loadAndDisplayPDF(url, canvasRef.current);
//   }, [url]);

//   return <canvas ref={canvasRef} style={canvasStyle} />;
// }

// export default PDFViewer;

