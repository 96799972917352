import React from "react";
import { Checkbox } from 'primereact/checkbox';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoCheckboxField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    let field = props.field;
    const item = props.item ? props.item : {}
    const onChange = (e) => {
        if (props.onChangeFn) {
            props.onChangeFn(e);
        }
    }

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    return (
        <div className="flex flex-column ">
            <div className="flex align-items-center">
                <Checkbox
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    name={field.name}
                    onChange={(e) => { field.onChange(e.checked); onChange(e) }} />
                <div className="flex align-center">
                    <label htmlFor={id} className="text-capitalize" style={{ paddingLeft: '6px' }}>{label}</label>
                    {props.markReq && props.markReq === true && markRequired()}
                </div>
                {props.fieldState.invalid && props.errors ? props.errors[props.name]?.message : ''}
            </div>
        </div>
    )

}

export default DoCheckboxField;