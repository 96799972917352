import React, { useEffect, useState,useContext, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from 'primereact/dialog';
import { Button as ReactStrapButton, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Button } from 'primereact/button';
import fetchMethodRequest from '../../../config/service';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import { useForm, Controller } from 'react-hook-form';
import apiCalls from '../../../config/apiCalls';
import { useNavigate } from 'react-router-dom';
import PatientNotesDialog from '../../Cruds/CommonModals/PatientNotesModal';
import { GlobalContext } from '../../App/App';

export default function PatientBar() {
    const [value, setPatientValue] = useState(localStorage.PatientData ? JSON.parse(localStorage.getItem('PatientData')) : null);
    const [selectedClinic, setSelectedClinic] = useState(localStorage.ClinicData ? JSON.parse(localStorage.getItem('ClinicData')) : null);
    const [isNotesDialogVisible, setIsNotesDialogVisible] = useState(false);
    const [patients, setPatients] = useState([]);
    const [isPatientDisplay, setIsPatientDisplay] = useState(!selectedClinic);
    let { handleSubmit, register, reset, setValue,watch, getValues, formState: { errors }, control, } = useForm();
    const navigate = useNavigate();
    const loginCredentials = JSON.parse(localStorage.getItem('loginCredentials'));
    const isAdmin = loginCredentials?.role === 'Admin';
    const context = useContext(GlobalContext);

    const menuOnClick = (path) => {
        navigate(`/${path}`);
    }

    useEffect(() => {

        if (value && value._id) {
            setValue('patientId', value);
        }
        if (selectedClinic && selectedClinic._id) {
            setValue('clinicId', selectedClinic);
        }else{
            setClinicsdegfault();
        }
        fetchPatientsData();
    }, []);
    useEffect(() => {
        setIsPatientDisplay(false);
        setTimeout(() => {
            setIsPatientDisplay(true)
        }, 10);
    }, [selectedClinic]);

    const fetchPatientsData = () => {
        fetchMethodRequest('GET', apiCalls.patients).then((res) => {
            if (res && res.patients) {
                const patientData = res.patients;
                setPatients(patientData.map((obj) => { return { label: obj.name, value: obj._id, data: obj } }));
            }
        });
    }

    const setClinicsdegfault = () => {
        let url = apiCalls.clinics
        fetchMethodRequest('GET', url).then((res) => {
            if (res[url] && res[url]) {
                setValue('clinicId', res[url][0]);
                onChange(res[url][0], { localStorageField: 'ClinicData', name: 'clinicId' })
            }
        })
    }

    const onClickRemoveButton = (item) => {
        localStorage.removeItem(item.localStorageField);
        if (item.name == 'patientId') {
            setPatientValue({});
            context.updatePatient('')
        } else {
            setSelectedClinic({});
            context.updateClinic('');
        }
        setValue(item.name, null);

    }

    const onChange = (value, item) => {
        localStorage.setItem(item.localStorageField, JSON.stringify(value));
        if (!value) {
            onClickRemoveButton(item)
        }
        else if (item.name == 'patientId') {
            setPatientValue(value);
            context.updatePatient(value)
        } else {
            context.updateClinic(value);
            setSelectedClinic(value);
            // setPatientDefaultData(value)
            setValue('patientId', null);
            onChange(null, { localStorageField: 'PatientData', name: 'patientId' })
        }
        window.dispatchEvent(new CustomEvent('PatientBar'));
    }

    const setPatientDefaultData = (data) => {
        let patientUrl = apiCalls.patients
        let filterCriteria = { limit: 20, page: 1, criteria: [{ key: 'clinicId', value: data._id, type: 'eq' }], direction: 'desc', softfield: 'created' };
        fetchMethodRequest('GET', `${patientUrl}?filter=${JSON.stringify(filterCriteria)}`).then((response) => {
            if (response[patientUrl] && response[patientUrl].length == 1) {
                setValue('patientId', response[patientUrl][0]);
                onChange(response[patientUrl][0], { localStorageField: 'PatientData', name: 'patientId' })
            } else {
                setValue('patientId', null);
                onChange(null, { localStorageField: 'PatientData', name: 'patientId' })
            }
        });
    }


    let getAutoComplete = (item) => {
        let itemTemplateFunction;

        if (item.populateValue && item.populateField) {
            itemTemplateFunction = (option) => {
                return (
                    <div className="autocomplete-item">
                        <div>{option[item.displayField]}</div>
                    </div>
                );
            };
        }

        return (
            <div className='patient-auto-complete col-2 px-2 d-flex'>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoAutoCompleteField
                            input={field}
                            id={field.id}
                            name={field.name}
                            field={field}
                            item={item}
                            fieldState={fieldState}
                            errors={errors}
                            screen={'PatientNavBar'}
                            searchApi={item.searchApi}
                            itemTemplate={itemTemplateFunction}
                            searchField={item.searchField}
                            filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                            placeholder={item.placeholder}
                            label={item.label}
                            onChange={(value) => onChange(value, item)}
                            populateValue={item.populateValue}
                            populateField={item.populateField}
                            getValues={getValues}
                            disabled={item.disabled}
                        />)}
                />
            </div>
        )
    }

    const openNotesDialog = () => {
        setIsNotesDialogVisible(true);
    };

    const closeNotesDialog = () => setIsNotesDialogVisible(false);

    return (
        <div className='patient-nav-bar-main'>
            {getAutoComplete({ name: 'clinicId',
            //  populateValue: 'clinicId', populateField: 'abbrevation',
              searchApi: apiCalls.clinics, searchField: 'fullClinicName', placeholder: 'Select a Clinic ID - Name', disableLabel: true, style: { height: '1.9rem', lineHeight: '1.9rem' }, localStorageField: 'ClinicData', value: selectedClinic, displayField:"fullClinicName"})}
            {getAutoComplete({
                name: 'patientId', searchApi: apiCalls.patients, searchGlobally: true, searchField: 'fullName',// populateValue: 'patientId', populateField: 'name',
                searchPatternMatch: /^(?=.*[a-zA-Z])[a-zA-Z\d\s'-]{3,}$|(?:(\d{2})(?:[:\/.-])(\d{2})(?:[:\/.-])(\d{4})|(\d{4})(?:[:\/.-])(\d{2})(?:[:\/.-])(\d{2}))$|(?:\+\d{1,3}[- ]?)?(?:\d{3}|\d{4}|\d{5}|\d{10})$/,
                placeholder: 'Select a Patient Name', disableLabel: true, style: { height: '1.9rem', lineHeight: '1.9rem' }, localStorageField: 'PatientData', value: value, hasDependencyField: 'clinicId', fieldForKey: 'clinicId', clearable : true, displayField:"fullName"
            })}

            {(value && value._id) && <>
                <h4 className='col-6 text-center' style={{ fontWeight: 'bolder',textTransform: 'capitalize'}}>Patient Name : {value.name} (Age : {value.age?value.age:0} yrs, {value.gender})</h4>
                <div className='col-2 d-flex justify-content-end'>
                    <div className='p-buttonset d-flex'>
                        <Button onClick={openNotesDialog} title="Notes" className='p-0 d-flex flex-column align-items-center'>
                            <FontAwesomeIcon className='p-0' color='white' icon='sticky-note' size='lg' data-toggle="tool-tip" style={{ width: '3rem' }} />
                            <p className='text-white m-0 d-none d-lg-block' style={{ fontSize: '15px' }}>Notes</p>
                        </Button>
                        <Button onClick={() => menuOnClick('create_appointments')} title="Appointments" className='p-0 d-flex flex-column align-items-center active'>
                            <FontAwesomeIcon
                                className='p-0'
                                color='white'
                                icon='calendar'
                                size='lg'
                                data-toggle="tool-tip"
                                // title="Appointments"
                                style={{ width: '3rem' }}
                            />
                            <p className='text-white m-0 d-none d-lg-block' style={{ fontSize: '15px' }}>App ts</p>
                        </Button>
                        <Button onClick={() => menuOnClick('edit_treatmentPlan/:id')} title="Treatments Plan" className='p-0 d-flex flex-column align-items-center Active'>
                            <FontAwesomeIcon
                                className='p-0'
                                color='white'
                                icon='route'
                                size='lg'
                                data-toggle="tool-tip"
                                // title="Treatments Plan"
                                style={{ width: '3rem' }}
                            />
                            <p className='text-white m-0 d-none d-lg-block' style={{ fontSize: '15px' }}>Tx Plan</p>
                        </Button>

                        <Button onClick={() => menuOnClick('forms')} title="Forms" className='p-0 d-flex flex-column align-items-center'>
                            <FontAwesomeIcon
                                className='p-0'
                                color='white'
                                icon='folder'
                                size='lg'
                                data-toggle="tool-tip"
                                // title="Forms"
                                style={{ width: '3rem' }}
                            />
                            <p className='text-white m-0 d-none d-lg-block' style={{ fontSize: '15px' }}>Forms</p>
                        </Button>
                        <Button onClick={() => menuOnClick('prescriptions')} title="Prescriptions" className='p-0 d-flex flex-column align-items-center'>
                            <FontAwesomeIcon
                                className='p-0'
                                color='white'
                                icon='folder'
                                size='lg'
                                data-toggle="tool-tip"
                                // title="Forms"
                                style={{ width: '3rem' }}
                            />
                            <p className="text-white m-0 d-none d-lg-block" style={{ fontSize: '15px' }}>RX</p>
                        </Button>
                        <Button onClick={() => menuOnClick('insurance')} title="Insurance" className='p-0 d-flex flex-column align-items-center'>
                            <FontAwesomeIcon
                                className='p-0'
                                color='white'
                                icon='hand-holding-medical'
                                size='lg'
                                data-toggle="tool-tip"
                                // title="Forms"
                                style={{ width: '3rem' }}
                            />
                            <p className="text-white m-0 d-none d-lg-block" style={{ fontSize: '15px' }}>INS</p>
                        </Button>
                    </div>

                </div></>}
            <PatientNotesDialog
                isOpen={isNotesDialogVisible}
                onClose={closeNotesDialog}
                patientId={value ? value._id : null}
                patientName={value ? value.name : ''}
                patientObj={value ? value : null}
                isAdmin={isAdmin}
                patients={patients}
            />
        </div>

    );
}
