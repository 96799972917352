import config from "./config";
const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    `${config.imgUrl}sample_employees_bulk_upload_file.csv`,
  activities:
    `${config.imgUrl}sample_activities_bulk_upload_file.csv`,
  emailtemplates:
    `${config.imgUrl}sample_emailtemplates_bulk_upload_file.csv`,
  roles:
    `${config.imgUrl}sample_roles_bulk_upload_file.csv`,
  uploadhistories:
    `${config.imgUrl}sample_uploadhistories_bulk_upload_file.csv`,
  emailstatuses:
    `${config.imgUrl}sample_emailstatuses_bulk_upload_file.csv`,
  users:
    `${config.imgUrl}sample_users_bulk_upload_file.csv`,
  hospitals:
    `${config.imgUrl}sample_hospitals_bulk_upload_file.csv`,
  clinics:
    `${config.imgUrl}sample_clinics_bulk_upload_file.csv`,
  patients:
    `${config.imgUrl}Patients_file.csv`,
  templates:
    `${config.imgUrl}sample_templates_bulk_upload_file.csv`,
  forms:
    `${config.imgUrl}sample_forms_bulk_upload_file.csv`,
  schedules:
    `${config.imgUrl}sample_schedules_bulk_upload_file.csv`,
  chairs:
    `${config.imgUrl}sample_chairs_bulk_upload_file.csv`,
  appointments:
    `${config.imgUrl}sample_appointments_bulk_upload_file.csv`,
  procedures:
    `${config.imgUrl}sample_Procedures_bulk_upload_file.csv`,
  txplans:
    `${config.imgUrl}sample_txplans_bulk_upload_file.csv`,
  treatments:
    `${config.imgUrl}sample_treatments_bulk_upload_file.csv`,
  prescriptions:
    `${config.imgUrl}sample_prescriptions_bulk_upload_file.csv`,
  pharmacies:
    `${config.imgUrl}sample_pharmacies_bulk_upload_file.csv`,
  treatmentplans:
    `${config.imgUrl}sample_treatmentplans_bulk_upload_file.csv`,
  drugs:
    `${config.imgUrl}sample_drugs_bulk_upload_file.csv`,
  reasons:
    `${config.imgUrl}sample_reasons_bulk_upload_file.csv`,
  insurances:
    `${config.imgUrl}sample_insurances_bulk_upload_file.csv`,
  Prescriptions:
    `${config.imgUrl}sample_Prescriptions_bulk_upload_file.csv`,
  Pharmacies:
    `${config.imgUrl}sample_Pharmacies_bulk_upload_file.csv`,
  clockInClockOut:
    `${config.imgUrl}sample_clockinclockout_bulk_upload_file.csv`,
};
export default configSampleFiles;
