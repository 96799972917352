import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Charts from './components/Charts';
const charts = (props, { t }) => (
  <Container>
    <Charts {...props} />
  </Container>
);

charts.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(charts);
