import React, { PureComponent, useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, } from 'reactstrap';
import config from '../../../../config/config';
import FormModal from '../../../Form/FormModal';
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import moment from 'moment';

// import moment
const ReleaseNotes = (props) => {
    const [allData, setAllData] = useState([]);
    const [isOpenFormModal, setIsOpenFormModal] = useState(false);

    useEffect(() => {
        getDataFromServer();
    }, []);

    const getFormFields = () => {
        return [
            {
                name: "versionNumber",
                type: "text",
                placeholder: "Version Number",
                label: "Version Number",
                derivedValue: "versionNumber=undefined",
                capitalizeTableText: false,
                id: "versionNumber",
                required: true,
                show: true,
                mobile: true,
                displayInSettings: true,
                customWidthClass: 'col-sm-6',
                style: {
                    color: "#0e4768",
                    cursor: "pointer",
                    textTransform: "capitalize",
                },
            },
            {
                name: "releaseDate",
                type: "date",
                placeholder: "Release Date",
                label: "Release Date",
                derivedValue: "releaseDate=undefined",
                capitalizeTableText: false,
                id: "releaseDate",
                required: true,
                show: true,
                mobile: true,
                displayInSettings: true,
                customWidthClass: 'col-sm-6',
            },
            {
                required: true,
                show: true,
                value: '',
                type: 'ckeditor',
                name: 'notes',
                label: 'Email Template',
                id: 'emailTemplate',
                placeholder: 'Create Template here'
            },
        ]
    }

    const openReleaseNote = () => {
        setIsOpenFormModal(true);
    }

    const closeFormModal = () => {
        setIsOpenFormModal(false);
    }

    const getDataFromServer = () => {
        fetchMethodRequest('GET', apiCalls.releaseNotes).then(res => {
            if (res[apiCalls.releaseNotes.toLowerCase()]) {
                setAllData(res[apiCalls.releaseNotes.toLowerCase()]);
            }
        })
    }

    return <div>

        <Card>
            <CardBody>
                {/* <div className='d-flex justify-content-end'>
                    <Button color="primary" outline className='deleteModalBtn marginRight' onClick={openReleaseNote}>Add</Button>
                </div> */}
                <h2 className='text-center'> <b>Release Notes</b></h2>

                {allData.map((obj) => <div className="mb-5" style={{ fontSize: '20px' }}>
                    <div><b>Version Number : {obj.versionNumber}</b></div>
                    <div><b>Release Date   : {obj.releaseDate && moment(obj.releaseDate).format(config.dateFormat)}</b></div>
                    {obj.notes && <div dangerouslySetInnerHTML={{ __html: obj.notes }} className='mt-3'></div>}
                </div>)
                }
            </CardBody>
        </Card>

        {isOpenFormModal && <div>
            <FormModal
                onRef={(ref) => formModalRef = ref}
                openFormModal={isOpenFormModal}
                allUsersData={[]}
                tableRowData={{}}
                totalRecords={1}
                first={1}
                rows={1}
                closeFormModal={closeFormModal}
                type={'Pharmacies'}
                tablefieldsToShow={[]}
                originalTableFields={[]}
                formType={'add'}
                // formFields={Pharmacies().getFormFields}
                formFields={getFormFields}
                getDataFromServer={getDataFromServer}
                apiUrl={apiCalls.releaseNotes}
                // role={loginRole}
                filterCriteria={{}}
                menuList={[]}
                // routeTo={apiCalls.prescriptions}
                displayViewOfForm={'modal'}
                actionsTypes={'add'}
                entityType={'users'}
            />
        </div>}
    </div>
}
export default ReleaseNotes;
