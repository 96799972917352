import React ,{useEffect}from "react";
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoSelectField = (props) => {
  const id = props.id ? props.id : props.name;
  const label = props.label ? props.label : props.name;
  const optionLabel = props.optionLabel ? props.optionLabel : 'label';
  const name = props.name;
  const options = props.options;
  const placeholder = props.placeholder;
  let field = props.field;
  const item = props.item ? props.item : {};

  useEffect(() => {
    onChange(field)
  }, [field.value]);

  const itemTemplate = (option) => {
    return option.displayLabel
  }

  let markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
      />
    );
  };

  const onChange = (e) => {
    console.log('/////////////////' , e);
    field.onChange(e.value)
    if (props.onChange) {
      props.onChange(e.value , e)
    }
    if (props.item.dependent) {
      props.handleFnEnableControlsBasedOnValue2(e.value, props.item.dependent)
    }
    if (props.screenName == 'Roles' && name == 'roleType') {
      props.handleFnEnableControlsBasedOnValue(e.value)
    }
  }

  return (
    <div className={`flex flex-column ${item.disableLabel ? 'mt-4' : ''}`}>
      {!item.disableLabel && <div className="flex align-center"> {/* Added a wrapper div with flex class */}
        <label htmlFor={id} className="text-capitalize">{label}</label>
        {props.markReq && props.markReq === true && markRequired()}
      </div>}
      <Dropdown
        value={field.value}
        optionLabel={optionLabel}
        placeholder={placeholder}
        name={name}
        filter={item.filter ? true : false}
        itemTemplate={item.itemTemplate ? itemTemplate : null}
        options={options}
        focusInputRef={field.ref}
        onChange={(e) => field.onChange(e.value)}
        // onChange={onChange}
      />
      <small style={{ marginTop: "5px" }}>{item.alertMessage ? item.alertMessage : ''}</small>
      <small className="text-danger " style={{ marginTop: "5px" }}>{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
    </div>
  )

}

export default DoSelectField;