import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import fetchMethodRequest from '../../../config/service';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import apiCalls from '../../../config/apiCalls';
import CreateSlotModal from '../../Cruds/CommonModals/AddSlot';
import { useForm, Controller } from 'react-hook-form';
import formatDate from '../../UI/FormatDate/formatDate';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage'
import { Calendar } from 'primereact/calendar';
import PaginatorComponent from '../../Cruds/CommonDataTable/PaginatorComponent';
import { GlobalContext } from '../../App/App';

const SchedulesDataTableComponent = () => {
    const [schedules, setSchedules] = useState([]);
    const [selectedSchedules, setSelectedSchedules] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [bulkAction, setBulkAction] = useState(null);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSingleDelete, setIsSingleDelete] = useState(false);
    const [isSingleEdit, setIsSingleEdit] = useState(false);
    const [rowData, setRowData] = useState();
    const [totalRecordsLength, setTotalRecords] = useState();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);
    const [clinicId, setClinicId] = useState();
    const [filterCriteria, setFilterCriteria] = useState({ limit: 10, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
    const context = useContext(GlobalContext);

    let Ref = useRef(null)

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        formState: { errors },
        control,
    } = useForm(
            // {resolver: yupResolver(schema),}
        );

    // useEffect(() => {
    //     let filterCriteria = { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' }
    //     fetchSchedules(filterCriteria);
    // }, []);

    useEffect(() => {
        fetchSchedules();
    }, [context.clinicData]);

    useEffect(() => {
        if (isModalVisible) {
            onBulkActionChange({ value: 'edit' });
        }
    }, [selectedSchedules]);

    const updateSlNoToData = (itemdata, index) => {
        let modifiedData = [];
        itemdata.forEach((item) => {
            index++

            item.Sno = index;
            modifiedData.push(item);

        });
        return modifiedData;
    }

    function fetchSchedules(filter) {
        // let filterCriteria = {
        //     sortfield: "created",
        //     direction: "desc",
        //     criteria: []
        // };
        // filter = filter ? filter : filterCriteria
        filter = filter ? filter : { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' };

        try {
            const clinicData = localStorage.getItem('ClinicData');
            if (clinicData) {
                const parsedClinicData = JSON.parse(clinicData);
                const clinicFilter = {
                    key: "clinicId",
                    value: parsedClinicData._id,
                    type: "eq"
                };
                filter.criteria.push(clinicFilter);
            }
        } catch (error) {
            console.error('Error accessing localStorage:', error);
        }

        const encodedFilter = encodeURIComponent(JSON.stringify(filter));
        const requestUrl = `${apiCalls.schedules}?searchFrom=autoComplete&filter=${encodedFilter}&type=exportToCsv`;
        let limit = filter.limit;
        let page = filter.page;
        fetchMethodRequest('GET', requestUrl)
            .then(response => {
                if (response && response.schedules && response.schedules.length > 0) {
                    const schedulesData = response.schedules.map(schedule => {
                        const scheduleCopy = { ...schedule };
                        const fromTime = new Date(schedule.fromTime);
                        const toTime = new Date(schedule.toTime);

                        scheduleCopy.fromTime = fromTime;
                        scheduleCopy.toTime = toTime;

                        return scheduleCopy;
                    });
                    let schedulesFinalData = updateSlNoToData(schedulesData, limit * (page - 1));
                    setSchedules(schedulesFinalData);
                    if (filter.page === 1) {
                        setTotalRecords(response.pagination && response.pagination.totalCount)
                    }
                    setBulkAction(null);
                } else {
                    setSchedules([]);
                    setTotalRecords(0);
                    setBulkAction(null);
                }
            }).catch((err) => {
                return err;
            })
    };
    const formatDateOnly = (dateString) => {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        const day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
        // return ((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
        return (month + '/' + day + '/' + date.getFullYear());
    }


    const formatRowDate = (dateString) => {
        const date = new Date(dateString);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        const strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    // const serialNumberTemplate = (rowData, { rowIndex }) => {
    //     return rowIndex + 1;
    // };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-text p-button-plain" onClick={() => {onEdit(rowData)}}
                    style={{ color: 'blue', marginRight: '0.1rem' }} />
                <Button icon="pi pi-trash" className="p-button-text p-button-plain" onClick={() => onDelete(rowData)}
                    style={{ color: 'red' }} />
            </React.Fragment>
        );
    };


    const onEdit = (rowData) => {
        setRowData(rowData);
        setIsSingleEdit(true);
        setSelectedSchedules(null)

        let arr= [rowData].map(obj=>obj.handlers._id) 
        setValue('handler', arr)
        setIsModalVisible(true);
    };

    const onDelete = (rowData) => {
        setRowData(rowData);
        setIsSingleDelete(true);
        setIsDialogVisible(true);
        setSelectedSchedules(null);
        setIsModalVisible(false)

        // console.log('Delete clicked for: ', rowData);
        // deleteSelectedSchedules();
    };

    const deleteSelectedSchedules = () => {
        let requestBody;

        if (selectedSchedules) {
            console.log(selectedSchedules);
            const idsToDelete = selectedSchedules.map(schedule => schedule._id);
            requestBody = {
                selectedIds: idsToDelete
            }
            fetchMethodRequest('POST', `${apiCalls.schedules}/multiDelete`, requestBody)
                .then(response => {
                    if (response.respCode) {
                        showToasterMessage(response.respMessage, 'success');
                        setIsDialogVisible(false);
                        setSelectedSchedules(null);
                        setIsModalVisible(false)
                        setBulkAction(null);
                        fetchSchedules();
                    } else if(response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }).catch((err) => {
                    return err;
                })

        }
    };

    const deleteSingleSchedule = async () => {
        fetchMethodRequest('DELETE', `${apiCalls.schedules}/${rowData._id}`)
            .then(response => {
                if (response.respCode) {
                    showToasterMessage(response.respMessage, 'success');
                    setIsDialogVisible(false);
                    setSelectedSchedules(null);
                    setBulkAction(null);
                    fetchSchedules();
            } else if(response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            })
    }

    //Single Edit
    // const editSingleSchedule = async () => {
    //     fetchMethodRequest('PUT', `${apiCalls.schedules}/${rowData._id}`)
    //         .then(response => console.log('edit successful:', response))
    //         .catch(error => console.error('Error during bulk delete:', error));
    //     setIsDialogVisible(false);
    //     await fetchSchedules();
    //     setBulkAction(null);
    // }

    const onBulkActionChange = (e) => {
        const action = e.value;
        setBulkAction(e.value);
        if (action === 'edit' && selectedSchedules) {
            let operative= selectedSchedules.map(obj=>obj.handlers._id) 
            let dublicateOper=[];
            operative.forEach((id)=>{
                if (!dublicateOper.includes(id)) {
                    dublicateOper.push(id);
                }
            })
            setValue('handler', dublicateOper)
            setIsModalVisible(true);
        } else if (action === 'delete') {
            setIsDialogVisible(true);
        }
    };
    const confirmBulkAction = () => {
        if (bulkAction === 'edit' && selectedSchedules && selectedSchedules.length > 0) {
            setIsModalVisible(true);
        } else if (bulkAction === 'delete') {
            deleteSelectedSchedules();
            // setIsDialogVisible(true);
        } else {
            if (isSingleDelete) {
                deleteSingleSchedule();
            } else if (isSingleEdit) {
                // editSingleSchedule();
            }
        }
    };
    const cancelConfirmationModal = () =>{
        setIsDialogVisible(false);
        setBulkAction(null) ;  
        setSelectedSchedules(null)
    }
    const dialogFooter = (
        <div>
            <Button label="No"  onClick={cancelConfirmationModal}  />
            <Button label="Yes"  onClick={confirmBulkAction} autoFocus />
        </div>
    );

    const bulkActionOptions = [
        { label: 'Delete', value: 'delete' },
        { label: 'Edit', value: 'edit' } // Placeholder for future implementation
    ];
    const handleFormSubmit = (data) => {
        console.log("data", data);
        let fromTime = `${data.dateRange[0]}T${data.startTime}:00`;
        let toTime = `${data.dateRange[0]}T${data.endTime}:00`;

        const idsToEdit = isSingleEdit ? rowData && [rowData._id] : selectedSchedules && selectedSchedules.map(schedule => schedule._id);
        const payload = {
            selectedIds: idsToEdit,
            fromTime: formatDate.formatDate(fromTime),
            toTime: formatDate.formatDate(toTime),
            dateRange:data.dateRange
        };

        const requestUrl = `${apiCalls.schedules}/multiupdate`;

        fetchMethodRequest('POST', requestUrl, payload)
            .then(response => {
                if (response.respCode) {
                    showToasterMessage(response.respMessage, 'success');
                    setIsModalVisible(false)
                    fetchSchedules()
                    setSelectedSchedules(null)
                    reset();
                    setBulkAction(null);
                } else {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            })
            .finally(() => {
                fetchSchedules();

            });
    }
    console.log("isModalVisible", isModalVisible);
    const getColumnClass = () => {
        return isModalVisible ? "col-9" : "col-12";
    };

    const getSidebarClass = () => {
        return isModalVisible ? "col-3" : "d-none";
    };

    const onHide = () => {
        setIsModalVisible(false);
        setSelectedSchedules(null);
        setBulkAction(null);
        reset();
    }

    const selectRecordChange = (e) =>{
        if(e.value){
            let data = e.value;
            setSelectedSchedules(data);
            setIsSingleEdit(false) ;
        }

    }

    const dateFilterTemplate = (options) => {
        if(options){
            return <Calendar value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
            />;
        }

    };

    // on Filter Apply
    const onFilterSubmit = (event) =>{
        let data = event.constraints?.constraints;
         data && data.map(obj =>{
            if (obj.matchMode && !obj.matchMode.includes("date")) {
                obj.matchMode = "dateIs";
            }
            return obj
        });
    }

    //on changing pagination
    const onPageChange = async (event) => {
        let filter = { ...filterCriteria };
        if (event && event.rows) {
            let currentPage = event.page + 1;
            filter['limit'] = event.rows;
            filter['page'] = currentPage;
            setRows(event.rows);
            setFirst(event.first);
            //   setPage(event.first);
        }
        fetchSchedules(filter);
    }

    //Get Pagination
    const getPaginator = () => {
        let totalCount = totalRecordsLength === undefined ? 0 : totalRecordsLength
        return (
            <PaginatorComponent
                totalRecords={totalCount}
                first={first}
                rows={rows}
                onPageChange={onPageChange}
                isWeb={true}
            />
        )
    }

    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        'CurrentPageReport': (options) => {
            if (schedules.length === 0) {
                return (
                    <span style={{ color: 'black' }}>
                        Showing 0 to 0 of 0 records
                    </span>
                );
            }
            return (
                <span style={{ color: 'black' }}>
                    Showing {options.first} to {options.first + options.rows > options.totalRecords ? options.totalRecords : options.first + options.rows} of {options.totalRecords} records
                </span>
            );
        }
    };


    return (
        <div>
            <div style={{ marginBottom: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                    <Dropdown
                        value={bulkAction}
                        options={bulkActionOptions}
                        disabled={!selectedSchedules || selectedSchedules?.length == 0}
                        onChange={onBulkActionChange}
                        placeholder="Select Action"
                        style={{ width: '200px' }}
                    />

                </div>
            </div>

            <div className='d-flex justify-content-end' style={{marginRight:"30px"}}>
                    { getPaginator() } 

            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}> {/* Flex container for side-by-side layout */}
                <div className={getColumnClass()} style={{ padding: "15px", marginBottom: "10px" }}> {/* Data table container */}
                    {schedules.length === 0 ? (
                        <p>No schedules available</p>
                    ) : (
                        <DataTable value={schedules}
                            //  paginatorTemplate={paginatorTemplate} paginator rows={20} 
                            totalRecords={totalRecordsLength} paginator={false} scrollHeight='1000px' scrollable={true}
                            selectionMode={'checkbox'} selection={selectedSchedules} onSelectionChange={(e) => { selectRecordChange(e) }} globalFilter={globalFilter} dataKey="_id">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                            <Column header="S.No" field="Sno" headerStyle={{ width: '50px', textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                            <Column field="handlers.name" header="Operatory" headerStyle={{ textAlign: 'center' }} filter bodyStyle={{ textAlign: 'center' }} />
                            <Column field="fromTime"
                                header="Date" headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} body={(rowData) => formatDateOnly(rowData.fromTime)}
                                filter filterField="fromTime" dataType="date" showFilterMatchModes={true} filterElement={dateFilterTemplate} onFilterApplyClick={onFilterSubmit} />
                            <Column field="fromTime" header="From Time" body={(rowData) => formatRowDate(rowData.fromTime)} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                            <Column field="toTime" header="To Time" body={(rowData) => formatRowDate(rowData.toTime)} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                            <Column header="Actions" body={actionBodyTemplate} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center', alignItems: 'center' }} />
                        </DataTable>
                    )}
                </div>
                {isModalVisible && (
                    <div className={getSidebarClass()}> {/* Modal container */}
                        <div className="calendar-form-container">
                            <div className='d-flex schedule-dialog-header'>
                                <div>
                                    <h1 className="calendar-form-header">Update Schedules</h1>
                                </div>
                                <button className="close-icon" onClick={onHide}>
                                    X
                                </button>
                            </div>
                            <form onSubmit={handleSubmit(handleFormSubmit)} className="calendar-form">
                                <CreateSlotModal control={control} disabled={true} getValues={getValues} />
                                <Button type="submit" label="Update" />
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <Dialog visible={isDialogVisible} style={{ width: '450px' }} header="Confirm" modal footer={dialogFooter}
                onHide={() => { setIsDialogVisible(false); }}>
                Are you sure you want to delete the selected records?
            </Dialog>
        </div>
    );
};

export default SchedulesDataTableComponent;
