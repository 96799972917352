import React, { useState, useEffect } from 'react';
import { ListBox } from 'primereact/listbox';
import { Button } from 'reactstrap';
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Modal, ModalHeader, ModalBody,
    Card, CardBody, ButtonToolbar, ButtonGroup, Row, Col, Badge
} from 'reactstrap';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import Loader from '../../../App/Loader';
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSave, faCheck, faAsterisk, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Calendar } from 'primereact/calendar';
import formatDate from '../../../UI/FormatDate/formatDate';
import config from '../../../../config/config';



function AutoNoteSelectModal(props) {
    const { handleSubmit, register, reset, setValue, getValues, formState: { errors }, control, watch } = useForm({
        resolver: yupResolver(yup.object().shape({
            // employer: yup.string().required('Name is Required'),
        })),
    });


    const [selectedNotes, setSelectedNotes] = useState(null);
    const [autoNotes, setAutoNotes] = useState([]);
    const [availablePrompts, setAvailablePrompts] = useState([]);
    const [formFields, setFormFields] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAutoNotes();
        getAvailablePrompts();
    }, []);

    const getAutoNotes = () => {
        const method = "GET";
        const apiUrl = apiCalls.autoNotes;
        return fetchMethodRequest(method, apiUrl).then(async (response) => {
            if (response && response.autonotes && response.autonotes.length > 0) {
                const modifiedAutoNotes = response.autonotes.map(note => ({
                    ...note,
                    modifiedNote: note.text,
                    name: note.name.charAt(0).toUpperCase() + note.name.slice(1)
                }));
                setAutoNotes(modifiedAutoNotes);
            }
        });
    }

    const getAvailablePrompts = () => {
        const method = "GET";
        const apiUrl = apiCalls.availablePrompts;
        return fetchMethodRequest(method, apiUrl).then(async (response) => {
            if (response && response.availableprompts && response.availableprompts.length > 0) {
                setAvailablePrompts(response.availableprompts);
            }
        });
    }


    const submit = (formValues) => {

        if (formValues) {
            const notes = formValues.map(item => {
                if( item && item.type === "Date"){
                    item.value = formatDate.formatDate(item.value , config.dateDBFormat)
                }
                let valueString;
                if (Array.isArray(item.value)) {
                    valueString = item.value.join(',');
                    return `${item.label} : ${valueString ? valueString : ''}`;
                } else {
                    return `${item.label} : ${item.value ? item.value : ''}`;
                }
            }).join('.');
            props.getAutonotesData(notes)
            closeFormModal();
        }
    }

    const getStringBeforeBrace = (str) => {
        return str.split('[')[0];
    };

    const getUpdateIds = (arr) => {
        const arr2 = arr.map((field, i) => {
            field.id = i + 1;
            return field;
        })
        setFormFields(arr2);
    }

    // on Click of Insert Button
    const getNotesData = () => {
        if (selectedNotes) {

            const selectedText = selectedNotes.text.split(',');
            let modifiedNote = [];
            for (let i = 0; i < selectedNotes.availablePromptsIds.length; i++) {
                const id = selectedNotes.availablePromptsIds[i];
                const prompt = availablePrompts.find(prop => prop._id === id);
                let resultObj = {}

                if (selectedText[i]) {
                    const label = getStringBeforeBrace(selectedText[i]);
                    resultObj.label = label
                }
                resultObj.type = prompt.type;
                if (['Single Selection', 'Multi Selection'].includes(prompt.type)) {
                    resultObj.options = prompt.defaultText.split(',').map(str => ({ label: str, value: str }));
                } else if (prompt.type === "Text") {
                    resultObj.value = prompt.defaultText;
                }
                modifiedNote.push(resultObj)

            }
            getUpdateIds([...formFields, ...modifiedNote]);
            setSelectedNotes(null);
        }
    };

    // ON CHANGE OF VALUE
    const onChange = (item, value) => {
        const modifiedFields = formFields;
        const i = formFields.findIndex(obj => obj.id === item.id);
        if (i >= 0) {
            modifiedFields[i].value = value;
            getUpdateIds(modifiedFields);
        }
    }

    const getMultiSelect = (item, i) => {
        return (<div>
            <MultiSelect
                value={item?.value?.length ? item?.value : []}
                onChange={(e) => onChange(item, e.value)}
                options={item.options}
                optionLabel="label"
                placeholder="Select Multiple"
                maxSelectedLabels={3}
                className="w-full md:w-20rem w-100" />
        </div>)
    }

    const getDropdown = (item, i) => {

        return (<div className='col-md-12'>
            <Dropdown
                value={item.value}
                onChange={(e) => onChange(item, e.value)}
                options={item.options}
                optionLabel="label"
                placeholder="Select"
                className="w-full md:w-14rem w-100"
            />
        </div>)
    }

    const getCalender = (item) => {
        return (
            <div className=" col-md-12">
                <Calendar
                    value={item.value}
                    onChange={(e) => onChange(item, e.value)}
                    placeholder="MM-DD-YYYY"
                    className="w-full md:w-14rem w-100"
                />
            </div>
        )
    }

    const getDefault = (item, i) => {
        let { t } = props;
        return (<div>

            <InputText
                value={item.value}
                onChange={(e) => onChange(item, e.target.value)}
                laceholder="Select"
                className="w-full md:w-14rem w-100"
            />

        </div>)
    }

    const closeFormModal = () => {
        props.setIsOpenAutoNotes(false);
    }

    const getModalHeader = () => {
        let { t } = props;
        return (
            <ModalHeader className="modal__header">
                <p className="bold-text modal__title"> Select Auto Note </p>
            </ModalHeader>
        );
    }

    // List Box Data 
    const getNotesList = (e) => {
        const previousText = selectedNotes?.text || '';
        const updatedText = previousText + e.value.text;
        const updatedNotes = { ...e.value, text: updatedText };
        setSelectedNotes(updatedNotes);
    }

    // Delete 
    const getDeleteRecords = (item) => {
        let filterNotes = formFields.filter((val, i) => val.id !== item.id);
        getUpdateIds(filterNotes);
    }


    // DELETE NOTE  
    const changeFieldValues = (item, type) => {

        if (type === "delete") {
            return (<div className='d-flex-justify-content-center'>
                <FontAwesomeIcon
                    className='drugsfield_action_deleteicon'
                    style={{ width: "25", height: "30" }}
                    color={'white'}
                    icon={faTimes}
                    size='lg'
                    title="Remove"
                    data-toggle="tool-tip"
                    onClick={(e) => getDeleteRecords(item)}
                />
            </div>)
        } else {
            if (item.type === "Multi Selection") {
                return getMultiSelect(item, item.id)
            } else if (item.type === "Single Selection") {
                return getDropdown(item, item.id)
            } else if (item.type === "Date") {
                return getCalender(item);
            } else {
                return getDefault(item, item.id)
            }
        }
    }



    const getPromptsTable = () => {

        return (<div className='mt-4 mb-4 scrollable-listbox p-2' style={{ border: "1px solid #dcd6d6" }} >

            <DataTable value={formFields} tableStyle={{ minWidth: '45rem' }} className='no-header custom-no-header'>
                <Column field="label" bodyStyle={{ maxWidth: '80px', whiteSpace: 'normal', wordWrap: 'break-word' }} style={{ width: '30%' }}></Column>
                <Column field="label" body={(item) => changeFieldValues(item)} style={{ width: '40' }}></Column>
                <Column field="Delete" body={(item) => changeFieldValues(item, 'delete')} style={{ width: '2rem' }}></Column>
            </DataTable>
        </div>)
    }

    const getButtonsToolBar = () => {
        return (
            <div className='float-end' style={{ marginRight: "1rem" }}>
                <Button color="primary" className='deleteModalBtn marginRight' onClick={() => submit(formFields)}>Submit</Button>
                <Button color="primary" onClick={props.closeSelectedAutoNotesModal} type='button' className='deleteModalBtn marginRight'>Cancel</Button>
            </div>
        )
    }

    return (
        <div>
            <Dialog
                visible={props.IsOpenAutoNotes}
                onHide={closeFormModal}
                draggable={false}
                style={{ width: '90vw', height: "100vh" }}
                header={getModalHeader()}
                closeOnEscape={false}
            >
                <ModalBody className="modal__body mb-0 pt-4">
                    <Card className='pb-0 cardForListMargin'>
                        <CardBody className='tableCardBody'>
                            <div className='d-flex'>
                                <div className="col-md-4 mt-1 pt-1"> Select Auto Notes
                                    <ListBox
                                        value={selectedNotes}
                                        onChange={(e) => getNotesList(e)}
                                        options={autoNotes}
                                        optionLabel="name"
                                        className="w-full md:w-14rem scrollable-listbox"
                                    />
                                </div>

                                <div className='col-md-2 d-flex justify-content-center align-items-center'>
                                    <Button
                                        color="primary"
                                        onClick={getNotesData}
                                        className='deleteModalBtn marginRight'
                                    >
                                        Insert
                                    </Button>
                                </div>

                                <div className='col-md-6'>

                                    {getPromptsTable()}

                                    {getButtonsToolBar()}

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Dialog>
        </div>
    );
}

export default AutoNoteSelectModal;


