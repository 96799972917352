import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'reactstrap';

import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';
import fetchMethodRequest from '../../../../../config/service';
import apiCalls from '../../../../../config/apiCalls';
import { Button as ReactStrapButton } from 'reactstrap';
import DoDateField from '../../../../Form/Fields/DoDateField';
import DoInputField from '../../../../Form/Fields/DoInputField';
import DoTextareaField from '../../../../Form/Fields/DoTextareaField';
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from 'primereact/dialog';
import DoSelectField from '../../../../Form/Fields/DoSelectField';
import DoRadioButtons from '../../../../Form/Fields/DoRadioButtons';
import DoDecimalField from '../../../../Form/Fields/DoDecimalField';
import Loader from '../../../../App/Loader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import * as yup from "yup";


function AccountsFormModal(props) {

  const { isOpenFormModal, closeFormModal, type, getUrlData } = props;
  const [formFields, setFormFields] = useState([]);
  const [patientId, setPatientId] = useState({});
  const [payloadData, setPayloadData] = useState({});
  const [adjustmentsOptions, setAdjustmentsOptions] = useState([]);
  const [schema, setSchema] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState(props.accounts ? props.accounts : []);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([{ label: 'Cash', value: 'Cash' }]);
  const [completedPayments, setCompletedPayments] = useState();
  const [outStandingCharges, setOutStandingCharges] = useState();
  const [totalOutStandingAmount, setTotalOutStandingAmount] = useState(0);
  const [totalCompletedAmount, setTotalCompletedAmount] = useState(0);

  const adjustmentsData = {
    additions: [
      { label: 'Sales Tax', value: 'Sales Tax' },
      { label: 'Misc Pos Adjustment', value: 'Misc Pos Adjustment' },
      { label: 'BROKEN Appt - No Show', value: 'BROKEN Appt - No Show' },
      { label: 'BROKEN Appt w/o 24 hrs Notice', value: 'BROKEN Appt w/o 24 hrs Notice' },
      { label: 'Returned Check Fee', value: 'Returned Check Fee' },
      { label: 'NSF fee', value: 'NSF fee' },
      { label: 'Finance Charge', value: 'Finance Charge' },
      { label: 'Billing Charge', value: 'Billing Charge' },
      { label: 'Late Charge', value: 'Late Charge' }
    ],
    subtractions: [
      { label: 'Professional Discount', value: 'Professional Discount' },
      { label: 'Cash Discount', value: 'Cash Discount' },
      { label: 'Misc Neg Adjustment', value: 'Misc Neg Adjustment' },
      { label: '$10 Referral Discount', value: '$10 Referral Discount' },
      { label: '5% Cash Discount', value: '5% Cash Discount' },
      { label: '10% Senior Discount', value: '10% Senior Discount' },
      { label: 'New Patient Discount', value: 'New Patient Discount' },
      { label: 'Discount', value: 'Discount' }
    ]
  };

  const PaymentCompleteTableFields = [
    // { field: "", header: "Prov" },  
    { field: "patientName", header: "Patient Name"}, { field: "providerName", header: "Prov"}, { field: "code", header: "Code"}, { field: "fee", header: "Amount" }
  ]

  const outStandingBillsTableFields = [
    // { field: "", header: "Date" },   
    { field: "patientName", header: "Patient Name"}, { field: "providerName", header: "Prov"},  { field: "code", header: "Code" },{ field: "fee", header: "AmtOrig" }, { field: "remaningAmount", type: "RemainingAmount", header: "AmtEnd" }

  ]

  let {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
    control,
    watch
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (type === 'payment') {
      const id = localStorage.HospitalData ? JSON.parse(localStorage.HospitalData)._id : {};
      fetchMethodRequest('GET', `${apiCalls.hospitals}/${id}`).then(res => {
        if (res?.details) {
          const hspData = res.details;
          localStorage.setItem('HospitalData', JSON.stringify(hspData));
          const arr = hspData?.paymentTypes && hspData?.paymentTypes?.length > 0 ? hspData.paymentTypes.map(type => ({ label: type, value: type })) : [{ label: 'Cash', value: 'Cash' }];
          setPaymentTypeOptions(arr);
          setFormFieldFun(type, arr);
        }
      })
      if (props.accounts && props.accounts[0]?.patientTreatment) {
        let completedData = [];
        let outStandingData = [];
        props.accounts[0].patientTreatment.map((item) => {
          if (item.isCompletedPayment === 'Completed') {
            completedData.push(item);
          } else if (item.isCompletedPayment === 'Pending') {
            outStandingData.push(item);
          }
        });
        const totalOutStandingAmount = caluclateTotalAMount(outStandingData, );
        const totalCompletedAmount = caluclateTotalAMount(completedData, );
        setTotalOutStandingAmount(totalOutStandingAmount);
        setTotalCompletedAmount(totalCompletedAmount);
        setCompletedPayments(completedData);
        setOutStandingCharges(outStandingData);
      }
    } else {
      setFormFieldFun(type, paymentTypeOptions);
    }
  }, []);

  const caluclateTotalAMount = (outStandingData) => {
    if (outStandingData && outStandingData.length > 0) {
      let total = 0;
      outStandingData.map((item) => {
        if (item) {
          total += parseInt(item.remaningAmount ? item.remaningAmount : item.fee);
        }
      });
      return total;
    }
  }


  useEffect(() => {
    updateFormFieldsOptions();
  }, [watch('type'), watch('paymentType')]);

  const getFormFields = (paymentTypeOptions) => {
    const todayDate = new Date();
    const patientData = localStorage.PatientData ? JSON.parse(localStorage.PatientData) : {};
    const hspData = localStorage.HospitalData ? JSON.parse(localStorage.HospitalData) : {};

    setPatientId(patientData);
    const allFormFields = [
      { name: 'hospital', value: hspData._id, displayForm: ['payment'] },
      { name: 'entryDate', label: 'Entry Date', required: true, type: 'date', value: todayDate, show: true, displayForm: ['adjustment', 'payment'], disabled: true },
      {
        name: 'patientName', label: 'Patient Name', required: true, type: 'text',
        value: patientData.fullName, //value: patientData.name, 
        show: true, displayForm: ['adjustment', 'payment'], disabled: true
      },
      { name: 'adjustmentDate', label: 'Adjustment Date', required: true, type: 'date', value: todayDate, show: true, displayForm: ['adjustment'], disabled: true },
      { name: 'procedureDate', label: 'Procedure Date', required: true, type: 'date', value: todayDate, show: true, displayForm: ['adjustment'], disabled: true },
      { name: 'amount', type: 'decimal', label: 'Amount', placeholder: 'Enter Amount ', required: true, show: true, displayForm: ['adjustment', 'payment'] },
      { name: 'type', type: 'radio', label: 'Adjustment Type', required: true, options: [{ label: 'Additions', value: 'additions' }, { label: 'Subtractions', value: 'subtractions' }], show: true, displayForm: ['adjustment'] },
      {
        name: 'adjustment', type: 'dropDown', label: 'Adjustment', required: true,
        options: [],
        placeholder: 'Select Adjustment', show: true, displayForm: ['adjustment']
      },
      {
        name: 'paymentType', type: 'dropDown', label: 'Payment Type', required: true,
        options: paymentTypeOptions,
        alertMessage: paymentTypeOptions.every(type => type.value !== 'Paypal') ? 'Please reach out to the Admin to activate PayPal payments' : null,
        placeholder: 'Select a Payment', show: true, displayForm: ['payment']
      },
      { name: 'chequeNumber', type: 'text', label: 'Cheque Number', required: true, placeholder: 'Enter Cheque Number', show: false, displayForm: ['payment'] },
      { name: 'note', type: 'textarea', label: 'Note', placeholder: 'Enter Note', customWidth: 'col-12 p-2', show: true, displayForm: ['adjustment', 'payment'] },
      { name: 'code', show: false, value: 'adjust', displayForm: ['adjustment'] },
      { name: 'tableData', type: 'table', label: 'Note', placeholder: 'Enter Note', customWidth: 'col-12 p-2', show: true, displayForm: ['payment'] },

    ];

    const formFields = allFormFields.filter(field => field.displayForm.includes(type));

    return formFields;
  }

  let getValidations = (field) => {
    if (field.type === 'number') {
      return yup.number().required(`${field.label} is a required field`);
    } else {
      return yup.string().required(`${field.label} is a required field`);
    }
  }

  const setValidations = (fields, setDefvalues) => {
    let valObj = {};
    fields.forEach((field) => {
      if (field.value && setDefvalues) {
        setValue(field.name, field.value)
      }
      if (field.required && field.show) {
        valObj[field.name] = getValidations(field);
      }
    });

    return yup.object().shape(valObj)
  }

  async function updateFormFieldsOptions() {
    const fields = await formFields;
    const i = await fields.findIndex(field => field.name === 'adjustment' || field.name === 'chequeNumber')
    if (i >= 0) {
      if (fields[i].name === 'adjustment') {
        setValue('adjustment', undefined);
        const type = await getValues('type');
        const options = await type ? adjustmentsData[type] : [];
        fields[i].options = await options;
        fields[i].show = false;
        setFormFields([...fields]);
        fields[i].show = true;

      } else if (fields[i].name === 'chequeNumber') {
        setValue('chequeNumber', undefined);
        const paymentType = await getValues('paymentType');
        fields[i].show = paymentType === 'Cheque' ? true : false;
      }
      // setTimeout(() => {
      const tSchema = await setValidations([...fields]);
      setSchema(tSchema);
      setFormFields([...fields]);
      // }, );
    }

  }

  async function setFormFieldFun(type, arr) {
    const formFields = await getFormFields(arr);

    const tSchema = await setValidations(formFields, true);
    setSchema(tSchema);
    setFormFields(formFields);
  }


  let getDate = (i, item) => {

    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoDateField
              markReq={item.required}
              input={field}
              item={item}
              label={item.label}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : "date"}
            />)}
        />
      </div>
    )
  }

  let getDefault = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <DoInputField
                markReq={item.required}
                input={field}
                item={item}
                id={field.id}
                name={field.name}
                label={item.label}
                field={field}
                // type={item.type ? item.type : "text"}
                type={item.type ? item.type : "text"}
                fieldState={fieldState}
                errors={errors}
                defVal={item.value}
                placeholder={item.placeholder ? item.placeholder : null}
              />
            );
          }}
        />
      </div>
    )
  }

  let getTextArea = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoTextareaField
              markReq={item.required}
              input={field}
              id={field.id}
              label={item.label}
              item={item}
              name={field.name}
              field={field}
              defVal={item.value}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }

  let getDropdown = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              markReq={item.required}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
              item={item}
            />)}
        />
      </div>
    );
  };

  let getRadio = (i, item) => {
    return (
      <div key={i} className='h-100'>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoRadioButtons
              options={item.options}
              input={field}
              id={field.id}
              item={item}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              defVal={item.value}
              markReq={item.required}
            />)}
        />
      </div>
    );
  };

  const changeFieldValues = (item, col) => {

    if (col?.type === 'RemainingAmount') {
      let val = item.remaningAmount ? item.remaningAmount : item.fee;
      return <span>{val}</span>
    } 
      return <span>{item[col.field]}</span>
  }

  const getDataTable = (i, item) => {
    console.log('outStandingCharges', outStandingCharges);
    console.log('completedPayments', completedPayments);
    return (
      <div className=' d-flex col-md-12'>
        <div className="col-md-5 m-2">
          <div className="col-md-12 text-center fw-bold" style={{ background: "#f0ecec" }}>Current Payment Splits</div>
          <div style={{ height: '300px', overflowY: 'auto' }}>
            <DataTable value={completedPayments} scrollable={true} scrollHeight='300px'>

            {PaymentCompleteTableFields.map((col) => {
              return (
                <Column field={col.field} header={col.header} style={{ textAlign: 'center' }} />
              )
            }
            )}
          </DataTable>
          </div>
          <div className='col-md-11 text-end mt-2'> <span className='fw-bold'>{`Total: ${totalCompletedAmount ? totalCompletedAmount : 0}`}</span></div>
        </div>

        <div className="col-md-7 m-2">
          <div className="col-md-12 text-center fw-bold" style={{ background: "#f0ecec" }}>Outstanding Charges</div>
          <div style={{ height: '300px', overflowY: 'auto' }}>
          <DataTable value={outStandingCharges} scrollable={true} scrollHeight='300px' >
            {
              outStandingBillsTableFields.map((col) => {
                return (
                  <Column header={col.header}
                    field={col.field} body={(item) => changeFieldValues(item, col)} style={{ textAlign: 'center' }} />
                )
              }
              )
            }
          </DataTable>
          </div>
          <div className='col-md-11 text-end mt-2'> <span className='fw-bold'>{`Total: ${totalOutStandingAmount ? totalOutStandingAmount : 0}`}</span></div>
        </div>
      </div>
    );
  }

  /**
   * 
   * @param {Object} item 
   * @param {Number} index 
   */
  const getDecimal = (index, item) => {
    return <div>
      <Controller
        name={item.name ? item.name : null}
        control={control}
        render={({ field, fieldState }) => (
          <DoDecimalField
            name={item.name ? item.name : null}
            item={item}
            field={field}
            fieldState={fieldState}
            errors={errors}
          />)}
      />
    </div>
  }

  const modalFooter = () => (
    <div className="d-flex justify-content-end w-100">
      <React.Fragment >
        <ReactStrapButton color="primary" className="me-2" onClick={closeFormModal}>Cancel</ReactStrapButton>
        <ReactStrapButton color="primary" type='submit'>{getValues('paymentType') === 'Paypal' ? 'Send request' : 'Submit'}</ReactStrapButton>
      </React.Fragment>
    </div>
  );

  const getPaymentStatus = (values) => {
    if (values) {
      let userAmount = parseInt(values.amount);
      let initialArray = [];
      if (props.accounts) {
        props.accounts.map((item) => {
          if (item.patientTreatment && item.patientTreatment.length > 0) {
            initialArray = [...initialArray, ...item.patientTreatment];
          }
        });
      }
      let outPutArray = initialArray.map((item) => {
        if (item.isCompletedPayment && item.isCompletedPayment === "Pending") {
          // let amount = (item.fee ? item.fee : item.amount); // required for Adjustment Data
          if (item.remaningAmount ? item.remaningAmount : item.fee <= userAmount) {
            let result = { ...item, isCompletedPayment: 'Completed' };
            userAmount -= item.remaningAmount ? item.remaningAmount : item.fee;
            delete result.remaningAmount
            return result;
          } else if (userAmount > 0) {
            let result = { ...item, remaningAmount: item.fee - userAmount, isCompletedPayment: 'Pending' };
            userAmount = 0;
            return result
          } else {
            return { ...item, isCompletedPayment: 'Pending' };
          }
        }
        else {
          return item;
        }
      })
      return outPutArray;

    }

  }

  const submit = (values) => {
    let treatmentProcedures = {};
    formFields.map((field) => {
      if (values[field.name]) {
        if (field.type === 'date') {
          values[field.name] = (new Date(values[field.name])).toISOString();
        }
      }
    });
    if (type === 'adjustment') {
      values[values.type === 'additions' ? 'amountCharges' : 'amountCredits'] = values.amount;
    } else if (type === 'payment') {
      let patientTreatment = getPaymentStatus(values);
      treatmentProcedures['patientTreatment'] = patientTreatment
      values['paymentAmount'] = values.amount;
    }
    const data = getUrlData(type);
    values = { ...values, ...data.addData }
    const payload = { ...values, patient: patientId._id };//{ [data.parentField]: values, patientId };
    if (type === 'payment' && values.paymentType === 'Paypal') {
      setPayment(payload);
      // setPayment({ ...values, patient: patientId._id });
      return;
    }
    saveDataToServer({ [data.parentField]: payload, patientId, ...treatmentProcedures }, data);
  }

  const serverRes = (res) => {
    if (res?.respCode) {
      showToasterMessage(res?.respMessage, 'success');
      closeFormModal();
    } else {
      showToasterMessage(res?.errorMessage, 'error');
    }
  }

  function saveDataToServer(payload, data) {
    fetchMethodRequest(data.method, data.url, payload).then(serverRes);
  }

  function setPayment(data) {
    // setIsLoading(true);
    data.currency = "USD";
    fetchMethodRequest('POST', apiCalls.requestPayment, data).then(res => {
      if (res.override) {
        setPayloadData(res);
      } else {
        serverRes(res);
      }
      // if (res?.link) {
      //   window.location.href = res.link;
      // }

      // setIsLoading(false);
    })

  }

  const getField = (item, i) => {
    if (!item.show) {
      return;
    }
    return <div className={`${item.customWidth ? item.customWidth : 'col-6 p-2'}`}>{
      item.type === 'date' ? getDate(i, item)
        : item.type === 'dropDown' ? getDropdown(i, item)
          : item.type === 'radio' ? getRadio(i, item)
            : item.type === 'textarea' ? getTextArea(i, item)
              : item.type === 'decimal' ? getDecimal(i, item)
                : item.type === "table" ? getDataTable(i, item)
                  : getDefault(i, item)
    }</div>
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit(submit)}>
        <div className='d-flex flex-wrap'>
          {formFields && formFields.map(getField)}
          {modalFooter()}
        </div>
      </form>
    );
  }


  const getConfirmationModal = () => {

    return <Dialog visible={true} className='payment_loading_modal'
      headerStyle={{ display: 'none' }}
      header=""
    // headerStyle={{ backgroundColor: 'white' }}
    // style={{ borderRadius: '20%' }}
    >
      <br />
      <div>Are you sure, do you want to override the existing payment.</div>
      <br />
      <Button outline color="primary" onClick={() => setPayloadData({})} className='deleteModalBtn marginRight'>No</Button>
      <Button outline color="primary" onClick={() => setPayment(payloadData)} className='deleteModalBtn'>Yes</Button>
    </Dialog>
  }

  return <div>
    <Dialog draggable={true} visible={isOpenFormModal} style={{ width: '90vw' }} header={type} headerStyle={{ textTransform: 'capitalize' }} onHide={closeFormModal}>
      {renderForm()}
    </Dialog>
    {(payloadData?.patient && payloadData?.override && payloadData?.paymentType === 'Paypal') && getConfirmationModal()}
  </div>
    ;

}

export default AccountsFormModal;
