import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from '../../App/Loader';
import { Button } from 'primereact/button';
import * as yup from "yup";
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import CustomTimePicker from '../../Form/Fields/CustomTimePicker';
import DoDateField from '../../Form/Fields/DoDateField';
import apiCalls from '../../../config/apiCalls';
import { TabView, TabPanel } from 'primereact/tabview';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import formatDate from '../../UI/FormatDate/formatDate';
import fetchMethodRequest from '../../../config/service';
import { object } from 'prop-types';
import moment from 'moment';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AppointmentForm from './AppointmentForm';
import WeekendConfirmModal from './WeekendConfirmModal';
import config from '../../../config/config';

// import React, { forwardRef } from 'react';


function AddAppointmentsData(props) {


    const {
        handleSubmit,
        register,
        reset,
        watch,
        setValue,
        getValues,
        formState: { errors },
        control,
    } = useForm(
        {
            resolver: yupResolver(yup.object().shape({
                patientId: yup.object().required('Patient Name is a required field'),
                reasons:  yup.object().required('Services is a required field') ,
                provider: yup.object().required('Provider is a required field'),
                eventDate: yup.string().required('Date is a required field'),
                startTime: yup.string().required('From Time is a required field'),
                // endTime: yup.string().required('End Time Is Required'),

                endTime: yup.string().required('To Time is a required field').test('time-range', 'To time must be greater than the from time.', function (value) {
                    const { startTime } = getValues('startTime')
                    return validateTimeRange(value);
                })


            })),
        }
    );
    const patientId = watch('patientId');
    let [isLoading, setIsLoading] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [formType, setFormType] = useState(props.formType);
    const [proceduresData, setProceduresData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [showWeekendConfirmModal, setShowWeekendConfirmModal] = useState(false);
    const [formValues, setFormValues] = useState();
    const [confirmStatus, setConfirmStatus] = useState(null);
    const [clinicData, setClinicData] = useState(null);

   
    const colorNames = {
        '#D32F2F': 'Red',
        '#C2185B': 'Pink',
        '#7B1FA2': 'Purple',
        '#512DA8': 'Dark Purple',
        '#303F9F': 'Indigo',
        '#1976D2': 'Blue',
        '#0288D1': 'Light Blue',
        '#0097A7': 'Cyan',
        '#00796B': 'Teal',
        '#388E3C': 'Green',
        '#689F38': 'Lime',
        '#AFB42B': 'Yellow',
        '#F57C00': 'Orange',
        '#E64A19': 'Deep Orange',
        '#5D4037': 'Brown',
        '#795548': 'Dark Brown',
        '#004D40': 'Deep Teal',
        '#1B5E20': 'Dark Green',
        '#BF360C': 'Deep Red',
        '#3E2723': 'Dark Sienna',
        '#212121': 'Charcoal',
        '#000080': 'Navy Blue',
        '#8B0000': 'Dark Red',
        '#006400': 'Dark Green'
    };

    const nameToHex = Object.entries(colorNames).reduce((acc, [hex, name]) => {
        acc[name.toLowerCase()] = hex;
        return acc;
    }, {});

    const statusOptions = [
        { label: 'Unconfirmed', value: 'unconfirmed', color: '#FF6347' }, // Tomato
        { label: 'Confirmed', value: 'confirmed', color: '#FF4500' }, // Orange Red
        { label: 'Arrived', value: 'arrived', color: '#32CD32' }, // Lime Green
        { label: 'Ready', value: 'ready', color: '#FFD700' }, // Gold
        { label: 'In Room', value: 'inroom', color: '#1E90FF' }, // Dodger Blue
        { label: 'Checkout', value: 'checkout', color: '#FF69B4' }, // Hot Pink
        { label: 'Left Msg', value: 'leftmsg', color: '#8A2BE2' }, // Blue Violet
        { label: 'Bad Num', value: 'badnum', color: '#A52A2A' }, // Brown
        { label: 'E-mailed', value: 'emailed', color: '#20B2AA' }, // Light Sea Green
        { label: 'Texted', value: 'texted', color: '#FFA500' }, // Orange
        { label: 'E-Confirm Sent', value: 'econfirmsent', color: '#778899' }, // Light Slate Gray
        { label: 'E-Confirm Call', value: 'econfirmcall', color: '#DB7093' }, // Pale Violet Red
        { label: 'E-Confirm Fault', value: 'econfirmfault', color: '#2E8B57' }, // Sea Green
        { label: 'Web Sched', value: 'websched', color: '#6A5ACD' }, // Slate Blue
        { label: 'Out The Door', value: 'outthedoor', color: '#FF4500' } // Orange Red
    ];
    const formFields = [
        { name: 'patientId', type: 'relateAutocomplete',placeholder:'Patient Name', show: true, label: 'Patient Name', width: "130px", searchApi: "patients", searchField: "fullName", required: true, showField: "firstName", disabled: false, 
        // populateValue: 'patientId', populateField: 'name', 
        defaultFilterCriteria:clinicData ? [{key:"clinicId", value:clinicData._id, type:'eq'}]:[]},
        { name: "eventDate", type: "date", placeholder: "From Date", label: "Date", width: "130px", id: "fromDate", required: true, controllerId: null, dateFormat: "MM-DD-YYYY", show: true, minDate:true},
        { name: 'startTime', type: 'time', label: 'From Time', id: 'startTime' , required: true, },
        { name: 'endTime', type: 'time', label: 'To Time', id: 'endTime' , required: true,},
        { name: 'provider', type: 'relateAutocomplete', show: true, placeholder:"Provider" ,  label: 'Provider', width: "130px", searchApi: "users", searchField: "name", required: true, disabled: false , providerFilter: true, 
        defaultFilterCriteria :[{ "key": 'role', "value": config.doctorRole,"type": "regexOr"} ]
        },
        { name: 'reasons', type: 'relateAutocomplete', show: true, label: 'Services', placeholder:"Services" ,  width: "130px", searchApi: "reasons", searchField: "reasons", required: true, disabled: false },
        { name: 'confirmationStatus', type: 'confirmationStatus', show: true, label: 'confirmationStatus', width: "130px", },
    ]

    useEffect(() => {

        if (props.selectedRowData) {
            setValue('patientId', JSON.parse(localStorage.getItem('PatientData')) ? JSON.parse(localStorage.getItem('PatientData')) : props.selectedRowData.patientId ? props.selectedRowData.patientId : null , { shouldValidate: props.formType=== "updateAppointment"  });
            setValue('provider', props.selectedRowData.provider ? props.selectedRowData.provider : null,  { shouldValidate: props.formType=== "updateAppointment"  });
            setValue('reasons', props.selectedRowData.reasons ? props.selectedRowData.reasons : null);
            setValue('eventDate', props.selectedRowData.start ? props.selectedRowData.start : props.eventDate);
            setValue('startTime', props.selectedRowData.start ? props.selectedRowData.start : props.startTime);
            setValue('endTime', props.selectedRowData.end ? props.selectedRowData.end : props.endTime)
           
            setSelectedColor(props.selectedRowData.color ? nameToHex[props.selectedRowData.color.toLowerCase()] : null);
            setSelectedPatient(props.selectedRowData.patientId);
        }
        setSelectedColor(props.selectedRowData.color ? nameToHex[props.selectedRowData.color.toLowerCase()] : null);
        setSelectedPatient(props.selectedRowData.patientId);

    }, [props.selectedRowData]);

    useEffect(() => {
        fetchProcedures();
    }, []);
    
    useEffect(() => {     
        const clinicData = JSON.parse(localStorage.getItem('ClinicData'));
        setClinicData(clinicData);
    }, [localStorage.getItem('ClinicData')]);
    
    //On Change Of Tabs 
    const onTabChange = async (e) => {
        const patientId =await getValues('patientId');
        setActiveIndex(e.index);
        props.setSlotModelTrue(e.index);
        
          setTimeout(() => {
           setValue('patientId',patientId? patientId:null, { shouldValidate:true  })
        }, 150);
    };

    useEffect(() => {
        if (props.slotModelTrue === 0) {
            setActiveIndex(0);
        } else if (props.slotModelTrue === 1) {
            setActiveIndex(1);
        }
    }, [props.slotModelTrue]);

    const fetchProcedures = () => {
        fetchMethodRequest('GET', apiCalls.procedures)
            .then(async (response) => {
                if (response && response.procedures) {
                    let proceduresData = response.procedures;
                    setProceduresData(proceduresData);

                }
            }).catch(error => {
                console.error('Error fetching procedures:', error);
            });
    };


    const formatTimeFromMoment = (value) => {
        const format = "HH:mm";
        const regex = /^\d{2}:\d{2}$/;
        const formattedTime = regex.test(value) ? value : moment(value).format(format);
        return formattedTime;
    };

    const convertIntoMin = (time) => {
        const regex = /^\d{2}:\d{2}$/;
        time = formatTimeFromMoment(time);
        if (time && regex.test(time)) {
            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
        }
        return 0;
    }

    // FromTime And  ToTime Validations
    function validateTimeRange(endTime) {
        let startTime = getValues('startTime')
        startTime = convertIntoMin(startTime ? getValues('startTime') : startTime);
        endTime = convertIntoMin(endTime);
        if (!startTime || !endTime || startTime < endTime) {
            return true;
        }
        return false;
    }


    // get Patient Details in form field
    const PatientDetails = ({ patient }) => {
        if (!patient) return null;

        const detailStyle = { marginBottom: '0.3em' };
        const valueStyle = { fontWeight: 'bold', textTransform: 'capitalize' };
        const { firstName, lastName, dateOfBirth, fullAddress, fullAddress2, phoneNumber,gender,age} = patient;
        const DOB = dateOfBirth ? moment(dateOfBirth).format('MM-DD-YYYY') : " ";

        return (
            <div className="patient-details" style={{ paddingTop: '20px' }}>
                <div style={detailStyle}>
                    <span>Patient Name:</span>
                    {firstName && lastName && (
                        <span style={valueStyle}>
                            {`${firstName} ${lastName}${age && gender ? ` (Age: ${age}, ${gender})` : ""
                                }`}
                        </span>
                    )}
                </div>
                <div style={detailStyle}>
                    <span>Address:</span>
                    <div>
                        {fullAddress2 && <div style={valueStyle}>{fullAddress2}</div>}
                        {fullAddress && <div style={valueStyle}>{fullAddress}</div>}
                        {phoneNumber && <div style={valueStyle}>{phoneNumber}</div>}
                    </div>
                </div>
            </div>
        );
    };

    const getStatusColor = (status) => {
        if(status){
            const statusObj = statusOptions && statusOptions.find(option => option.value === status);
            return statusObj ? statusObj.color : '#000000'; // Default color if status not found
        }
      };

    const handleStatusChange = (e) => {
        setConfirmStatus(e.value);
        const updatedEvents =  props.eventsData &&  props.eventsData.events.map(event => {
          if (event._id === props.selectedEventId) {
            return {
              ...event,
              confirmationStatus: e.value,
              statusColor: getStatusColor(e.value)
            };
          }
          return event;
        });
    
        props.eventsData.setEvents(updatedEvents);
      };

    // save data To Server
    const sendDataToServer = (data) => {

        if (data) {

            let method, apiUrl;
            if (props.formType === 'updateAppointment') {
                method = 'PUT';
                apiUrl = `${apiCalls.appointments}/${props.selectedRowData._id}`;
            } else {
                method = 'POST';
                apiUrl = apiCalls.appointments;
            }

            const startDate = new Date(data.eventDate);
            const endDate = new Date(data.eventDate);
            const getTimeFromDate = (time, baseDate) => {
                return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), time.getHours(), time.getMinutes());
            };

            const parseTime = (timeString, baseDate) => {
                if ((/^\d{2}:\d{2}$/).test(timeString)) {
                    const [hours, minutes] = timeString.split(':').map(Number);
                    return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), hours, minutes);
                } else {
                    const isoDate = new Date(timeString).toISOString();
                    return isoDate
                }
            };

            // Function to process each time input independently
            const processTimeInput = (timeInput, baseDate) => {

                if (typeof timeInput === 'string') {
                    return parseTime(timeInput, baseDate);
                } else if (timeInput instanceof Date) {
                    return getTimeFromDate(timeInput, baseDate);
                } else {
                    console.error("Invalid time input format");
                    return null;
                }
            };
            let start = processTimeInput(data.startTime, startDate);
            let end = processTimeInput(data.endTime, endDate);
            const patientId = data.patientId;
            const treatmentType = data.treatmentType && data.treatmentType;
            const color = data.reasons ? data.reasons.color : data.color;
            const provider = data.provider;
            const selectedHandler = props.selectedHandler ? props.selectedHandler : null
            const selectedHospitalId = props.selectedHospitalId ? props.selectedHospitalId : null
            const formattedDate = formatDate.formatDate(startDate);
            const fromTimePart = formatDate.formatDate(start);
            const toTimePart = formatDate.formatDate(end);
            console.log('formattedDate' , fromTimePart);
            const modifiedFromTime = formattedDate.substring(0, 10) + fromTimePart.substring(10);
            const modifiedToTime  = formattedDate.substring(0, 10) + toTimePart.substring(10);
            let payload = {}

            if (props.formType === "createAppointment") {
                payload = {
                    title: data.eventName,
                    // fromTime: fromTimePart,
                    // toTime: toTimePart,
                    fromTime: modifiedFromTime,
                    toTime: modifiedToTime,
                    date: formattedDate,
                    allDay: false,
                    status: "Available",
                    patientId: patientId,
                    clinicId: patientId.clinicId ? patientId.clinicId : null,
                    confirmationStatus: 'Booked',
                    treatmentType: treatmentType,
                    color: (color && color.hex ? color.hex : color) || '#265985',
                    provider: provider,
                    handler: selectedHandler,
                    hospitalId: selectedHospitalId,
                    reasons:data.reasons
                }

            } else if (props.formType === "updateAppointment") {
                let appointmentId;
                let handler;
                let hospitalId;
                let color;
                let confirmationStatus;

                const matchingAppointment = props.appointments && props.appointments.find(appointment => appointment._id === props.selectedRowData._id);
                if (matchingAppointment) {
                    appointmentId = matchingAppointment._id;
                    hospitalId = matchingAppointment.hospitalId;
                    handler = matchingAppointment.handler;
                    confirmationStatus = matchingAppointment.confirmationStatus;
                    color =data.reasons ? data.reasons.color : matchingAppointment.color;
                }
                payload = {
                    // fromTime: start,
                    // toTime: end,
                    fromTime: modifiedFromTime,
                    toTime: modifiedToTime,
                    _id: appointmentId,
                    patientId: patientId,
                    treatmentType: treatmentType,
                    provider: provider,
                    // confirmationStatus: props.confirmStatus ? props.confirmStatus : null,
                    confirmationStatus:confirmStatus ? confirmStatus : confirmationStatus,
                    hospitalId: hospitalId,
                    handler: handler,
                    color: color,
                    date: startDate,
                    reasons:data.reasons
                };
            }
            setIsLoading(true);

            fetchMethodRequest(method, apiUrl, payload)
                .then(response => {
                    if (response.respCode) {
                        //   setShowWeekendConfirmModal(false)
                        reset();
                        showToasterMessage(response.respMessage, 'success');
                        props.setDialogVisible(false);
                        props.getSlotsAndAppointmentsData();
                        props.getAllAppointments();
                    } else {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                })
                .catch(error => {
                    console.error('Error canceling appointment:', error);
                    showToasterMessage(response.errorMessage, 'danger');
                })
            setIsLoading(false);
            props.setSlotModelTrue(0);
        }
    };

    //Weekend Confirmation changes
    function checkForWeekends(date) {
        const dayOfWeek = date.getDay();
        return dayOfWeek === 0 || dayOfWeek === 6;
    }

    // send Data To Server 
    const submit = (formValues) => {
        if (formValues) {
            setFormValues(formValues);
            const startDate = new Date(formValues.eventDate);
            const includesWeekend = checkForWeekends(startDate);
            if (includesWeekend && props.formType === 'createAppointment') {
                setShowWeekendConfirmModal(true);
            } else {
                sendDataToServer(formValues);
            }
        }
    }


    // Get Reasons Color while  change it
    const handlePatientSelect = (value, name, props) => {
        if (name === "reasons") {
            props.field.onChange(value);
            const colorNameLowercase = value.color ? value.color.toLowerCase() : null;
            const hexCode = nameToHex[colorNameLowercase] || '#265985';
            setSelectedColor(hexCode);
        }
    };

    const updateStartTime = (newStartTime) => {
        props.setStartTime(newStartTime);
    };


    // get AutoComplete Field
    let getAutoComplete = (i, item) => {
        let itemTemplateFunction;

        if (item.populateValue && item.populateField) {
            itemTemplateFunction = (option) => {
                // console.log("Processing option in itemTemplate:", option);
                const value = option[item.populateValue];
                const label = option[item.populateField];
                return (
                    <div className="autocomplete-item">
                        <div>{`${value} - ${label}`}</div>
                    </div>
                );
            };
        }
        return (<>

            <div className='col-md-12 mt-1'>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoAutoCompleteField
                            markReq={item.required}
                            input={field}
                            id={field.id}
                            name={field.name}
                            field={field}
                            item={item}
                            filterField={item.filterField}
                            filterValue={item.filterValue}
                            filterType={item.isNotEq}
                            multiple={item.isMultiple}
                            fieldState={fieldState}
                            errors={errors}
                            disabled={props.formType === 'createAppointment' && item.name === "patientId" ? true : false}
                            screen={'Appointments'}
                            formType={props.formType === 'updateAppointment' ? 'edit' : 'add'}
                            searchApi={item.searchApi}
                            searchField={item.searchField}
                            filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                            placeholder={item.placeholder}
                            label={item.label}
                            itemTemplate={itemTemplateFunction}
                            onChange={handlePatientSelect}
                            populateValue={item.populateValue}
                            populateField={item.populateField}
                            defaultFilterCriteria={item.defaultFilterCriteria}
                            providerFilter={item.providerFilter && item.providerFilter}
                        />)}
                />

                {patientId && item.name === "patientId" &&
                    <div>
                        {patientId ? (
                            <PatientDetails patient={patientId} />
                        ) : localStorage.getItem('PatientData') ? (
                            <PatientDetails patient={JSON.parse(localStorage.getItem('PatientData'))} />
                        ) : null}
                    </div>
                }

                {selectedColor && item.name === "reasons" && (
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom:"1rem" }}>
                        <label>Appointment Color: </label>
                        <div style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: selectedColor,
                            marginLeft: '10px',
                            borderRadius: '5px'
                        }}>
                        </div>
                    </div>
                )}
            </div>

        </>)
    }

    let getDate = (i, item) => {

        return (
            <div className='col-md-12  mt-1'>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoDateField
                            markReq={item.required}
                            input={field}
                            item={item}
                            label={item.label}
                            id={field.id}
                            name={field.name}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                            placeholder={item.placeholder ? item.placeholder : "date"}
                        //   formType={props.formType}
                        // onChange={onChange} 
                        />)}
                />
            </div>
        )
    }

    const getTimePicker = (i, item) => {

        return (
            <div className="calendar-form-group calendar-form-group-time col-md-6">
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <>
                            <CustomTimePicker
                                markReq = {item.required}
                                id={item.id}
                                {...field}
                                field={field}
                                label={item.label}
                                screen={'appointments'}
                                fieldState={fieldState}
                                item={item}
                                errors={errors}
                                timeOnly hourFormat="12" showIcon
                                onChange={(startTimeValue) => {
                                    field.onChange(startTimeValue);
                                    updateStartTime(startTimeValue);
                                }}
                            />
                        </>
                    )}
                />
            </div>
        )
    }

    const getColerPicker = () => {
        return (
            <div>
                <label htmlFor="confirmationStatus">Confirmation Status</label>
                <Controller
                    name="confirmationStatus"
                    control={control}
                    render={({ field }) => (
                        <ListBox
                            {...field}
                            options={statusOptions}
                            optionLabel="label"
                            onChange={handleStatusChange}
                            style={{ width: '100%' }}
                            listStyle={{ height: '250px' }}
                            className="my-listbox"
                        />
                    )}
                />
            </div>)
    }

    // get Individual form field to display
    const getField = (item, i) => {
        if (
        //  (item.name === 'reasons' && props.formType === 'updateAppointment') ||
         (item.name === 'confirmationStatus' && props.formType === 'createAppointment')) {
            return null;
        }
        return item.type === "relateAutocomplete" ? getAutoComplete(i, item)
            : item.type === "date" ? getDate(i, item)
                : item.type === "time" ? getTimePicker(i, item)
                    : item.type === "confirmationStatus" ? getColerPicker() : null
    }

    // get form fields
    const getFields = () => {
        return (<div>
            <div className=''>
                <div className='form  mb-2 mt-4'>
                    {formFields && formFields.map(getField)}
                </div>
            </div>
        </div>)
    }

    const getButtonToolbar = () => {
        let tittle = props.formType === "createAppointment" ? 'Submit' : "Update"
        return (<>
            <div className='d-flex justify-content-center align-items-center'>

                <Button type="submit" className='appointment-submit-btn'> {tittle} </Button>
            </div>
        </>)

    }

    const getWeekendConfirmation = () => {
        return <WeekendConfirmModal
            isOpenWeekendModal={showWeekendConfirmModal}
            label="Are You Sure You Want To Create Appointments For Weekends?"
            closeWeekendModal={() => setShowWeekendConfirmModal(false)}
            header='Create Appointments'
            confirm={() => sendDataToServer(formValues)}
        />
    }


    return (
        <div>
            {showWeekendConfirmModal ? getWeekendConfirmation() : null}
            <TabView onTabChange={(e) => onTabChange(e)} activeIndex={activeIndex}>
                <TabPanel header="Appointments">
                    <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
                        <Loader loader={isLoading} />
                        <div
                            className={`row`}
                        >
                            {getFields()}
                        </div>
                        {getButtonToolbar()}

                    </form>
                </TabPanel >

                <TabPanel header="Procedures">
                    <DataTable value={proceduresData} responsiveLayout="scroll">
                        <Column field="code" header="Code" sortable></Column>
                        <Column field="description" header="Description" sortable filter filterPlaceholder="Search by description"></Column>
                        <Column field="fee" header="Fee" sortable></Column>
                    </DataTable>
                </TabPanel>

                <TabPanel header="Forms">
                    <AppointmentForm />
                </TabPanel>

            </TabView>
        </div>
    )
}

export default AddAppointmentsData