import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;
let show = true;

const TopbarSidebarButton = (props) => {
// useEffect(()=>{
//     window.addEventListener('sideMenubar',onChange);
//   },[]);

  const propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

// const onChange = async(a) =>{
//     const s = !a || (a.detail && !show)|| (!a.detail && show) 
//     if(s ){
//       await changeSidebarVisibility();
//     }
//     show= !show;
//   }
 
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = props;

    return (
      <div>
        <button className="topbar__button topbar__button--desktop" type="button" onClick={ ()=>{
        changeSidebarVisibility();
        // onChange()
        }}>
          <img src={icon} alt="" className="topbar__button-icon" />
        </button>
        <button className="topbar__button topbar__button--mobile" type="button" onClick={changeMobileSidebarVisibility}>
          <img src={icon} alt="" className="topbar__button-icon" />
        </button>
      </div>
    );
  }


export default TopbarSidebarButton;
