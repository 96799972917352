import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ListBox } from 'primereact/listbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import apiCalls from '../../../config/apiCalls';
import { faTrash, faEdit, faInfoCircle ,faUser} from '@fortawesome/free-solid-svg-icons';
import DeleteRowModal from '../../Cruds/CommonModals/DeleteRowModal';
import { Tooltip } from 'reactstrap';
import TreatmentPlanDialog from '../../Cruds/CommonModals/TreatmentPlanModal';
import { Dialog } from 'primereact/dialog';
import Charts from '../../Cruds/Charts/components/Charts';
import config from '../../../config/config';
import RefferealModal from '../../Cruds/CommonModals/RefferealModal';
import AutoNoteSelectModal from './AutoNoteForms/AutoNoteSelectModal';
import axios from 'axios';
import Signature from './Signature';

const DragAndDropTreatment = (props) => {
  let { i, item,control, Controller, getValues, setValue, errors, selectedData, setCustomFieldValidation, loginRole, heading, watch, reset, submit, handleSubmit } = props;
  const [dataTableData, setDataTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalFee, setTotalFee] = useState(0);
  const [originalData, setOriginalData] = useState([]);
  const [rowPriorities, setRowPriorities] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [originalDataTable, setOriginalDataTable] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);
  const [noteDialogVisible, setNoteDialogVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState('');
  const [isMultiDelete, setIsMultiDelete] = useState(false);
  const [isOpenReffereal, setIsOpenReffereal] = useState(false);
  const [IsOpenAutoNotes, setIsOpenAutoNotes] = useState(false);
  const [isOpenSignDialog, setIsOpenSignDialog] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    getDataFromServer();
  }, [props.data]);

  const filterData = (data, predicate) => {
    return data.filter(predicate);
  };

  useEffect(() => {
    dataTableDataChange(dataTableData);
  }, [dataTableData]);

  function dataTableDataChange(dataTableData, saveDataToServer) {
    let data = dataTableData.reduce((acc, curr) => acc + (curr.isSubtotal ? 0 : parseFloat(curr.fee || 0)), 0);
    setTotalFee(data);
    const predicate = item => !item.isSubtotal;
    const filteredDataTableData = filterData(dataTableData, predicate);
    const filteredOriginalDataTable = filterData(originalDataTable, predicate);
    if (props.setValue) {
      props.setValue('procedureSteps', filteredOriginalDataTable);
      props.setValue('teethProcedureSteps', filteredDataTableData);
    }
    if (saveDataToServer === true && props.data?._id) {
      const payload =getValues();
      fetchMethodRequest('PUT',`${apiCalls.treatmentPlan}/${props.data._id}`,{...props.data,...payload}).then(res=>{
        if(res.respCode){
          props.setValue('treatmentPlanHeading',res.details);
        }
      })
      // handleSubmit(submit)();
    }
  }


  /**
   * 
   * @param {Array} procedures 
   * @param {Array} teeths 
   * @returns Array
   */
  function addTeethToProceduresSteps(procedures, teeths) {

    teeths.map(teeth => {
      const index = procedures.findIndex(pro => pro.code == teeth.code);
      if (index >= 0) {
        let teethArr = procedures[index]?.teethNumber ? procedures[index]?.teethNumber : [];
        teeth.teethNumber = teeth.teethNumber ? teeth.teethNumber : null;
        if (!teethArr.includes(teeth.teethNumber)) {
          teethArr.push(teeth.teethNumber);
        }
        procedures[index].teethNumber = teethArr;
      } else {        
        procedures.push({...teeth,teethNumber:[teeth.teethNumber]});
      }
      return teeth;
    })
    return procedures;
  }

  const customizeData = (initialData) => {
    const dataWithoutSubtotals = initialData.filter(item => !item.isSubtotal);
    let groupedData = dataWithoutSubtotals.reduce((acc, item) => {
      const priority = item.priority || 'default';
      if (!acc[priority]) {
        acc[priority] = { items: [], allHavePriority: true };
      }
      acc[priority].items.push(item);
      if (!item.priority) {
        acc[priority].allHavePriority = false;
      }
      return acc;
    }, {});

    const result = [];
    const priorities = Object.keys(groupedData).sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));

    let hasPriorities = priorities.some(priority => priority !== 'default');

    priorities.forEach(priority => {
      const group = groupedData[priority];
      const items = group.items;
      result.push(...items);

      if (hasPriorities && group.allHavePriority) {
        const subtotal = items.reduce((sum, item) => sum + parseFloat(item.fee || 0), 0);
        result.push({
          isSubtotal: true,
          _id: `subtotal-${priority}-${Date.now()}`,
          description: `Subtotal`,
          subtotal: subtotal,
          priority: priority
        });
      }
    });

    return result;
  };


  const getDataFromServer = () => {
    if (props.data && Array.isArray(props.data.teethProcedureSteps)) {
      const initialData = (props.data && props.data.teethProcedureSteps && props.data.teethProcedureSteps.length > 0) ? props.data.teethProcedureSteps : [];
      const customizedData = customizeData(initialData);
      setDataTableData(customizedData);
      const originalData = (props.data && props.data.procedureSteps && props.data.procedureSteps.length > 0) ? props.data.procedureSteps : [];
      setOriginalDataTable(originalData);
    } else {
      setDataTableData([]);
      setOriginalData([]);
      setSelectedRows([]);
      setRowPriorities({});
      setOriginalDataTable([]);
    }
  }
  const handleCheckboxChange = (dataItem) => {
    const rowKey = `${dataItem._id}-${dataItem.teethNumber}`;
    setSelectedRows(prevSelectedRows => {
      const isSelected = prevSelectedRows.includes(rowKey);
      if (isSelected) {
        return prevSelectedRows.filter(id => id !== rowKey);
      } else {
        return [...prevSelectedRows, rowKey];
      }
    });
  };
  


  const handlePriorityChange = (newPriority) => {
    if (newPriority === null) {
      const resetData = dataTableData.map(item => {
        const newItem = { ...item };
        delete newItem.priority;
        return newItem;
      }).filter(item => !item.isSubtotal);

      setDataTableData(resetData);
      setSelectedRows([]);
      setRowPriorities({});

      const total = resetData.reduce((acc, curr) => acc + parseFloat(curr.fee || 0), 0);
      setTotalFee(total);
    } else {
      if (selectedRows.length === 0) {
        return;
      }
      updatePriorities(newPriority, selectedRows);
    }
  };


  const updatePriorities = (newPriority, selectedRowIds) => {
    const updatedRowPriorities = { ...rowPriorities };
    selectedRowIds.forEach(rowId => {
      updatedRowPriorities[rowId] = newPriority;
    });

    setRowPriorities(updatedRowPriorities);

    groupAndSortData(updatedRowPriorities);
  };

  const groupAndSortData = (updatedRowPriorities) => {
    const nonSubtotalData = dataTableData.filter(row => !row.isSubtotal);

    const updatedData = nonSubtotalData.map(row => {
      const rowKey = `${row._id}-${row.teethNumber}`;
      return { ...row, priority: updatedRowPriorities[rowKey] || row.priority };
    });

    let groupedData = updatedData.reduce((acc, row) => {
      const priority = row.priority || 'default';
      (acc[priority] = acc[priority] || []).push(row);
      return acc;
    }, {});

    const sortedGroupKeys = Object.keys(groupedData).sort((a, b) => parseInt(a) - parseInt(b));

    let finalData = [];
    sortedGroupKeys.forEach(priority => {
      const groupRows = groupedData[priority];
      if (priority !== 'default') {
        const groupSubtotal = groupRows.reduce((sum, row) => sum + parseFloat(row.fee || 0), 0);

        finalData.push(
          ...groupRows,
          {
            isSubtotal: true,
            _id: `subtotal-${priority}`,
            description: `Subtotal`,
            subtotal: groupSubtotal,
          }
        );
      } else {
        finalData.push(...groupRows);
      }
    });

    setDataTableData(finalData);
    setOriginalData(originalDataTable);
    setSelectedRows([]);
  };

  const openDeleteModal = (id) => {
    setRowToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDelete = () => {
    if (rowToDelete !== null) {
      const [deleteId, deleteTeethNumber] = rowToDelete.split('-');
      const deleteTeethNumberInt = parseInt(deleteTeethNumber) ? parseInt(deleteTeethNumber) : null;
      let filteredData = dataTableData.filter(item =>
        !(item._id === deleteId && item.teethNumber === deleteTeethNumberInt) && !item.isSubtotal
      );

      const updatedOriginalData = originalDataTable.reduce((acc, data) => {
        if (data._id === deleteId) {
          const filteredTeethNumbers = data.teethNumber ? data.teethNumber.filter(num => num !== deleteTeethNumberInt):'';
          if (filteredTeethNumbers.length > 0) {
            acc.push({ ...data, teethNumber: filteredTeethNumbers });
          }
        } else {
          acc.push(data);
      }
      return acc;
    }, []);

    const treatmentPlanId = props.getValues('treatmentPlanHeading');
    const payload = {
      patientId: props.getValues('patientId'),
        teethProcedureSteps: filteredData,
        procedureSteps: updatedOriginalData,
        hospitalId: treatmentPlanId.hospitalId,
        heading: treatmentPlanId.heading,
        txPlanId: treatmentPlanId.txPlanId,
        note: props.getValues('note'),
        _id: treatmentPlanId._id
      };

      const requestUrl = `${apiCalls.treatmentPlan}/${treatmentPlanId._id}`;

      fetchMethodRequest('PUT', requestUrl, payload)
        .then(response => {
          if (response.respCode) {
            showToasterMessage('Deleted successfully', 'success');
            props.setValue('treatmentPlanHeading',response.details);
            setDataTableData(customizeData(response.details.teethProcedureSteps));
            setOriginalDataTable(customizeData(response.details.procedureSteps))
            setSelectedRows([]);
        } else {
          showToasterMessage('Error updating treatment plan', 'error');
        }
      })
      .catch(error => {
        console.error('Error updating treatment plan:', error);
        showToasterMessage('Error updating treatment plan', 'danger');
      });

      setIsDeleteModalOpen(false);
    }
  };
  
  const toggleTooltip = (id) => {
    setTooltipOpen(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleEmailTreatment = () => {
    const PatientId = props.getValues('patientId');
    const treatmentPlanId = props.getValues('treatmentPlanHeading')._id;
    let payload = {
      patientId: PatientId,
      treatmentPlanId: treatmentPlanId
    };
    const requestUrl = `${apiCalls.treatmentPlan}/treatmentConfirmationEmail/${treatmentPlanId}`;
    fetchMethodRequest('POST', requestUrl, payload)
      .then(response => {
        if (response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          setSelectedRows([]);
        } else {
          showToasterMessage(response.errorMessage, 'error');
        }
      })
      .catch(error => {
        console.error('Error canceling treatment:', error);
        showToasterMessage(response.errorMessage, 'danger');
      })
  }

  const handlePrintTreatment = () => {
    const PatientId = props.getValues('patientId');
    const treatmentPlanId = props.getValues('treatmentPlanHeading')._id;
    let payload = {
      patientId: PatientId,
      treatmentPlanId: treatmentPlanId
    };
    const requestUrl = `${apiCalls.treatmentPlan}/generatePdfs/${treatmentPlanId}`;
    fetchMethodRequest('POST', requestUrl, payload)
      .then(response => {
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          setSelectedRows([]);
          if (response.pdfPath) {
            const downloadUrl = config.imgUrl + response.pdfPath;
            window.open(downloadUrl, '_blank');
          }
        } else {
          showToasterMessage(response.errorMessage, 'error');
        }
      })
      .catch(error => {
        console.error('Error canceling treatment:', error);
        showToasterMessage(response.errorMessage, 'danger');
      })
  }

  const rowClassName = (item) => {
    switch (item.treatmentStatus) {
      case 'Complete':
        return "text-complete";
      default:
        return "row-default";
    }
  }

  const openSelectedNotes = (data) => {
    if(data){
      setRowData(data);
    }
    setIsOpenAutoNotes(true);
    setCustomFieldValidation({ save: false, });
  }
  
  const renderTableData = () => (
    <tbody>
      {dataTableData.map((data, index) => {
        const uniqueKey = `${data._id}-${data.teethNumber}`;
      const formattedDate = data.created ? new Date(data.created).toLocaleDateString('en-US') : '';
        const className = rowClassName(data);
        if (data.isSubtotal) {
          return (
            <tr key={`subtotal-${index}`} className="text-center" style={{ fontWeight: 'bold', borderBottom: '2px solid black' }}>
              <td colSpan="6">{data.description}</td>
              <td style={{textAlign : 'left'}}>${data.subtotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
          );
        } else if (data.isSeparator) {
          return (
            <tr key={`separator-${uniqueKey}`} className="separator-row text-center">
              <td colSpan="8" style={{ borderTop: '2px solid #000' }}></td>
            </tr>
          );
        } else {

          return (
            <tr key={uniqueKey} className={className}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(uniqueKey)}
                  onChange={() => handleCheckboxChange(data)}
                  className='treatmentplan-checkbox'
                />
              </td>
              <td>{formattedDate}</td>
              <td>{data.teethNumber}</td>
              <td>{data.priority || ''}</td>
              <td>{data.code}</td>
              <td style={{ textAlign: 'left', textTransform: 'capitalize' }}>
                {data.description}
                {data.note && (
                  <div>
                    <span style={{ color: '#888', fontSize: '0.8em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {data.note.substring(0, 50)}...
                    </span>
                    <FontAwesomeIcon icon={faInfoCircle} onClick={() => handleNoteClick(data.note)} style={{ marginLeft: '5px' }} />
                  </div>
                )}
              </td>
              <td>${data.fee ? data.fee.toFixed(2).toLocaleString() : ''}</td>
              <td>
                <button type='button' style={{ border: 'none', background: 'transparent' }}>
                  <FontAwesomeIcon title='Edit' icon={faEdit} onClick={() => handleEditClick(data)} />
                </button>
                <button type='button' style={{ border: 'none', background: 'transparent', marginLeft: '5px' }}>
                  <FontAwesomeIcon title='Delete' icon={faTrash} style={{ color: 'red' }} onClick={() => openDeleteModal(uniqueKey)} />
                </button>
                <button type='button' style={{ border: 'none', background: 'transparent' }}>
                  <FontAwesomeIcon title='Auto Notes' icon={'pencil-alt'} onClick={ ()=> openSelectedNotes(data)} />
                </button>
              </td>
              {/* <td>{data.notes ? data.notes : "-"}</td> */}
              <td style={{width:"5%"}}>
                {data.autoNote ? (
                    data.autoNote.split('.').map((note, index) => (
                      <div key={index}>{note}</div>
                    ))
                  ) : (
                    "-"
                  )}
              </td>

            </tr>
          );
        }
      })}
    </tbody>
  );


  const handleActivatePlan = () => {
    const PatientId = props.getValues('patientId');
    const treatmentPlanId = props.getValues('treatmentPlanHeading');
    const hospitalId = treatmentPlanId.treatmentPlanId;
    const txPlanId = treatmentPlanId.txPlanId;
    const note = props.getValues('note');

    let heading = treatmentPlanId.heading;
    const procedureSteps = selectedRows.map(rowId => {
      const [id, teethNumber] = rowId.split('-');
      return dataTableData.find(row => row._id === id && row.teethNumber === parseInt(teethNumber, 10));
    }).filter(step => step !== undefined);

    const regex = /^(Active [^\(\)]+)(?: \((\d+)\))?$/;
    const match = heading.match(regex);
    if (match) {
      const base = match[1];
      const number = match[2];
      if (number) {
        heading = `${base} (${parseInt(number, 10) + 1})`;
      } else {
        heading = `${base} (1)`;
      }
    } else {
      heading = `Active ${treatmentPlanId.heading}`;
    }

    let payload = {
      patientId: PatientId,
      note: note,
      hospitalId: hospitalId,
      heading: heading,
      txPlanId: txPlanId,
      procedureSteps: procedureSteps
    };

    const requestUrl = apiCalls.treatmentPlan;
    fetchMethodRequest('POST', requestUrl, payload)
      .then(response => {render
        if (response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          setValue('treatmentPlanHeading', response.details)
          setDataTableData(response.details.procedureSteps);
          setSelectedRows([]);
        } else {
          showToasterMessage(response.errorMessage, 'error');
        }
      })
      .catch(error => {
        console.error('Error canceling appointment:', error);
        showToasterMessage(response.errorMessage, 'danger');
      })
  };

  const handleEditClick = (item) => {
    setSelectedItemForEdit(item);
    setDialogVisible(true);
  };

  const handleCloseDialog = () => {
    setDialogVisible(false);
    setIsOpenReffereal(false)
  };
  const handleSaveChanges = (data) => {
    const updatedData = dataTableData.map(item => {
      if (item._id === selectedItemForEdit._id && item.teethNumber === selectedItemForEdit.teethNumber) {
        return {
          ...item,
          note: data.note,
          treatmentStatus: data.treatmentStatus,
          provider: data.provider,
          referral: data.referral,
          autoNote:data.autoNote,
          completedDate: Date.now(),
        };
      }
      return item;
    });
    setDataTableData(updatedData);
    updateTreatmentStatus(updatedData);
  };

  const updateTreatmentStatus = (updatedDataTableData) => {
    const treatmentPlanId = props.getValues('treatmentPlanHeading');
    const payload = {
      patientId: props.getValues('patientId'),
        teethProcedureSteps: updatedDataTableData,
        procedureSteps: originalDataTable,
        hospitalId: treatmentPlanId.hospitalId,
        heading: treatmentPlanId.heading,
        txPlanId: treatmentPlanId.txPlanId,
        note: props.getValues('note'),
        _id: treatmentPlanId._id
      };
      const requestUrl = `${apiCalls.treatmentPlan}/${treatmentPlanId._id}`;
      fetchMethodRequest('PUT', requestUrl, payload)
        .then(response => {
          if (response.respCode) {
            showToasterMessage('Updated successfully', 'success');
            props.setValue('treatmentPlanHeading',response.details);
            setDataTableData(customizeData(response.details.teethProcedureSteps));
            setOriginalDataTable(customizeData(response.details.procedureSteps))
            setSelectedRows([]);
        } else {
          showToasterMessage('Error updating treatment plan', 'error');
        }
      })
      .catch(error => {
        console.error('Error updating treatment plan:', error);
        showToasterMessage('Error updating treatment plan', 'danger');
      });
  }
  //notemodal
  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setNoteDialogVisible(true);
  };
  const NoteModal = ({ visible, onHide, note }) => (
    <Dialog header="Note Details" visible={visible} onHide={onHide} style={{ width: '50vw' }}>
      <p style={{paddingTop:'15px'}}>{note}</p>
    </Dialog>
  );

  
  const setDataTableDataFun = async (arr,render) => {
    setDataTableData(arr);
    if(render){
      const orgData = await addTeethToProceduresSteps(originalDataTable, arr)
      setOriginalDataTable(orgData);
      setTimeout(() => {
        dataTableDataChange(arr, true);
      }, 100);
    }

  }

  const getAutonotesData = (data) =>{
    let arr =dataTableData.map(item=>{
      if(item._id === rowData._id && item.teethNumber===rowData.teethNumber){
        item.autoNote = data
      }
      return item;
    })
   
    setDataTableDataFun(arr,true);


  }

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      const newSelectedRows = dataTableData.filter(item => !item.isSubtotal).map(item => `${item._id}-${item.teethNumber}`);
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows([]);
    }
  };
  //multidelete
  const openMultiDeleteModal = () => {
    setIsMultiDelete(true);
    setIsDeleteModalOpen(true);
  };

  const handleMultiDelete = () => {
    if (selectedRows.length === 0) {
      showToasterMessage('No rows selected for deletion', 'error');
      return;
    }
    
    let filteredData = dataTableData.filter(item =>
      !selectedRows.includes(`${item._id}-${item.teethNumber}`) && !item.isSubtotal
    );
  
  
    const updatedOriginalData = originalDataTable.reduce((acc, data) => {
      if (data.teethNumber) {
          const teethNumbers = data.teethNumber.filter(teethNumber =>
              !selectedRows.includes(`${data._id}-${teethNumber}`)
          );
          if (teethNumbers.length > 0) {
              acc.push({ ...data, teethNumber: teethNumbers });
          }
      } else {
          if (!selectedRows.includes(`${data._id}-undefined`)) {
              acc.push(data);
          }
      }
      return acc;
  }, []);
  
    const treatmentPlanId = props.getValues('treatmentPlanHeading');
    const payload = {
      patientId: props.getValues('patientId'),
      teethProcedureSteps: filteredData,
      procedureSteps: updatedOriginalData,
      hospitalId: treatmentPlanId.hospitalId,
      heading: treatmentPlanId.heading,
      txPlanId: treatmentPlanId.txPlanId,
      note: props.getValues('note'),
      _id: treatmentPlanId._id
    };
  
    const requestUrl = `${apiCalls.treatmentPlan}/${treatmentPlanId._id}`;
  
    fetchMethodRequest('PUT', requestUrl, payload)
      .then(response => {
        if (response.respCode) {
          showToasterMessage('Deleted successfully', 'success');
          props.setValue('treatmentPlanHeading',response.details);
          setDataTableData(customizeData(response.details.teethProcedureSteps));
          setOriginalDataTable(customizeData(response.details.procedureSteps));
          setSelectedRows([]);
        } else {
          showToasterMessage('Error updating treatment plan', 'error');
        }
      })
      .catch(error => {
        console.error('Error updating treatment plan:', error);
        showToasterMessage('Error updating treatment plan', 'danger');
      });
      setIsDeleteModalOpen(false);
  }; 
  const handleCompleteTreatment = () => {
    const selectedProcedures = selectedRows.map(rowId => {
      const [id, teethNumber] = rowId.split('-');
      if (teethNumber === "undefined") {
        return dataTableData.find(row => row._id === id && row.teethNumber === undefined);
      } else {
        return dataTableData.find(row => row._id === id && row.teethNumber === parseInt(teethNumber, 10));
      }
    }).filter(procedure => procedure !== undefined);

    const patientTreatment = selectedProcedures
    .filter(procedure => !procedure.treatmentStatus || procedure.treatmentStatus !== 'Complete')
    .map(({ code, description, teethNumber, fee }) => ({
      code,
      description,
      teethNumber,
      fee
    })); 
    const updatedDataTableData = dataTableData.map(data => {
      const selectedProcedure = selectedProcedures.find(proc => proc._id === data._id && proc.teethNumber === data.teethNumber);
      if (selectedProcedure) {
        return { ...data, treatmentStatus: 'Complete', completedDate: new Date() };
      }
      return data;
    });
  
    const updatedOriginalData = originalDataTable.map(data => {
      const selectedProcedure = selectedProcedures.find(proc => proc._id === data._id && proc.teethNumber === data.teethNumber);
      if (selectedProcedure) {
        return { ...data, treatmentStatus: 'Complete' };
      }
      return data;
    });
  
    setDataTableData(updatedDataTableData);
    setOriginalDataTable(updatedOriginalData);

    const payload = {
      patientTreatment: patientTreatment,
      patientId: props.getValues('patientId')
    };

    fetchMethodRequest('POST', apiCalls.accounts, payload)
      .then(response => {
        if (response.respCode) {
          updateTreatmentStatus(updatedDataTableData);
          showToasterMessage('Treatment completed successfully', 'success');
        } else {
          showToasterMessage('Failed to complete treatment', 'error');
        }
      })
      .catch(error => {
        console.error('Error completing treatment:', error);
        showToasterMessage('Error completing treatment', 'danger');
      });
  };
  
  const handleSignTreatment = async (rowData) => {
    const patientId = props.getValues('patientId');
    const treatmentPlanId = props.getValues('treatmentPlanHeading')._id;
    const requestUrl = `${config.apiUrl}/${apiCalls.signTP}/${treatmentPlanId}?type=signTP`;
    try {
      const response = await axios.get(requestUrl, {
        responseType: 'blob'
      });
      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfBlobUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfBlobUrl);
        setRowData(rowData);
        setIsOpenSignDialog(true);
        getPatientSignature();
        showToasterMessage('PDF generated successfully', 'success');
      } else {
        showToasterMessage('Failed to generate PDF', 'error');
      }
    } catch (error) {
      console.error('Error signing treatment:', error);
      showToasterMessage(error.response?.data?.errorMessage || 'An error occurred', 'danger');
    }
  };

  const getPatientSignature =  () => {
    const PatientId = props.getValues('patientId');
    const treatmentPlanId = props.getValues('treatmentPlanHeading')._id;
    const requestUrl = `${apiCalls.treatmentPlan}/PatientSign/${treatmentPlanId}`;
    fetchMethodRequest('GET', requestUrl)
    .then(response => {
      // Check if PatientSign exists and is not just an empty string
      if (response && response.PatientSign && response.PatientSign.trim() !== "") {
          setValue("patientSignature", response.PatientSign);
      } else {
          setValue("patientSignature", "");  // Optionally clear the previous value
      }
    })
    .catch(error => {
      console.error('Error fetching patient signature:', error);
    })
    .catch(error => {
        console.error('Error canceling treatment:', error);
        showToasterMessage("No patient signature", 'danger');
      })

  }
  
  const closeAutoNotes = () =>{
    setCustomFieldValidation({ save: true, });
    setIsOpenAutoNotes(false);
    
  }

  const getSignature = (index, item) => {

    return <Controller
      name={item.name ? item.name : null}
      control={control}
      render={({ field, fieldState }) => (
        <Signature
          item={item}
          field={field}
          fieldState={fieldState}
          errors={errors}
        />)}
    />
  }

  const getSignDialog = () => {
    return (
      <Dialog
        header="Sign Treatment Plan"
        visible={isOpenSignDialog}
        headerClassName="modal-header"
        draggable={false}
        style={{ width: '60vw', height: '90vh' }}
        onHide={() => setIsOpenSignDialog(false)}
        modal
      >
        <div className="row" style={{ height: "100%" }}>
          <iframe
            src={pdfUrl}
            style={{ width: '100%', height: '90%', border: 'none' }}
            title="PDF Viewer"
          />
          <div className="container">
            {getSignature(1,{ name: 'patientSignature', label: 'Patient Signature', id: 'signature', required: true })}
            <Button color="primary" onClick={handleSaveSignature} style={{ marginTop: '10px' }}>
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const handleSaveSignature = async () => {
    let data =getValues();
    const patientId = props.getValues('patientId');
    const treatmentPlanId = props.getValues('treatmentPlanHeading');
    const signature = getValues('patientSignature');

    let payload = {
      patientId: patientId,
      procedureSteps:originalDataTable,
      teethProcedureSteps:dataTableData,
      signatureTP: signature,
      note: props.getValues('note'),
      _id:treatmentPlanId._id,
      heading: treatmentPlanId.heading,
      txPlanId: treatmentPlanId.txPlanId,
    };
    const requestUrl = `${apiCalls.treatmentPlan}/UpdateSign`;

    try {
      const response = await fetchMethodRequest('POST', requestUrl, payload);
      if (response.respCode) {
        showToasterMessage('Signature saved successfully', 'success');
        setIsOpenSignDialog(false);
      } else {
        showToasterMessage('Failed to saved signature', 'error');
      }
    } catch (error) {
      console.error('Error saving signature:', error);
      showToasterMessage('An error occurred while saving the signature', 'danger');
    }
  };
  return (dataTableData.length > 0 &&
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {dataTableData.length > 0 && (
          <>
            <Button onClick={handleActivatePlan} style={{ marginLeft: '10px', marginTop: '20px' }} color="primary" disabled={selectedRows && selectedRows.length === 0}>
              Make Active Plan
            </Button>
            <Button onClick={() => handleSignTreatment(rowData)} style={{ marginLeft: '10px', marginTop: '20px' }} color="primary">
              Sign
            </Button>
            <Button onClick={handlePrintTreatment} style={{ marginLeft: '10px', marginTop: '20px' }} color="primary">
              Print
            </Button>
            <Button onClick={handleEmailTreatment} style={{ marginLeft: '10px', marginTop: '20px' }} color="primary">
              Email
            </Button>
            <Button style={{ marginLeft: '10px', marginTop: '20px' }} color="primary" type="submit">
              Update
            </Button>
            <Button onClick={openMultiDeleteModal} style={{ marginLeft: '10px', marginTop: '20px' }} color="primary" disabled={selectedRows && selectedRows.length === 0}>
              Delete
            </Button>
            <Button onClick={handleCompleteTreatment} style={{ marginLeft: '10px', marginTop: '20px' }} color="success" disabled={selectedRows.length === 0}>
              Complete
            </Button>
            <Button onClick={() => setIsOpenReffereal(true)} style={{ marginLeft: '10px', marginTop: '20px' }} color="primary">
              Referral
            </Button>
          </>
        )}
      </div>
      <div>
        <Charts dataTableData={dataTableData} setDataTableData={setDataTableDataFun} {...props} />
      </div>
      <div className='d-flex justify-content-between'>
        {dataTableData.length > 0 && (
          <div className='data-table'>
            <table className='table'>
            <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectedRows.length === dataTableData.filter(item => !item.isSubtotal).length}
                      onChange={handleSelectAllChange}
                      className='treatmentplan-checkbox'
                      />
                  </th>
                  {['Date', 'TTH', 'Priority', 'Code', 'Description', 'Fee', 'Actions' , 'Auto Notes' ].map(header => (
                    <th style={{ textAlign: 'left' }}>{header}</th>
                  ))}
                </tr>
              </thead>
              {renderTableData(dataTableData)}
              <tfoot>
                <tr className="text-center" style={{ fontWeight: 'bold' }}>
                  <td colSpan="6">Total:</td>
                  <td style={{textAlign : 'left'}}>${totalFee.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        {dataTableData.length > 0 && (
          <div class='priority-table'>
            <label htmlFor="priority-list-box">Priority</label>
            <Controller
              name="priority"
              control={control}
              render={({ field }) => (
                <ListBox {...field} options={[{ label: 'No Priority', value: null }, ...Array.from({ length: 10 }, (_, i) => ({ label: (i + 1).toString(), value: i + 1 }))]}
                  onChange={e => handlePriorityChange(e.value)}
                  style={{ width: '130px' }}
                  listStyle={{ maxHeight: '580px' }}
                />
              )}
            />
          </div>
        )}
      </div>
      <DeleteRowModal
        openDeleteModal={isDeleteModalOpen}
        deleteSelectedRow={isMultiDelete ? handleMultiDelete : handleDelete}
        closeDeleteModal={() => {
          setIsDeleteModalOpen(false);
          setIsMultiDelete(false);
        }}
      />
      <TreatmentPlanDialog
        visible={dialogVisible}
        onHide={handleCloseDialog}
        selectedRowData={selectedItemForEdit}
        onSave={handleSaveChanges}
      />

     { isOpenReffereal && 
          <RefferealModal
          isOpenReffereal={isOpenReffereal}
          setIsOpenReffereal={setIsOpenReffereal}
          onHide={handleCloseDialog}
          {...props}
          />
      }
      
      {isOpenSignDialog && getSignDialog()}

      <NoteModal visible={noteDialogVisible} onHide={() => setNoteDialogVisible(false)} note={selectedNote} />

     
     {IsOpenAutoNotes && <div>
        <AutoNoteSelectModal
          IsOpenAutoNotes={IsOpenAutoNotes}
          setIsOpenAutoNotes = {setIsOpenAutoNotes}
          closeSelectedAutoNotesModal={closeAutoNotes}
          getAutonotesData = {getAutonotesData}
        />
        
      </div>}
    
    </div>
  );
};

export default DragAndDropTreatment;