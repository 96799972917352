import React from 'react';
import { Button } from 'reactstrap';
import { Dialog } from 'primereact/dialog';

// config file
const DeleteRowDialog = (props) => {

    const deleteSelectedRow = () => {
        props.deleteSelectedRow();
    }

    return (
        <div>
            <Dialog 
                header="Confirmation"
                visible={props.openDeleteModal}
                onHide={props.closeDeleteModal}
                style={{ width: '30vw' }}
                className="p-dialog-centered"
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',paddingTop:'10px'}}>
                    <div style={{ marginBottom: 20, textAlign: 'center' }}>Are you sure you want to delete?</div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button color="primary" outline onClick={props.closeDeleteModal} className='deleteModalBtn marginRight' style={{ marginRight: 10 }}>No</Button>
                        <Button color="primary" outline onClick={deleteSelectedRow} className='deleteModalBtn'>Yes</Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default DeleteRowDialog;
