import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import fetchMethodRequest from '../../../config/service';
import { useForm, Controller } from 'react-hook-form';
import DoAutoCompleteField from './DoAutoCompleteField';
import { MultiSelect } from 'primereact/multiselect';
const DragAndDropTables = (props) => {   
  const [selectedAllData, setSelectedAllData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [item, setItem] = useState(props.item);
  const [field, setField] = useState(props.field);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [filterValue,setFilterValue] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [previousTxPlan , setPreviousTxPlan] = useState(item?.selectedItemTxplan?.procedureSteps || []);
  const {getValues, setValue, control, Controller, errors} = props;
  useEffect(() => {
    getDataFromServer();
    if(props.item && props.item.modal && props.item.modal==="TreatmentPlan"){
      setValue('note', 'If you have dental insurance, please be aware that THIS IS AN ESTIMATE ONLY.Coverage may be different if your deductible has not been met, annual maximum has been met, or if your coverage table is lower than average.');
    }
  }, []);


  // Setting specific data when 'item' state changes
  useEffect(()=> {
    if (item.selectedItemTxplan && item.formType==="edit") {
      console.log("itemsss",item)
      setValue('txPlanId', item.selectedItemTxplan);
    }
  },[item]);
  
useEffect(()=> {
  if(item.onChange){
    item.onChange(data);
}
},[data]);
  // Fetch data from the server based on the provided item configuration
const getDataFromServer = () => {
  let apiUrl = `${item.searchApi}?type=exportToCsv`;
  fetchMethodRequest('GET', apiUrl).then(async (res) => {
      if (res[item.searchApi]) {
        // let selData = field.value ? field.value : [];
          let selData = props.data && props.data[item.name] && props.data[item.name].length > 0 ? props.data[item.name] : [];
          let allData = res[item.searchApi];
          setData(selData);
          setAllData(allData.filter(obj => selData.every(o => o._id !== obj._id)));
      }
  });
}

  /**
    * Function to perform search based on value and type.
    * @param {String} value - Search value
    * @param {String} type - Type of search (not currently used)
    */
  const search = (value, type) => {

  }

  /**
    * Function to render the header of the DataTable.
    * @param {Object} e - Event object
    * @param {String} type - Type of DataTable ('all' or 'selected')
    * @returns {JSX.Element} - JSX for the DataTable header
    */
  const header = (e, type) => {
    let title = type == 'all' ? "All" : "Selected"
    title += ' ' + item.label;

    return <div className='d-flex justify-content-between p-0'>
      <h3>{title}</h3>
      {/* <input placeholder='Search by Code' onChange={(e) => search(e.value, type)} /> */}
    </div>
  }
  
  const mergeAndDeduplicate = (source, additions, previous = []) => {
    previous = Array.isArray(previous) ? previous : [];
    const additionIds = new Set([...additions.map(item => item._id), ...previous.map(item => item._id)]);
    const filteredSource = source.filter(item => !additionIds.has(item._id));
    return [...filteredSource, ...additions];
  }  
  /**
   * Function to handle shifting of data between tables (left or right).
   * @param {Array} arr - Array of data to be shifted
   * @param {String} type - Type of shift ('left' or 'right')
   */
 
  const shift = (arr, type) => {
    let source = type === 'right' ? allData : data;
    let destination = type === 'right' ? data : allData;
  
    const newSource = source.filter(obj => !arr.some(o => o._id === obj._id));
  
    const newDestination = [...destination, ...arr];
  
    if (type === 'right') {
      setData(newDestination);
      setAllData(newSource);
      setSelectedProcedures(newDestination);
      field.onChange(newDestination);
    } else {
      setAllData(newDestination);
      setData(newSource);
      setSelectedProcedures([]);
      field.onChange(newSource);
    }
  
    setSelectedAllData([]);
    setSelectedData([]);
  
  }

  // Custom row class styling function
  const rowClass = (data) => {
    return {
      'hide-row': data.isSubtotal,
    };
  };


  /**
 * Renders an input field within the DataTable. The type of input rendered depends on the modal context.
 *
 * @param {Object} rowData - The current row data of the DataTable.
 * @param {Object} column - The current column data where this input is being rendered.
 * @returns {JSX.Element} - Returns a MultiSelect component for selecting teeth in the 'TreatmentPlan' modal,
 *                           or a span element for other types of data.
 */

  const teethOptions = Array.from({ length: 32 }, (_, i) => ({ label: `${i + 1}`, value: i + 1 }));
  
  const renderInputField = (rowData, column) => {
    if (props.item.modal === "TreatmentPlan") {
      return (
        <MultiSelect
          value={rowData.teethNumber || []}
          options={teethOptions}
          onChange={(e) => handleToothNumberChange(rowData, e.value)}
          style={{ width: '150px' }}
          placeholder="Select teeth"
          display="chip"
          filter
          filterPlaceholder="Search teeth"
          filterLocale="en"
        />
      );
    } else {
      return <span>{rowData[column.field]}</span>;
    }
  };
  
  // Function to handle changes to tooth numbers in a treatment plan
  const handleToothNumberChange = (rowData, newValue) => {

    const newData = data.map((item) => {
      if (item._id === rowData._id) {
        return { ...item, teethNumber: newValue };
      }
      return item;
    });
    setData(newData);
    field.onChange(newData);
  };
  /**
    * Function to generate DataTable JSX based on type and column fields.
    * @param {String} type - Type of DataTable ('all' or 'selected')
    * @param {Object} columnFields - Configuration for columnshospitalsId
    * @returns {JSX.Element} - JSX for the DataTable
    */
  const getDatatable = (type, columnFields) => {
    return (
      <div className='' style={{ width: '48%', height: '400px' }}>
        <DataTable value={type === 'all' ? allData : data}
          selection={type === 'all' ? selectedAllData : selectedData}
          onSelectionChange={(e) => type === 'all' ? setSelectedAllData(e.value) : setSelectedData(e.value)}
          header={(e) => header(e, type)}
          rowClassName={rowClass}
          scrollable
          scrollHeight="350px"
          selectionMode="checkbox"
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }}></Column>
          {columnFields.map((obj) => {
            if (type === 'selected' && obj.type === 'input') {
              return <Column key={obj.field} header={obj.header} body={(rowData) => renderInputField(rowData, obj)} />;
            } else if (type === 'all' && obj.field !== 'toothNumber') {
              return <Column key={obj.field} header={obj.header} field={obj.field} style={obj.style ? obj.style : {}} filter={obj.filter ? obj.filter : false} 
              body={(rowData) => obj.field === 'fee' ? Number(rowData.fee).toFixed(2) : rowData[obj.field]} />;
            } else if (type === 'selected') {
              return <Column key={obj.field} header={obj.header} field={obj.field} style={obj.style ? obj.style : {}} filter={obj.filter ? obj.filter : false} 
              body={(rowData) => obj.field === 'fee' ? Number(rowData.fee).toFixed(2) : rowData[obj.field]} />;
            }
          })}
        </DataTable>
      </div>
    );
  };
  let getAutoCompleteField = (i, item, Controller, control, getValues, setValue, errors) => {

    const onfilterSelect = (value) => {
      const newProcedures = value.procedureSteps;
      const mergedProcedures = mergeAndDeduplicate(data, newProcedures, previousTxPlan);
      setValue(item.name,value);
      
      setData(mergedProcedures);
      setAllData(allData.filter(obj => !newProcedures.some(o => o._id === obj._id)));
      setSelectedProcedures(mergedProcedures);
      field.onChange(mergedProcedures);
      setPreviousTxPlan(newProcedures);
    }

    return <div>
        <Controller
            name={item.name}
            control={control}
            render={({ field, fieldState }) => (
                <DoAutoCompleteField
                    input={field}
                    id={field.id}
                    name={field.name}
                    field={field}
                    item={item}
                    filterField={item.filterField}
                    filterValue={item.filterValue}
                    filterType={item.isNotEq}
                    multiple={item.isMultiple}
                    fieldState={fieldState}
                    errors={errors}
                    screen={"Treatment Plan Modal"}
                    searchApi={item.searchApi}
                    onChange={onfilterSelect}
                    searchField={item.searchField}
                    filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                    placeholder={item.placeholder}
                    label={item.label}
                    setValue={setValue}
                    getValues={getValues}
                    formType={props.formType}
                />)}
        />
    </div>
}
  return (
    <div className='treatment-plan-container'>
       {props.item && props.item.modal && props.item.modal==="TreatmentPlan" && (
      <div style={{width:'50%'}}>
        {getAutoCompleteField(1, { name: 'txPlanId', type: 'relateAutoComplete', label: 'TX Plan', searchApi: "txPlans", searchField: "heading" }, Controller, control, getValues, setValue, errors)}
      </div>
      )}
      <div className='data-tables-container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {getDatatable('all', item.tableFields)}

        <div className='' style={{ width: '4%',paddingLeft:'15px' }}>
          <i className="pi pi-angle-right " style={{ fontSize: '2rem' }} onClick={() => shift(selectedAllData, 'right')}></i>
          <br />
          <i className="pi pi-angle-left" style={{ fontSize: '2rem' }} onClick={() => shift(selectedData, 'left')}></i>
        </div>
    {getDatatable('selected', item.tableFields)}

      </div>
    </div>
  );
}  

export default DragAndDropTables;
