import React, { useState, useEffect } from 'react';
import { Button, CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link, Navigate } from 'react-router-dom';


// import { connect } from 'react-redux';
// import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
// import {settingsAction} from '../../../../redux/actions/settingsAction'
import { settings } from '../../../../redux/actions/settingsAction'
import PropTypes from 'prop-types';

import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';

import renderCheckBoxField from '../../../../shared/components/form/CheckBox';

// Validate input
import validate from '../../../Validations/validate';

// Input field
import DefaultInput from '../../../../shared/components/form/DefaultInput';

// Config
import configMessage from '../../../../config/configMessages';
import fetchRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';

// Toaster message
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import PasswordField from '@/shared/components/form/password';

// Loader
import Loader from '../../../App/Loader';
import jwt_decode from "jwt-decode"
import ReCAPTCHA from "react-google-recaptcha";
import {
  browserName,
  osName, osVersion, deviceType
} from "react-device-detect";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CheckBoxField from '@/shared/components/form/CheckBox2';
import { Checkbox } from 'primereact/checkbox';
import DoInputField from '../../../Form/Fields/DoInputField';
import DoPasswordField from '../../../Form/Fields/DoPasswordField';

const publicIp = require('public-ip');
const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)

const LogInForm = (props) => {
  // static propTypes = {
  //   handleSubmit: PropTypes.func.isRequired,
  // };

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     deviceInfo: {},
  //     showPassword: false,
  //     isLoginDisabled: config.displayRecaptcha ? true : false,
  //     isLoading: true,
  //     loginRememberInfo: null,
  //     captchaValue: null,
  //     isRobot: false,
  //     isLoginSuccess: false,
  //     isLoading: false,
  //     loginRole: '',
  //     userDetails: {},
  //     displayDetailsModal: false,
  //     formFields:[{"name":"name","placeholder":"Name","label":"Name"},{"name":"email","placeholder":"Email","label":"Email"},{"name":"phone","placeholder":"Phone","label":"Phone"}]
  //   };

  //   this.getSettingsData()
  // }

  const [deviceInfo, setDeviceInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(config.displayRecaptcha ? true : false);
  const [loginRememberInfo, setLoginRememberInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRobot, setIsRobot] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [settingsData, setSettingsData] = useState(null);
  const [remember_me, setRemember_me] = useState(false);
  const [loginRole, setLoginRole] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [displayDetailsModal, setDisplayDetailsModal] = useState(false);
  //const formFields = [{ "name": "name", "placeholder":"Name","label":"Name"},{"name":"email","placeholder":"Email","label":"Email"},{"name":"phone","placeholder":"Phone","label":"Phone"}];
   const formFields =[{"name":"name","placeholder":"Name","label":"Name","type":"text"},{"name":"email","placeholder":"Email","label":"Email","type":"email"},{"name":"phone","placeholder":"Phone","label":"Phone","type":"text"}]
  const schema = yup.object().shape({   
    email: yup.string().email().required(),
    password: yup.string().required(),
  });
  

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getSettingsData();
    let ipAddr = '';
    publicIp.v4().then((res) => {
      ipAddr = res;
    })
    setDeviceInfo({
      ipAddress: ipAddr,
      browserName: browserName,
      osName: osName,
      osVersion: osVersion,
      deviceType: deviceType
    })
    const savedloginBody = localStorage.SavedloginBody ? JSON.parse(localStorage.SavedloginBody):null;
    localStorage.clear();
    if(savedloginBody){
      setRemember_me(true);
      setValue('email',savedloginBody.email);
      setValue('password',savedloginBody.password);
      localStorage.setItem('SavedloginBody',JSON.stringify(savedloginBody));
    }
  }, [])

  const loadGoogleComponent = (self) => {
    function handleCredentialResponse(response) {
      var data = jwt_decode(response.credential);
      if (data.email && data.name && config.displayGoogleLogin) {
        return fetchRequest('POST', 'auth/socialLogin', { name: data.name, email: data.email, role: "Admin", entityType: config.entityType, deviceInfo: deviceInfo })
          .then(async (response) => {
            if (response && response.respCode && response.respCode == 200) {
              self.setState({
                isLoading: true
              });
              let userDetails = response.details;
              if (userDetails && userDetails._id) {
                if (response.accessToken) {
                  let tokenInfo = {
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken,
                    tokenExpires: response.tokenExpires,
                  };
                  userDetails = { ...userDetails, ...tokenInfo };

                  // save user credentials in storage
                  localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                  localStorage.removeItem('sessionexpired');
                }
                if (userDetails && userDetails.rolePermissions) {
                  let permissions = userDetails.rolePermissions;
                  // save user permissions in storage
                  localStorage.setItem('rolePermissions', JSON.stringify(permissions));
                }
              }

              showToasterMessage(response.respMessage, 'success');

              if (userDetails.firstTimeLogin) {
                self.setState({
                  // isLoginSuccess: true,
                  displayDetailsModal: true,
                  isLoading: false,
                  userDetails: userDetails
                })
                if (self.props.load) {
                  setTimeout(() => {
                    self.props.load(self.state.userDetails)
                  }, 300);
                }

              } else {
                self.setState({
                  isLoginSuccess: true,

                })
              }
            } else if (response && response.errorMessage) {
              setTimeout(() => {
                self.setState({
                  isLoginDisabled: false,
                  isLoading: false
                })
              }, 250);
              showToasterMessage(response.errorMessage, 'error');
            }
            // settingsAction
          }).catch((err) => {
            self.setState({
              isLoginDisabled: false,
              isLoading: false
            });
          });
      }
    }
    if (config.displayGoogleLogin) {
      // window.onload = function () {
      if (self.state.settingsData && self.state.settingsData.GoogleClientId) {
        let GoogleClientId = self.state.settingsData && self.state.settingsData.GoogleClientId ? self.state.settingsData.GoogleClientId : null
        /* global google */
        google.accounts.id.initialize({
          client_id: GoogleClientId,
          callback: handleCredentialResponse
        });
        google.accounts.id.renderButton(
          document.getElementById("loginButton"),
          { theme: "outline", size: "large", width: document.getElementById('googleButton').clientWidth }  // customization attributes
        );
        // google.accounts.id.prompt(); // also display the One Tap dialog
      }
      // }
    }
    return true
  }
  // componentDidMount = () => {
  //   let deviceInfo = {}
  //   publicIp.v4().then((res) => {
  //     deviceInfo.ipAddress = res
  //   })
  //   deviceInfo.browserName = browserName
  //   deviceInfo.osName = osName
  //   deviceInfo.osVersion = osVersion
  //   deviceInfo.deviceType = deviceType
  //   this.setState({ deviceInfo: deviceInfo });
  // }
 const getLoginRememberInfo = async () => {
    let keysToRemove = ["loginCredentials", "rolePermissions", 'loginBody', 'sessionexpired'];
    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }

    let loginRememberInfo = localStorage.getItem('loginRememberInfo');
    loginRememberInfo = loginRememberInfo ? JSON.parse(loginRememberInfo) : null;
    reset(loginRememberInfo);
    // this.setState({
    //   loginRememberInfo: loginRememberInfo,
    //   remember_me: loginRememberInfo && loginRememberInfo.remember_me ? loginRememberInfo.remember_me : false
    // })
    setLoginRememberInfo(loginRememberInfo);
    setRemember_me(loginRememberInfo && loginRememberInfo.remember_me ? loginRememberInfo.remember_me : false);
  }

  const togglePassword = (e) => {
    e.preventDefault();
    // this.setState(prevState => ({ showPassword: !prevState.showPassword }));
    setShowPassword(!showPassword);
  }

 const submit = (values) => {
    if (values.email && values.password) {

      // this.setState({
      //   remember_me: values.remember_me,
      //   isLoginDisabled: true,
      //   isLoading: true
      // })
      // setRemember_me(values.remember_me);
      setIsLoginDisabled(true);
      setIsLoading(true);
      handleLoginUser(values);
    }
  }

 const handleLoginUser = async (values) => {
    let userBody = {
      email: values.email,
      password: values.password,
      entityType: config.entityType,
      deviceInfo: deviceInfo
    };
    localStorage.setItem('loginBody', JSON.stringify(userBody));
    if (captchaValue || !config.displayRecaptcha) {
      return fetchRequest('POST', apiCalls.loginUser, userBody)
        .then(async (response) => {
          if (response && response.respCode && response.respCode == 200) {
            // this.setState({
            //   isLoading: true
            // });
            setIsLoading(true);
            let userDetails = response.details;
            if (userDetails && userDetails._id) {
              if (response.accessToken) {
                let tokenInfo = {
                  accessToken: response.accessToken,
                  refreshToken: response.refreshToken,
                  tokenExpires: response.tokenExpires,
                };
                userDetails = { ...userDetails, ...tokenInfo };
                // this.getSettingsData(userDetails)
                // save user credentials in storage
                if(remember_me){
                  localStorage.setItem('SavedloginBody', JSON.stringify(userBody));
                }
                localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                localStorage.removeItem('sessionexpired');
                if (userDetails && userDetails.role && userDetails.role == 'Teacher') {                  
                  setLoginRole(userDetails.role);
                }
              }
              if(userDetails?.hospitalId?._id){
                localStorage.setItem('HospitalData',JSON.stringify(userDetails.hospitalId));        
              }
              if (userDetails && userDetails.rolePermissions) {
                let permissions = userDetails.rolePermissions;
                // save user permissions in storage
                localStorage.setItem('rolePermissions', JSON.stringify(permissions));
              }
            }
            showToasterMessage(response.respMessage, 'success');
            // await this.setState({
            //   isLoginSuccess: true,
            // }, () => {
              setIsLoginSuccess(true);
              if (remember_me) {
                var data = {
                  email: values.email,
                  password: values.password,
                  remember_me: this.state.remember_me
                }
                if (loginRememberInfo && loginRememberInfo.email &&
                  loginRememberInfo.email != values.email) {
                  localStorage.setItem('loginRememberInfo', JSON.stringify(data));
                } else if (!loginRememberInfo) {
                  localStorage.setItem('loginRememberInfo', JSON.stringify(data));
                }
              } else {
                localStorage.removeItem('loginRememberInfo');
              };
            // });
          } else if (response && response.errorMessage) {
            setTimeout(() => {
              // this.setState({
              //   isLoginDisabled: false,
              //   isLoading: false
              // })
              setIsLoginDisabled(false);
              setIsLoading(false);
            }, 250);
            showToasterMessage(response.errorMessage, 'error');
          }
          // settingsAction
        }).catch((err) => {
          // this.setState({
          //   isLoginDisabled: false,
          //   isLoading: false
          // });
          setIsLoginDisabled(false);
          setIsLoading(false);
        });
    }
  }

 const onChange = (value) => {
    if (value == 'remember') {
      // this.setState({
      //   remember_me: false
      // })
      setRemember_me(!remember_me);
    } if (config.displayRecaptcha) {
      // this.setState({
      //   captchaValue: value && value != "remember" ? value : null,
      //   isLoginDisabled: value && value != "remember" ? false : true,
      // })
      setCaptchaValue(value && value != "remember" ? value : null);
      setIsLoginDisabled(value && value != "remember" ? false : true)
    }
  }
 const getSettingsData = () => {
    let settingsData;
    // let filterCriteria = {};
    // filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.respCode) {
        settingsData = response.settings[0]
        settingsData["GoogleClientId"]=settingsData["users"].GoogleClientId;
        settingsData["GooglesecretKey"]=settingsData["users"].GooglesecretKey;
        // await this.setState({ settingsData: settingsData })
        // this.loadGoogleComponent(this, this.state)
        setSettingsData(settingsData);
      }
    })
    return settingsData;
  }
 const cancelReset = async type => {
    // await this.setState({
    //   isLoginSuccess: false,
    //   displayDetailsModal: false
    // })
    setIsLoginSuccess(false);
    setDisplayDetailsModal(false);
    localStorage.removeItem("rolePermissions");
    localStorage.removeItem("loginCredentials");
    await this.props.reset();
  }
  const handleModalSubmit = async formValues => {
    if (formValues) {
      delete formValues.email;
      delete formValues.password;
      formValues["firstTimeLogin"] = false
      let Url;
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        Url = `${apiCalls["employee"]}/${user._id}`;
      }
      return fetchRequest('PUT', Url, formValues)
        .then(async (response) => {
          if (response && response.respCode && response.respCode === 205) {
            showToasterMessage(response.respMessage, 'success');
            // await this.setState({
            //   isLoginSuccess: true,
            //   displayDetailsModal: false
            // })
            setIsLoginSuccess(true);
            setDisplayDetailsModal(false);
          } else if (response && response.errorMessage) {
            // await this.setState({
            //   isLoginSuccess: false,
            //   displayDetailsModal: false
            // })
            setIsLoginSuccess(false);
            setDisplayDetailsModal(false);
            showToasterMessage(response.errorMessage, 'error');
          }
        }).catch((err) => {
          // this.setState({
          //   isLoginSuccess: false,
          //   displayDetailsModal: false
          // })
          setIsLoginSuccess(false);
          setDisplayDetailsModal(false);
          return err;
        });

    } else {
      // await this.setState({
      //   isLoginSuccess: false,
      //   displayDetailsModal: false
      // })
      setIsLoginSuccess(false);
      setDisplayDetailsModal(false);
      return;
    }

  }
  // render() {
  //   const { handleSubmit } = this.props;
  //   const { showPassword } = this.state;

    return (
      <div>
        {displayDetailsModal ?
          <Modal
            isOpen={displayDetailsModal}
            toggle={cancelReset}
            centered
            className={`modal-dialog modal-dialog-centered modal-dialog--primary  modal-dialog--header`}
          >
            <ModalHeader className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn"
                type="button"
                onClick={cancelReset}
              />
              <p className="bold-text  modal__title"> {"Details"} </p>
            </ModalHeader>
            <ModalBody className="p-2">
              <Loader loader={isLoading} />

              <form className="form" onSubmit={handleSubmit(handleModalSubmit)}>
                <div className="row mx-1 mt-3 " style={{ width: '100%' }}>

                  {
                    formFields && formFields.length && formFields.length > 0 ?
                      formFields.map((item, index) => {
                        return (
                          <div className="col-sm-12 col-md-6">
                            <div className="form__form-group ">
                              <span className="form__form-group-label">{item.label}</span>
                              <div className="form__form-group-field">
                                {/* <Field
                                  name={item.name}
                                  component={DefaultInput}
                                  type="text"
                                  placeholder={item.placeholder}
                                  validate={required}
                                  isDisable={item.name && item.name == "email" ? true : false}
                                /> */}
                                <Controller
                                  name="email"
                                  control={control}
                                  render={({ field, fieldState }) => (
                                    <DoInputField
                                      input={field}
                                      id={field.id}
                                      name={field.name}
                                      field={field}
                                      type="email"
                                      style={{ width: "100%" }}
                                      fieldState={fieldState}
                                      errors={errors}
                                      placeholder="Email"
                                    />)}
                                />

                              </div>
                            </div>
                          </div>

                        )
                      }) : null

                  }
                  <div className="col-sm-12 text-center pt-3">
                    <div>
                      <Button
                        outline
                        color="primary"
                        type="buttom"
                        onClick={cancelReset}
                      >
                        Cancel
                      </Button>

                      <Button color="primary" type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </ModalBody>
          </Modal>
          :

          <form className="form" onSubmit={handleSubmit(submit)}>
            <Loader loader={isLoading} />
            <div className="form__form-group mt-2">
             
              <div>
                {/* <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div> */}
                {/* <Field
                  name="email"
                  component={DefaultInput}
                  type="email"
                  placeholder="Email"
                  validate={required}
                /> */}
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <DoInputField
                    input={field}
                    id={field.id}
                    label={"Email"}
                    name={field.name}
                    field={field}
                    style={{ width: "100%" }}
                    type="email"
                    fieldState={fieldState}
                    errors={errors}
                    placeholder="Email"
                  />)}
              />

              </div>
              {/* <small className='text-danger'>{errors.email?.message}</small> */}
            </div>
            <div className="form__form-group mt-2">
             
              <div >
                {/* <Field
                  name="password"
                  component={DefaultInput}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  validate={required}
                  from={"login"}
                /> */}
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoPasswordField
                      input={field}                      
                      name={field.name}
                      field={field}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder="Password"
                    />)}
                />
              </div>
              {/* <small className='text-danger'>{errors.password?.message}</small> */}
            </div>

            <div className="form__form-group my-2">
              <div className='d-flex justify-content-between align-items-center'>
                <div>
                  {/* <Field
                    name="remember_me"
                    component={renderCheckBoxField}
                    label="Remember me"
                    checked={remember_me}
                    onChange={() => onChange('remember')}
                  /> */}
                  {/* <div className='d-flex'>                    
                    <input
                      type='checkbox'
                      placeholder='Remember Me'
                      {...register('remember_me')}                     
                    />                    
                  </div>
                  <label htmlFor=''>Remember Me</label> */}
                    
                                    
                  <Controller
                    name="remember_me"
                    control={control}                    
                    render={({ field, fieldState }) => (
                      <>                        
                        <div className='d-flex align-items-center'>
                      <div>
                      <Checkbox inputId={field.name} checked={remember_me} style={{ marginBottom: '3px',height:'21px',width:'21px' }} inputRef={field.ref} onChange={(e) => onChange('remember')} />
                      </div>
                      <div>
                        <label htmlFor={field.name} className='ms-1' style={{fontSize:"14px"}}>Remember Me</label>
</div>
                      </div>
                      </>
                    )}
                  />
                  
                </div>
                <div >
                  <Link to="/forgot_password">Forgot password?</Link>
                </div>
              </div>

            </div>
            <div className="w-100 mt-2">
              {config.displayRecaptcha && settingsData && settingsData.GooglesecretKey ?
                < ReCAPTCHA
                  sitekey={settingsData && settingsData.GooglesecretKey}
                  // sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
                  onChange={onChange}
                  type="image"
                  className='recap'
                />
                : null}
            </div>
            <div className="account__btns" style={{ width: '100%' }}>
              <Button className="ml-auto" style={{ width: '100%' }}
                color="primary"
                disabled={isLoginDisabled}
              >LOGIN</Button>
            </div>
            {config.displayGoogleLogin ?
              <div className='line'>
                <div className='text'>
                  or
                </div>
              </div>
              : null
            }
            <div className='d-flex flex-column w-100 pb-3'>
              {config.displayGoogleLogin ?
                <div id="googleButton">
                  <div id="loginButton" className="strike w-100"></div>
                </div>
                : null
              }
            </div>
            <div className="form__form-group mb-2" >
              {/* <div className="form__form-group-field" style={{ float: 'left'}}>
                Dont' have an account ?&nbsp;<Link to="/register" style={{ fontWeight:'bold' }}>Register</Link>
              </div> */}
            </div>
            {/* change */}
            {/* Navigate to Home */}
            {isLoginSuccess ? <Navigate to="/create_appointments" /> : null}

          </form >

        }
      </div>
    );
  }
// }

// LogInForm = reduxForm({
//   form: 'log_in_form', // a unique identifier for this form
//   validate,
//   enableReinitialize: true,
// })(LogInForm);

// // You have to connect() to any reducers that you wish to connect to yourself
// LogInForm = connect(
//   state => ({
//     initialValues: state.commonData.data // pull initial values from account reducer
//   }),
//   {
//     load: loadAccount,// bind account loading action creator
//     settingsLoad: settings //binding settingsDAta
//   }
// )(LogInForm);

export default LogInForm;
