import React from "react";
import { RadioButton } from 'primereact/radiobutton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoRadioButtons = (props) => {
    const id = props.id ? props.id : props.name;
    let field = props.field;
    let value = props.value
    const item = props.item ? props.item : {}

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };


    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange(e.value); 
        }
        field.onChange(e);
    };

    return (
        <div className="flex flex-column h-100">
            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize"  style={item.labelStyle ? item.labelStyle : {} }>{item.label ? item.label : props.name}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <div className="flex align-items-center h-100">
                {props.options ? props.options.map((option) => {
                    return <div key={option.name}>
                        <RadioButton {...field} inputId={option.label} name={props.name} inputRef={field.ref} value={option.value}
                            // checked={value && value==option.value ? true : field.value === option.value}  
                            checked={field.value === option.value} onChange={handleChange}
                        />
                        <label htmlFor={option.label} className="ms-1 me-4">
                            {option.label}
                        </label>
                    </div>
                }) :

                    <>
                        <RadioButton {...field} inputRef={field.ref} value={props.value} checked={field.value === value} />
                        <label className="ms-1 me-4">
                            {props.label}
                        </label>
                    </>}

            </div>
            <small className="text-danger " style={{ marginTop: "5px" }}>{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>

        </div>
    )

}

export default DoRadioButtons;