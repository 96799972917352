import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const AutoNotes = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [prompts, setPrompts] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Auto Notes");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    getAvailablePrompts();
  }, []);

  const getAvailablePrompts = () =>{
    let apiUrl = apiCalls.availablePrompts
    let method = "GET"
    return fetchMethodRequest(method, apiUrl)
    .then(async (response)=>{
      if(response && response.availableprompts){
        let prompt = response.availableprompts.map((item) => { 
          return {label: item.promptText, value: item.promptText};
      });
      setPrompts(prompt)
      }
    })
  }

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 300,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        displayinregisterForm: false,
        capitalizeTableText:true, 
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      
      {
        name: "text",
        fieldName: "text",
        type: "textarea",
        placeholder: "Text",
        value: "",
        label: "Text",
        width: "100px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Text",
        derivedValue: "text=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "text",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "text",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        style: {
          maxWidth: '600px',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
          textOverflow: 'ellipsis',
        }
      },
      {
        name: "availablePrompts",
        fieldName: "availablePrompts",
        type: "dropDown",
        placeholder: "Available Prompts",
        value: "",
        label: "Available Prompts",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Available Prompts",
        derivedValue: "availablePrompts=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "availablePrompts",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [],
        filterElement: [],
        dependent: [],
        textAlign: "center",
        show: false,
        field: "availablePrompts",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 160,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "category",
        fieldName: "category",
        type: "text",
        placeholder: "Category",
        value: "",
        label: "Category",
        // disableLabel:true,
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "category=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "category",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        addFunctionnality:true
      },
      {
        name: "text",
        fieldName: "text",
        type: "textarea",
        placeholder: "Text",
        value: "",
        label: "Text",
        width: "100px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "text=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "text",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },

      {
        type:'isEmpty',
        customWidthClass:'col-md-6'
      },

      {
        name: "promptInput",
        fieldName: "promptInput",
        type: "text",
        placeholder: "Text",
        value: "",
        label: "Text",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue:"promptInput=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "promptInput",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidthClass:'col-md-3 mt-1'
      },
      // {
      //   name: "availablePrompts",
      //   fieldName: "availablePrompts",
      //   type: "dropDown",
      //   placeholder: "Available Prompts",
      //   value: "",
      //   label: "Available Prompts",
      //   width: "120px",
      //   maxFileSize: "",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   derivedValue: "availablePrompts=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   options: prompts,
      //   id: "availablePrompts",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: "",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   isMultipleRequired: "",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   filterElement: [],
      //   dependent: [],
      //   show: true,
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   isAddFormHidden: false,
      //   isEditFormHidden: false,
      //   customWidthClass:'col-md-3'
      // },
      {
        name: "availablePrompts",
        value: "",
        type: "relateAutoComplete",
        placeholder: "Available Prompts",
        label: "Available Prompts",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "availablePrompts",
        derivedValue: "availablePrompts=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "availablePrompts",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "description",
        fieldType: "relateAutoComplete",
        populateValue: "availablePrompts",
        populateField: "description",
        // controllerName: "",
        searchApi: "availablePrompts",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "availablePrompts",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        type:'isButton',
        customWidthClass:'col-md-2'
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        displayinregisterForm: false,
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Name",
        derivedValue: "name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "category",
        fieldName: "category",
        type: "text",
        placeholder: "Category",
        value: "",
        label: "Category",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Category",
        derivedValue: "category=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "category",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "category",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "text",
        fieldName: "text",
        type: "textarea",
        placeholder: "Text",
        value: "",
        label: "Text",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Text",
        derivedValue: "text=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "text",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "text",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availablePrompts",
        fieldName: "availablePrompts",
        type: "dropDown",
        placeholder: "Available Prompts",
        value: "",
        label: "Available Prompts",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Available Prompts",
        derivedValue: "availablePrompts=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "availablePrompts",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [],
        filterElement: [],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "availablePrompts",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.autoNotes}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          // globalSearch={'Display Name/Email'}
          // type='AutoNotes'
          // apiUrl={apiCalls.AutoNotes}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.autoNotes}
          globalSearch={"Name"}
          displayName="Auto Notes"
          type="Auto Notes"
          routeTo={'autoNotes'}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.autoNotes?.toLowerCase()}
          apiUrl={apiCalls.autoNotes}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="autoNotes"
          apiUrl={apiCalls.autoNotes}
        />
      ) : null}
    </span>
  );
};

export default AutoNotes;
