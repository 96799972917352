import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';

function InsuranceConfirmationModal(props) {
    let displayText = `is this patient, the subscriber`

    return (
        <div>
            <Modal isOpen={props.openConfirmationModal} className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width'>
                <ModalBody className='deleteModalBody'>
                    <div style={{ marginBottom: '1.5rem' ,fontSize:"14px"}}> {displayText} ?</div>
                    <Button color="primary" outline onClick={props.confirm}
                        className='deleteModalBtn'>Yes</Button>
                    <Button color="primary" outline onClick={props.openSubscriberModal} className='deleteModalBtn marginRight'>No</Button>
                    <Button color="primary" outline onClick={props.closeConfirmationModal}
                        className='deleteModalBtn'>Cancel</Button>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default InsuranceConfirmationModal


