import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from '../../App/Loader';
import DoSelectField from '../Fields/DoSelectField';
import DoInputField from '../Fields/DoInputField';
import DoTextareaField from '../Fields/DoTextareaField';
import { Button } from 'primereact/button';
import config from '../../../config/config';
import {
  Modal, ModalHeader, ModalBody,
  Card, CardBody, ButtonToolbar, ButtonGroup, Row, Col, Badge
} from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment'
import DoDateField from '../Fields/DoDateField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faCheck } from '@fortawesome/free-solid-svg-icons';
import InsuranceBenifitInfoModal from './InsuranceBenifitInfoModal';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import * as yup from "yup";
import apiCalls from '../../../config/apiCalls';
import DoAutoCompleteField from '../Fields/DoAutoCompleteField';

function InsuranceFormModal(props) {


  let [isLoading, setIsLoading] = useState(false);
  let [allOriginalFormFields, setAllOriginalFormFields] = useState(props.formFields());
  const [employerformFields, setEmployerformFields] = useState();
  const [subscribersFormFields, setSubscribersFormFields] = useState();
  const [formType, setFormType] = useState(props.formType);
  const [isOpenBenifitInfo, setIsOpenBenifitInfo] = useState(false);
  const [prevent, setPrevent] = useState(false);
  const [benifitInformation, setBenifitInformation] = useState({});
  const [tableRowData, setTableRowData] = useState(props.tableRowData);
  const [otherBenifitsData , setOtherBenifitsData] = useState([]);
  const [benifitInfoTableView, setBenifitInfoTableView] = useState([]);
  const [patientId, setPatientId] = useState({});
  const [hideAddBtn, setHideAddBtn] = useState(true);
  const [otherBenifitId, setOtherBenifitId] = useState('');
  const [editInfo, setEditInfo] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm(
      {resolver: yupResolver( yup.object().shape({
        employer: yup.string().required('Employeer is Required'),
        carrier: yup.object().required('Carrier is Required'),
        subscriberId: yup.string().required('SubscriberId is Required'),
        fromDate: yup.string().required('FromDate is Required'),
        toDate: yup.string().required('ToDate is Required'),
        groupName: yup.string().required('Group Name is Required'),
        groupNumber: yup.string().required('Group Number is Required'),
        planType: yup.string().required('Plan Type is Required'),
        feeSchedule: yup.string().required('Fee Schedule is Required'),
       
       

      })),}
    );


  useEffect(() => {
   
    if(props.formType == "edit"){
      let rowData = props.tableRowData && props.tableRowData.benifitsInfo ? props.tableRowData.benifitsInfo : {}
        getRowData(props.tableRowData,'edit');
        if(props.tableRowData.benifitsInfo && Object.keys( props.tableRowData.benifitsInfo)?.length > 0){
          onChangeBenifit(rowData);
        }
    }
    const originalFormFields = props.formFields()
    let employerformFields = [];
    let subscribersFormFields = [];
    originalFormFields.map((item) => {
      if (item.displayInSubscriberInfo) {
        subscribersFormFields.push(item)
      } else {
        employerformFields.push(item)
      }
    })
    setSubscribersFormFields(subscribersFormFields);
    setEmployerformFields(employerformFields);
    getBenifitInformation();

  }, []);

  useEffect(() => {
    
    let patientData = JSON.parse(localStorage.getItem('PatientData'));
    if(patientData !== null ){
      setPatientId(patientData)
    }
  }, [localStorage.getItem('PatientData')]);

  let getFormFieldItem = async (key) => {
    let formFields = await allOriginalFormFields;
    for (let i = 0; i < formFields.length; i++) {
      if (formFields[i].name === key) {
        return formFields[i];
      }
    }
    return null;
  }

  const getRowData = async (selectedRowInfo , type) =>{

    
    
    let fields = allOriginalFormFields;
    fields.forEach((item) => {
      if(selectedRowInfo){
        setValue(item.name,selectedRowInfo[item.name])
      }
    });

       let fromDate = moment(selectedRowInfo.fromDate).toDate();
       let toDate = moment(selectedRowInfo.toDate).toDate();
       setValue('fromDate' , fromDate);
       setValue('toDate' , toDate);

      setValue('benifitsInfo' , selectedRowInfo.benifitsInfo ? selectedRowInfo.benifitsInfo :[])
      setOtherBenifitsData(selectedRowInfo.benifitsInfo ? selectedRowInfo.benifitsInfo : [])
  }

  const getBenifitInformation = () =>{
    let apiUrl = apiCalls.benifits
    let method = "GET"
    return fetchMethodRequest(method, apiUrl)
    .then(async (response)=>{
      if(response && response.benifits){
        // setBenifitInformation(response.benifits && response.benifits[0].otherBenifits)
      }
    })
  }

  const sendDataToServer = (formValues) => {
    let userBody = Object.assign({}, formValues);
    userBody.benifitsInfo =  otherBenifitsData;
    // userBody.benifitsInfo._id = otherBenifitId ? otherBenifitId :  props.tableRowData.benifitsInfo._id;

    userBody.benifitsInfo._id = otherBenifitId ? otherBenifitId : props.tableRowData.benifitsInfo?._id ?? null;

    if(props.formType === "add"){
      userBody.patientId = patientId ;
    }else{
      userBody.patientId = props.tableRowData && props.tableRowData.patientId ? props.tableRowData.patientId : null;
    }

    let validationExists;

    setIsLoading(true);

    if (formValues) {
      let method, apiUrl;
      if (formType === 'edit') {
        method = 'PUT';
        apiUrl = `${props.apiUrl}/${props.tableRowData._id}`;
      } else {
        method = 'POST';
        apiUrl = props.apiUrl;
      }


      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            await props.getDataFromServer(props.filterCriteria);
            showToasterMessage(response.respMessage, 'success');
            if (props.formType === 'edit') {
              props.closeFormModal('edit', response);
            }else {
                props.closeFormModal('save', response);
              }
              setTableRowData({});
            // clearFormFields();
            // clearFormFields();
            // props.reset();

          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
          setIsLoading(false);
        }).catch((err) => {
          return err;
        });





    } else {
      return
    }
  }

  const submit = (formValues) => {
    if(formValues && !prevent){
      sendDataToServer(formValues)
    }
  }

  let closeBenifitModal = async () => {
    setPrevent(false);
    setIsOpenBenifitInfo(false);
  }

  let closeFormModal = async () => {
    // clearFormFields();
    // props.reset();
    // props.getDataFromServer(props.filterCriteria);
    props.closeFormModal();
  }

  let getHeader = () => {
    const { type, routeTo } = props;
    // const absoluteRouteTo = routeTo.startsWith('/') ? routeTo : `/${routeTo}`; 
    return (
      <div className='d-flex mx-2'>
        <div className='col-12 px-0 pb-1'>
          <span className='float-left pt-2'>
            <h4 style={{ textTransform: 'capitalize' }}><b>
              <div className='text-white'>
                {type}

              </div>
              {/* {formType !== 'add' && displayBreadCrumbValue ? ` / ${displayBreadCrumbValue} ` : null} */}
            </b> </h4>
          </span>
        </div>
      </div>
    )
  }

 

  const handleHandlerSelect = (e) =>{
   
    if(e){
      setValue('phone' , e.phone)
      setValue('address' , e.address)
      setValue('address2' , e.address2)
      setValue('email' , e.email)
      setValue('city' , e.city)
      setValue('fax' , e.fax)
      setValue('logInInformation' , e.logInInformation)
      setValue('electronicId' , e.payerId)
      setValue('zipCode' , e.pinCode)
      setValue('state' , e.state)
      setValue('webSite' , e.webSite)
    }
   
  }

  let getAutoComplete = (i, item) => {
    // let itemTemplateFunction;
    
    // if (item.populateValue && item.populateField) {
    //   itemTemplateFunction = (option) => {
    //     // console.log("Processing option in itemTemplate:", option);
    //     const value = option[item.populateValue];
    //     const label = option[item.populateField];
    //     return (
    //       <div className="autocomplete-item">
    //         <div>{`${value} (Name : ${label})`}</div>
    //       </div>
    //     );
    //   };
    // }
    // const handleHandlerSelect = (value) => {
    //   setSelectedHandler(value);
    // };
    return (
     
      <div className='col-md-4 mt-0'>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoAutoCompleteField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              item={item}
              filterField={item.filterField}
              filterValue={item.filterValue}
              filterType={item.isNotEq}
              multiple={item.isMultiple}
              fieldState={fieldState}
              errors={errors}
              screen={props.type}
              searchApi={item.searchApi}
              // itemTemplate={itemTemplateFunction}
              searchField={item.searchField}
              allow={props.allowDuplicates}
              filterFieldType={item.filterFieldType ? item.filterFieldType : null}
              placeholder={item.placeholder}
              label={item.label}
              setValue={setValue}
              // getValues={getValues}
              onChange = {handleHandlerSelect}
              formType={props.formType}
              populateValue={item.populateValue}
              populateField={item.populateField}
            />)}
        />
      </div>
    )
  }

  let getDropdown = (i, item) => {
    return (
      <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-4 `} p-1 mt-1`}>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              markReq={item.required}
              input={field}
              id={field.id}
              item={item}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
            // onChange={e => handleFnEnableControlsBasedOnValue2(e, item.dependent)}
            />)}
        />
      </div>
    )
  }

  let getDefault = (i, item) => {
    let { t } = props;
    return (
      <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-4 `} p-1`}>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoInputField
              markReq={item.required}
              input={field}
              item={item}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              type={item.type ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }
  let getTextArea = (i, item) => {
    return (
      <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-4 `} p-1`}>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoTextareaField
              markReq={item.required}
              input={field}
              id={field.id}
              label={item.label}
              item={item}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )

  }

  let getDate = (i, item) => {

    return (

      <div className='col-md-4 p-1 mt-1'>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoDateField
              markReq={item.required}
              input={field}
              item={item}
              label={item.label}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : "date"}
              formType={props.formType}
            // onChange={onChange} 
            />)}
        />
      </div>
    )
  }

  const getBenifitInfo = (e) => {
    // if(patientId !== undefined){
      setPrevent(true)
      e.stopPropagation();
      e.preventDefault();
      setIsOpenBenifitInfo(true)
    // }
    // else{
    //   showToasterMessage('Please Select The Patient Name','warning');
    // }
   
  }

  const getEditBenifitInfo = (e) =>{
    // setFormType('edit')
    setEditInfo(true)
    e.stopPropagation();
      e.preventDefault();
      setIsOpenBenifitInfo(true);

  }

  const onChangeBenifit =async (values)=>{
    setBenifitInformation(values);

    let benifitsDetails =[
      {category:"Diagnostic",level:'diagnosticIndividual',type:'',percentage:'diagnosticPercentage', timePeriod:''},
      {category:"X-Ray",level:'xRayIndividual',type:'',percentage:'xRayPercentage' , timePeriod:''},
      {category:"Preventive",level:'routineIndividual',type:'',percentage:'routinePercentage' , timePeriod:''},
      {category:"Restorative",level:'',type:'',percentage:'restorativePercentage' , timePeriod:''},
      {category:"Endo",level:'',type:'',percentage:'endoPercentage' , timePeriod:''},
      {category:"Oral Surgery",level:'',type:'',percentage:'oralSurgeryPercentage' , timePeriod:''},
      {category:"Crowns",level:'',type:'',percentage:'crownsPercentage' , timePeriod:''},
      // {category:"Prosth",level:'diagnosticIndividual',type:'',percentage:'prosthodonticPercentage'},
      // {category:"Perio",level:'diagnosticIndividual',type:'',percentage:'diagnosticPercentage'},
      // {category:"Diagnostic",level:'diagnosticIndividual',type:'',percentage:'diagnosticPercentage'},
    ]

    let defaultVal ={level:'',type:'%',percentage:'%' , timePeriod : ''}
    let modifiedData = [];
    benifitsDetails =await benifitsDetails.map((obj)=>{
      let newObj={category: obj.category}
      let toogle = false;
      for (const key in obj) {
        if(key != 'category' ){
          toogle =values[(obj[key])] ? true : toogle
          newObj[key]=values[(obj[key])]?values[(obj[key])]:defaultVal[key];
        }
      }
      if(toogle){
        modifiedData.push(newObj)
      }
      return newObj;
    })
    setBenifitInfoTableView(modifiedData)
  }

  const getFields = (item, i) => {

    return (<>
      <div> <b>Employers</b> </div>

      <div className='form insuranceCard mb-2'>
        {
          employerformFields && employerformFields.map((item, i) => {
            return item.type === "dropDown" ?
              getDropdown(i, item)
              : item.type === "relateAutoComplete" ?
                getAutoComplete(i,item)
               : item.type === "textarea" ?
                 getTextArea(i, item)
                 : getDefault(i, item)
          })
        }
      </div>

      <div className='mt-4'> <b>Subscribers Information</b></div>
      <div className='form insuranceCard mb-2' >
        {
          subscribersFormFields && subscribersFormFields.map((item, i) => {
            return item.type === "dropDown" ?
              getDropdown(i, item)
              : item.type === "textarea" ?
                getTextArea(i, item)
                : item.type === "date" ?
                  getDate(i, item)
                  : getDefault(i, item)
          })
        }
      </div>

      <div className='mt-4'> <b>Benifit Information</b>
        { otherBenifitId 
        || (  tableRowData && tableRowData.benifitsInfo && Object.keys(tableRowData.benifitsInfo)?.length > 0)  
         ?
        <FontAwesomeIcon
              className='genderIconAlignment'
              color='white'
              icon='edit'
              data-toggle="tool-tip" title="Edit"
              style={{ color: '#024a88', width: '15', cursor: 'pointer' ,marginLeft:"1rem"}}
              onClick={getEditBenifitInfo} 
          />
        :  
        <Button rounded className="drugsfield_add_icon" style={{ fontSize: "10px" }} onClick={getBenifitInfo}><FontAwesomeIcon
          icon={faPlus}
          size='lg'
          data-toggle="tool-tip"
          title={"Show"}
          type="button"
          onClick={getBenifitInfo}
        /></Button>
          }
      </div>
      {<div className='form insuranceCard mb-2' >
        
        <DataTable value={benifitInfoTableView} className="mt-1 w-100">
          {/* {formFields.map((col) => { */}
          <Column field={'level'} header={'Level'}  style={{ textAlign: 'center' }}/>
          <Column field={'type'} header={'Type'} style={{ textAlign: 'center' }} />
          <Column field={'category'} header={'Category'} style={{ textAlign: 'center' }} />
          <Column field={'percentage'} header={'%'} style={{ textAlign: 'center' }} />
          <Column field={'amount'} header={'Amt'} />
          <Column field={'timePeriod'} header={'Time Period'} style={{ textAlign: 'center' }} />
          {/* })}  */}
        </DataTable>
      </div>}

    </>)
  }

  let getButtonToolbar = () => {
    let { t } = props;
    return (
      <div className='d-flex mt-5'>
        <div className='col-12 px-0'>
          <span className='float-end'>
            <ButtonToolbar>
              {formType === 'add' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                {('Cancel') ? ('Cancel') : 'Cancel'}
              </Button> : formType !== 'add' ? <Button color='primary' type="button" onClick={() =>
                // setFormType("view")
                closeFormModal()
              }>
                {('Cancel') ? ('Cancel') : 'Cancel'}
              </Button> : formType === 'add' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                {('Cancel') ? ('Cancel') : 'Cancel'}
              </Button> : null}
               <Button color='primary' outline type="submit">
                {formType && formType === 'add' ?
                  ('Save') ? ('Save') : 'Save'
                  : ('Update') ? ('Update') : 'Update'
                }
              </Button>
            </ButtonToolbar>
          </span>
        </div>
      </div>
    )
  }
  

  let getScreenBody = () => {
    return (
      <>
        <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
          <Loader loader={isLoading} />
          <div
            className={`row form mx-2 ${props.formType === 'add' ? "pt-3" : ""}`}
          >
            {getFields()}
          </div>
          {getButtonToolbar()}
        </form>
      </>
    )
  }

  //getScreenView
  let getScreenView = () => {
    let { handleSubmit, type, openFormModal } = props;
    // const modalClass = classNames({
    //   // 'modal-dialog--colored': "red",
    //   // 'modal-dialog--header': "hai",
    // });
    const headerClassName = 'd-flex justify-content-between pb-2 mb-2 form_form-header';
    return (
      <div>
        {props.openFormModal ?
          <div>
            <div className={headerClassName}>
              <div >{getHeader()}</div>
            </div>
            {getScreenBody()}
          </div> : null

        }
      </div>
    );
  }


  return (
    <div>
      {getScreenView()}
      {/* {getDeleteDialog()} */}

      <InsuranceBenifitInfoModal

        isOpenBenifitInfo={isOpenBenifitInfo}
        closeFormModal={closeBenifitModal}
        benifitInfoFormFields={props.benifitInfoFormFields}
        frequencyFormFields={props.frequencyFormFields}
        othoFormFields={props.othoFormFields}
        getDataFromServer = {props.getDataFromServer}
        filterCriteria={props.filterCriteria}
        formType={formType}
        apiUrl = {apiCalls.benifits}
        displayViewOfForm={'modal'}
        setOtherBenifitsData={setOtherBenifitsData}
        tableRowData={tableRowData}
        patientId = {patientId}
        setBenifitInformation={onChangeBenifit}
        setHideAddBtn = {setHideAddBtn}
        setOtherBenifitId={setOtherBenifitId}
        editTrue = {editInfo}      
        otherBenifitId = {otherBenifitId}  
      />


    </div>

  );
}

export default InsuranceFormModal
