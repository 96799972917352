import React, { useEffect, useState, useContext } from "react";
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { GlobalContext } from "../../App/App";
const DoAutoCompleteField = (props) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [selectedDisplayValue, setSelectedDisplayValue] = useState("");
    const [isValidate, setIsValidate] = useState(false);
    const [noData, setNoData] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [disable, setDisable] = useState(props.disabled || props?.item?.disabled ? true : false);
    const [dependencyFieldError, setDependencyFieldError]= useState('');
    const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);

    const context = useContext(GlobalContext);

    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field ? props.field : {};
    const placeholder = props.placeholder;
    const item = props.item ? props.item : {};
    const [hasMounted, setHasMounted] = useState(false);
    const parent = props.watch && item.hasDependencyField && item.hasDependency ? props.watch( item.hasDependencyField):null;

    useEffect(() => {
        setPatientData();
        getAllSuggestions();
    }, []);

    useEffect(() => {
        if(hasMounted && props.setValue && item.hasDependencyField && item.hasDependency){
            props.setValue(item.name,null);
        }else{
            setHasMounted(true);
        }
    }, [parent]);


    useEffect(() => {
        if(props.setValue && props?.formType !== 'edit'){
            props.setValue('clinicId',context?.clinicData);
        }
      }, [context?.clinicData]);
    const setPatientData = async () => {
        if (props.formType === "add" && props.name === 'patientId') {
            await getAllSuggestions();
            let storedPatientDetails
            if (localStorage.getItem('PatientData')) {
                storedPatientDetails = JSON.parse(localStorage.getItem('PatientData'));
            }
            if (props.name === 'patientId') {
                setDisable(!!storedPatientDetails); // Disable only if patient details are present
            }
            if (storedPatientDetails) {

                if (props.name === 'patientId' && storedPatientDetails) {
                    field.onChange(storedPatientDetails);
                    onSelectValue({ value: storedPatientDetails });

                    if (props.screen === 'Appointments') {
                        props.onChange(storedPatientDetails);
                    }
                }
                if (props.screen === 'Appointments') {
                    props.onChange(storedPatientDetails);
                }
            }

        }
    };

    useEffect(() => {

        if (props.formType === "add" && props.name === 'patientId') {
            let storedPatientDetails=null;

            if (localStorage.getItem('PatientData')) {
                storedPatientDetails = JSON.parse(localStorage.getItem('PatientData'));
            }
            setDisable(storedPatientDetails ? true : false);
            if (storedPatientDetails) {
                // Handle patientId for all screens, but format display value only if populateValue and populateField are applicable
                if (props.name === 'patientId' && storedPatientDetails) {
                    field.onChange(storedPatientDetails); // Assuming to set the whole patient details object for consistency
                    onSelectValue({ value: storedPatientDetails });

                    if (props.screen === 'Appointments') {
                        props.onChange(storedPatientDetails);
                    }
                }
            }
            setTimeout(() => { onSelectValue({ value: storedPatientDetails }) },150 )

        }
    }, [localStorage.getItem('PatientData')]);
    const onSelectValue = (e) => {
        if (!item.isMultiple) {
            field.onChange(e.value);
        }
        
        // if(props.screen === 'Treatment Plan'){
        //     if(name == "patientId"){
        //         props.setValue('treatmentPlanHeading', '')
        //     }
        // }
        
        // if (props.screen === 'Appointments' && props.field.name === 'handler' && e.value) {
        //     props.onHandlerSelect(e.value);
        // } else if (props.screen === 'Appointments' && (props.field.name === 'patientId' || props.field.name === "reasons") && e.value) {
        //     props.onPatientSelect(e.value , props.field.name , props);
        // } else if (props.screen === 'Schedules' && props.field.name === 'handler' && e.value) {
        //     props.onHandlerSelect(e.value);
        // } 
        if (props.onChange) {
            props.onChange(e.value,props.field.name,props);
        }
        if(item.onChange){
            item.onChange(e.value, props.setValue);
        }
    }

    useEffect(() => {//when we change the clinic on the patient Nav bar the function is render
        if (props.formType === 'add' && item.name === 'clinicId') {
            const data = JSON.parse(localStorage.getItem('ClinicData'));
            setDisable(data ? true : false);
            setTimeout(() => { onSelectValue({ value: data }) }, 100)
        }
    }, [localStorage.getItem('ClinicData')]);

    useEffect(() => {//Render this function when we change the Data
        setSearchFieldValueOnTheBody(field.value);
        // if(!field.value){
        //     onSelectValue({value:null});
        // }
    }, [field.value])

    const onSelectRecord = (e) => {
        setNoData(false);
        setIsValidate(false);
        setErrorMessage('');
        if (props.input) {
            const { name, onChange } = this.props.input;
            onChange(e.value);
            this.props.handleAutoCompleteData(e.value, name)
            if (e && e.value) {
                this.removeDuplicates(e.value, name);
            }
        }

    }


    const clearData = () => {//when we need to clear the data on the field
        if (!item.disabled) {
            onSelectValue({ value: undefined });
            setSelectedDisplayValue("");
            setDisable(false);
        }
    }

    // to remove duplicates
    const removeDuplicates = () => {
        if (this.state.filteredSuggestions) {
            setSuggestions(this.state.filteredSuggestions);
        }
    }

    /**
     * 
     * @param {Object} value 
     */
    const setSearchFieldValueOnTheBody = (value) => {//Set the Search field value on the Body due to filter data by the search field on the DataTable Filter
        if (item.searchField && props.setValue && value && value[item.searchField]) {
            props.setValue(item.name + item.searchField, value[item.searchField]);
            if (item.tableFilterKeys && item.tableFilterKeys.length > 0) {
                item.tableFilterKeys.map((key) => {
                    if (value[key]) {
                        props.setValue(item.name + key, value[key]);
                    }
                })
            }
        }
    }

    const getAllSuggestions = (event, type) => {

        setHasAttemptedFetch(true);
        
        setIsValidate(false);
        setNoData(false);
        setErrorMessage('');
        let filterCriteria = {
            limit: 30,
            page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };

        if(props.clinicFilter && context && context.clinicData){
            filterCriteria.criteria = [{key:"clinicId", value: context.clinicData._id, type:"eq"}]
        }

        if(props.providerFilter && context && context.clinicData){
            filterCriteria.criteria = [{key:"clinicsId", value: [context.clinicData._id], type:"in"}]
        }
    
        if (item.hasDependency && item.hasDependencyField) {
            const dependencyValue = props.getValues(item.hasDependencyField);
            if (!dependencyValue || !dependencyValue["_id"]) {
                setDependencyFieldError('Dependency field is required');
                setFilteredSuggestions([]);
                return;  
            }
        }
        if(item.defaultFilterCriteria && item.defaultFilterCriteria.length>0){
            filterCriteria['criteria']=[...filterCriteria['criteria'],...item.defaultFilterCriteria];
        }
    
        setDependencyFieldError(''); 
    
        if (item.hasDependencyField && props.getValues(item.hasDependencyField) && props.getValues(item.hasDependencyField)["_id"]) {
            filterCriteria['criteria'].push({
                "key": item.fieldForKey,
                "value": props.getValues(item.hasDependencyField)["_id"],
                "type": item.hasDependencyFieldType || "eq"
            });
        }
    
        if (event && event.query) {
            if (item.searchPatternMatch && !item.searchPatternMatch?.test(event.query)) {//searchPatternMatch it should Regular expresetion
                return;
            }

            if (item?.searchGlobally) {
                filterCriteria["globalSearch"] = { "value": event.query, "type": "user" };
            } else {
                filterCriteria['criteria'].push({
                    "key": props.searchField,
                    "value": event.query,
                    "type": "regexOr"
                });
            }
        };
    
        let apiUrl = props.searchApi;
        let url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
            return fetch('GET', url)
            .then((response) => {
                let dropdownData = response[apiUrl]|| response[apiUrl.toLowerCase()]  || [];
                if (dropdownData.length === 0) {
                    setNoData(true);
                }
                setFilteredSuggestions(dropdownData);
            }).catch((err) => {
                setErrorMessage('Failed to fetch data');
                return err;
            });
    };
    
    const setSuggestions = async (dropdownData) => {
        // if (props.screen === 'Users' && props.name === "provider") {
            //     dropdownData = dropdownData.filter(item => item.role === 'Doctor');
        // } else if (props.screen === 'Appointments' && props.name === "handler") {
            //     dropdownData = dropdownData.filter(item => item.role === 'Operative');
        // } else if (props.screen === 'Appointments' && props.name === "provider") {
            //     dropdownData = dropdownData.filter(item => item.role === 'Doctor');
        // } else if (props.screen === 'Schedules' && props.name === "handler") {
            //     dropdownData = dropdownData.filter(item => item.role === 'Operative');
        // }
        if (props.input && props.input.value) {
            if (props.input.value.length > 0) {
                let values = props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
                setFilteredSuggestions(dropdownData);
    }

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    return (
        <div className="flex flex-column">
            
            {!item.disableLabel && <div className="doautocomplete-label flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>}
            <div className="d-flex align-items-center">
                <AutoComplete
                    className="w-100"
                    inputId={field.name}
                    value={field.value && field.value[item?.populateValue] && field.value[item?.populateField] ? `${field.value[item?.populateValue]} (${field.value[item?.populateField]})` : field.value}
                    onChange={field.onChange}
                    // inputRef={field.ref}
                    suggestions={filteredSuggestions}
                    completeMethod={getAllSuggestions}
                    placeholder={placeholder}
                    field={props.searchField}
                    dropdown={true}
                    multiple={props.multiple}
                    itemTemplate={!noData ? props.itemTemplate : false}
                    selectionLimit={props.selectionLimit ? props.selectionLimit : config.selectionLimit}
                    onSelect={(e) => onSelectValue(e)}
                    disabled={disable}
                    style={item.style ? item.style : {}}
                    showEmptyMessage={true}
                    emptyMessage='No Records'
                />
                {item.clearable && field.value && <FontAwesomeIcon
                    className=''
                    color={field.value ? 'red' : 'grey'}
                    icon={faTimes}
                    size='lg'
                    data-toggle="tool-tip"
                    onClick={clearData}
                    style={{ width: '1.5rem' }}
                />}
            </div>
            {/* {hasAttemptedFetch && dependencyFieldError && <small className="text-danger">{dependencyFieldError}</small>} */}
             <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
        </div>
    )

}

export default DoAutoCompleteField;