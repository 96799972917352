import React, { useEffect, useState , useContext } from "react";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import { Accordion, Card, Container, Row, Col, Navbar, Nav,Image } from 'react-bootstrap';
import { Button as ReactStrapButton } from 'reactstrap';
import { GlobalContext } from "../../../App/App";
import PDFViewer from "../../../Form/Fields/PdfViewer";
// config file


const Imaging = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeKey, setActiveKey] = useState("0");
  const context = useContext(GlobalContext);
  const [folders, setFolders] = useState([
    { title: "Medical History", details: "Records of past medical history", photos: [] },
    { title: "Patient Attachments", details: "Basic information of patients", photos: [] },
    { title: "Forms", details: "Patients forms", photos: [] },
    { title: "Treatment Plans", details: "Planned medical treatments", photos: [] },
    { title: "Tooth Charts", details: "Charts showing tooth positions and conditions", photos: [] }
  ]);

  useEffect(() => {
    const fetchPatientImagingData = async () => {
      const filterCriteria = {
        limit: 50, page: 1,
        criteria: [{ key: 'patientId', value: context?.patientData?._id, type: 'eq' }],
        direction: 'desc', softfield: 'created'
      };
  
      const response = await fetchMethodRequest('GET', `${apiCalls.imageMaintaining}?filter=${JSON.stringify(filterCriteria)}`);
      if (response && response.imagemaintainings) {
        categorizeData(response.imagemaintainings);
      }
    };
  
    // Reset selected item and folders before fetching new data
    setSelectedItem(null);
    setFolders(folders.map(folder => ({ ...folder, photos: [] })));
  
    fetchPatientImagingData();
  }, [context.patientData]);
  
  const categorizeData = (data) => {
    const folderConfig = {
      "Treatment Plan": "Treatment Plans",
      "Patient Image": "Patient Attachments",
      "Patient Form": "Forms",
    };
  
    const updatedFolders = folders.map(folder => ({
      ...folder,
      photos: data.reduce((acc, item) => {
        item.attachment.forEach(attachment => {
            const fileType = attachment.filePath.split('.').pop().toLowerCase();
            let urlPath;
            if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
              urlPath = `${config.imgUrl}/patients/${attachment.filePath}`;
            } else if (fileType === 'pdf') {
              urlPath = `${config.imgUrl}/${attachment.filePath}`; 
            } else {
              return;
            }
            acc.push({
              name: attachment.fileName,
              url: urlPath,
              type: fileType
            });
          
        });
        return acc;
      }, [])
    }));
    setFolders(updatedFolders);
  };
  
  const handleSelect = (item, event) => {
    event.stopPropagation();
    setSelectedItem(item);
  };

  
  const renderContent = (item, context = 'folder') => {
    if (!item || !item.url) return <h5>Select an item to view its details.</h5>;
    const fileType = item.url.split('.').pop().toLowerCase();
    let imageSize = {};
    
    if (context === 'folder') {
      imageSize = { width: '100px', height: '100px' };  
    } else if (context === 'detailView') {
      imageSize = { width: '300px', height: '300px' }; 
    }
  
    switch (fileType) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return (
          <Image 
            src={item.url} 
            style={{ width: imageSize.width, height: imageSize.height, maxWidth: '100%', maxHeight: '100%' }} 
            fluid 
          />
        );
      default:
        return null;
    }
  };
  
  
  
  const renderFileName = (item) => {
    if (!item || !item.url) return null;
    const fileType = item.url.split('.').pop().toLowerCase();
    if (['png', 'jpg', 'jpeg', 'gif', 'pdf'].includes(fileType)) {
      return <p style={{ cursor: 'pointer' }}>{item.name}</p>;
    }
  };
  
  const renderPdfViewer = (item) => {
    if (item && item.url && item.url.endsWith('.pdf')) {
      return (
        <div style={{ width: '100%', height: '500px', border: 'none' }}>
          <PDFViewer
            url={item.url}
            canvasStyle={{ width: '100%', height: '100%' }}
          />
        </div>
      );
    }
    return <p>PDF not available</p>;
  };
  
  return (
    <Container fluid>
    <Row>
      <Col md={4} lg={3} className="mb-4">
        <div style={{ maxHeight: '700px', overflowY: 'auto' }}>
          <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
            {folders.map((folder, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{folder.title}</Accordion.Header>
                <Accordion.Body>
                  {folder.details}
                  {folder.photos.map((photo, photoIndex) => (
                    <div key={`${photo.name}-${photoIndex}`} onClick={(e) => handleSelect(photo, e)}>
                      {renderContent(photo, 'folder')}
                      {renderFileName(photo)}
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            ))}
            </Accordion>
        </div>
      </Col>
      <Col md={8} lg={9}>
        <Card>
            <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              {selectedItem && selectedItem.url.endsWith('.pdf')
                ? renderPdfViewer(selectedItem)
                : renderContent(selectedItem, 'detailView')}
            </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
  );
}
export default Imaging;
