import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import FamilyRelations from "./FamilyRelations";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// config file

const Patients = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [stateOptions, setStateOptions] = useState();

  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Patients");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    setStateData();
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  // componentDidMount = () => {
  //   //Screen permisions value can be edit,view, no view
  //   let screenPermissions = RolePermissions.screenPermissions('Test');
  //   if (screenPermissions) {
  //     this.setState({
  //       rolePermission: screenPermissions
  //     })
  //   }
  //   // this.getRoleListFromServer()
  // }
  
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const setStateData = async () => {
    let arr = [];
    if (localStorage.StatesOptions) {
      arr = await JSON.parse(localStorage.StatesOptions);
    } else {
      let a = await fetchMethodRequest('GET', apiCalls.Settings).then(res => {
        if (res.settings[0]?.states?.length) {
          arr = res.settings[0]?.states.map(option => { return { label: option.split('-')[1].trim(), value: option, displayLabel: option } })
        }
      })
    }
    setStateOptions(arr);
  }

  const titleOptions = [
    { label: "Mr", value: "Mr", color: "primary" },
    { label: "Mrs", value: "Mrs", color: "primary" },
    { label: "Miss", value: "Miss", color: "primary" },
    { label: "Ms", value: "Ms", color: "primary" },
  ];

  const genderOptions = [
    { label: "Male", value: "Male", color: "primary" },
    { label: "Female", value: "Female", color: "primary" },
    { label: "Other", value: "Other", color: "primary" },
  ];

  const positionOptions = [
    { label: "Single", value: "Single", color: "primary" },
    { label: "Married", value: "Married", color: "primary" },
    { label: "Child", value: "Child", color: "primary" },
    { label: "Widowed", value: "Widowed", color: "primary" },
    { label: "Divorced", value: "Divorced", color: "primary" },
  ];

  const preferContactMethodOptions = [
    { label: "Contact Method1", value: "Contact Method1", color: "primary", },
    { label: "Contact Method2", value: "Contact Method2", color: "primary", },
    { label: "Contact Method3", value: "Contact Method3", color: "primary", },
  ];

  const preferConfirmMethodOptions = [
    { label: "Confirm Method1", value: "Confirm Method1", color: "primary", },
    { label: "Confirm Method2", value: "Confirm Method2", color: "primary", },
    { label: "Confirm Method3", value: "Confirm Method3", color: "primary", },
  ]

  const excludeAutomatedMsgsOptions = [
    { label: "Msgs1", value: "Msgs1", color: "primary" },
    { label: "Msgs2", value: "Msgs2", color: "primary" },
    { label: "Msgs3", value: "Msgs3", color: "primary" },
  ];

  const preferRecallMethodOptions = [
    { label: "Recall Method 1", value: "Recall Method 1", color: "primary", },
    { label: "Recall Method 2", value: "Recall Method 2", color: "primary", },
    { label: "Recall Method 3", value: "Recall Method 3", color: "primary", },
  ];

  const languageOptions = [
    { label: "English", value: "English" },
    { label: "Spanish", value: "Spanish" },
    { label: "French", value: "French" },
    { label: "Dutch", value: "Dutch" },
  ];

  const referredFromOptions = [
    { label: "Form 1", value: "Form 1", color: "primary" },
    { label: "Form 2", value: "Form 2", color: "primary" },
    { label: "Form 3", value: "Form 3", color: "primary" },
  ];

  const specialtyOptions = [
    { label: "Specified", value: "Specified" },
    { label: "Unspecified", value: "Unspecified" },
  ];

  const creditTypeOptions = [
    { label: "Credit Type 1", value: "Credit Type 1", color: "primary" },
    { label: "Credit Type 2", value: "Credit Type 2", color: "primary" },
    { label: "Credit Type 3", value: "Credit Type 3", color: "primary" },
  ];

  const billingTypeOptions = [
    { label: "Billing Type 1", value: "Billing Type 1", color: "primary", },
    { label: "Billing Type 2", value: "Billing Type 2", color: "primary", },
    { label: "Billing Type 3", value: "Billing Type 3", color: "primary", },
  ];

  const primaryProviderOptions = [
    { label: "Primary Provider 1", value: "Primary Provider 1", color: "primary", },
    { label: "Primary Provider 2", value: "Primary Provider 2", color: "primary", },
    { label: "Primary Provider 3", value: "Primary Provider 3", color: "primary", },
  ];
  const secondaryProviderOptions = [
    { label: "Secondary Provider1", value: "Secondary Provider1", color: "primary", },
    { label: "Secondary Provider 2", value: "Secondary Provider 2", color: "primary", },
    { label: "Secondary Provider 3", value: "Secondary Provider 3", color: "primary", },
  ];

  const feeScheduleOptions = [
    { label: "Schedule 1", value: "Schedule 1" },
    { label: "Schedule 2", value: "Schedule 2" },
    { label: "Schedule 2", value: "Schedule 2" },
  ];

  const insuranceOnClick = (item) => {

      if (localStorage.PatientData) {
        navigate('/create_insurance');
      } else {
        localStorage.setItem('PatientData', JSON.stringify(item))
        setTimeout(() => {
          window.location.href = '/create_insurance';
          // navigate('/create_insurance');
        }, 300);
      }

  }

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "name",
      //   type: "text",
      //   placeholder: "Name",
      //   label: "Name",
      //   width: "130px",
      //   addFormOrder: 6,
      //   editFormOrder: 6,
      //   header: "Name",
      //   derivedValue: "name=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "name",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "true",
      //   controllerId: null,
      //   textAlign: "left",
      //   show: true,
      //   field: "name",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   fieldType: "Link",
      //   style: {
      //     color: "#0e4768",
      //     cursor: "pointer",
      //     textTransform: "capitalize",
      //   },
      // },
      {
        name: "firstName",
        type: "text",
        placeholder: "First Name",
        label: "First Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "First Name",
        derivedValue: "firstName=undefined",
        capitalizeTableText: true,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "firstName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "Clinics_abbrevation",
      //   type: "text",
      //   placeholder: "Clinics_abbrevation",
      //   label: "Clinics_abbrevation",
      //   header: "Clinics abbrevation",
      //   width: 110,
      //   parentField: "clinicId",
      //   id: "Clinics_abbrevation",
      //   displayinlist: "true",
      //   fieldType: "RACSubField",
      //   textAlign: "Center",
      //   subFieldName: "abbrevation",
      //   show: true,
      //   field: "Clinics_abbrevation",
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
       
      // {
      //   name: "name",
      //   type: "derivedFields",
      //   placeholder: "Name",
      //   label: "Name",
      //   width: "120px",
      //   addFormOrder: 5,
      //   editFormOrder: 5,
      //   stringType: "string",
      //   header: "Name",
      //   derivedValue: "name=firstName+lastName",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   isClickable: false,
      //   filter: false,
      //   actions: ["firstName", "lastName"],
      //   actionsNumber: [],
      //   id: "name",
      //   displayinaddForm: false,
      //   displayineditForm: false,
      //   displayinregisterForm: false,
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "false",
      //   controllerId: null,
      //   textAlign: "Center",
      //   show: true,
      //   field: "name",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
        label: "Last Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Last Name",
        derivedValue: "lastName=undefined",
        capitalizeTableText: true,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "lastName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        // fieldType: "Link",
        // style: {
        //   color: "#0e4768",
        //   cursor: "pointer",
        //   textTransform: "capitalize",
        // },
      },

      {
        name: "patientId",
        type: "text",
        placeholder: "Patient Id",
        label: "Patient Id",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Patient Id",
        derivedValue: "patientId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "patientId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "fullID",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      
      {
        header: "Clinic ID",
        derivedValue: "clinicId=undefined",
        capitalizeTableText: false,
        sortable: false,
        width: "120px",
        actionsNumber: [],
        id: "clinicId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        displayOptionsInActions: false,
        globalSearchField: "false",
        searchField: "clinicId",
        fieldType: "relateAutoComplete",
        textAlign: "Center",
        show: true,
        field: "clinicId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        header: "Clinic Name",
        derivedValue: "clinicName=undefined",
        capitalizeTableText: false,
        sortable: false,
        width: "120px",
        actionsNumber: [],
        id: "clinicName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        displayOptionsInActions: false,
        globalSearchField: "false",
        searchField: "abbrevation",
        fieldType: "RACSubField",
        parentField:"clinicId",
        subFieldName:"abbrevation",
        textAlign: "Center",
        show: true,
        field: "abbrevation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "dateOfBirth",
        // name: "dateOfBirth",
        type: "date",
        placeholder: "Date Of Birth",
        label: "Date Of Birth",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Date Of Birth",
        derivedValue: "dateOfBirth=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "dateOfBirth",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateDBFormat,
        fieldType: "Date",
        textAlign: "Center",
        ageCaluclationField:'age',
        show: true,
        // field: "dateOfBirth",
        field: "dateOfBirth",
        isDob : true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "gender",
        type: "dropDown",
        placeholder: "Gender",
        label: "Gender",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Gender",
        derivedValue: "gender=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "gender",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: genderOptions,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "gender",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phoneNumber",
        type: "number",
        placeholder: "Work Phone",
        label: "Work Phone",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Phone",
        derivedValue: "phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "phoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "Address",
        derivedValue: "address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "lastVisited",
        type: "date",
        placeholder: "Last Visited",
        label: "Last Visited",
        width: 90,
        header: "Last Visited",
        derivedValue: "lastVisited=undefined",
        actions: [],
        actionsNumber: [],
        id: "lastVisited",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateDBFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "lastVisited",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Insurance",
        type: "dropDown",
        placeholder: "Insurance",
        label: "Insurance",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Insurance",
        derivedValue: "Insurance=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Insurance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "isInsurance",
        options: [
          { label: "In Active", value: "In Active", color: "danger" },
          {label: "Active", value: "Active" , color:'sucess'}
        ],
        fieldType: "dropDown",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "planType",
        type: "text",
        placeholder: "Plan Type",
        label: "planType",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Plan Type",
        derivedValue: "planType=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "planType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        fieldType:'relateAutoComplete',
        searchField:"planType",
        field: "planType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      }, 
      // {
      //   name: "insuranceName",
      //   type: "text",
      //   placeholder: "Plan Type",
      //   label: "insuranceName",
      //   width: "130px",
      //   addFormOrder: 6,
      //   editFormOrder: 6,
      //   header: "Insurance Name",
      //   derivedValue: "insuranceName=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "insuranceName",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "true",
      //   controllerId: null,
      //   textAlign: "left",
      //   show: true,
      //   field: "insuranceName",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      // },
    ];
    data.push({
      "show": true,
      "textAlign": "center",
      "width": 60,
      "fieldType": "Actions",
      "field": "Actions",
      "header": "Actions",
      "filter": false,
      "sortable": false,
      displayInSettings: true,
    })
    return data;
  };


  const getFamilyRelations = (props) =>{
    return <FamilyRelations {...props}/>
  }

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Patient",
        value: "Patient",
      },
      {
        label: "NonPatient",
        value: "NonPatient",
      },
      {
        label: "Inactive",
        value: "Inactive",
      }, {
        label: "Archived",
        value: "Archived",
      }, {
        label: "Deceased",
        value: "Deceased",
      },
    ];
    return [
      {
        name: "clinicId",
        type: "relateAutoComplete",
        placeholder: "Select a Clinic",
        label: "Clinic ID",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "clinicId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clinicId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1011,
        searchField: "fullClinicName",
        fieldType: "relateAutoComplete",
        displayFields: ["abbrevation"],
        controllerName: "Clinics",
        searchApi: apiCalls.clinics,
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "provider",
        type: "relateAutoComplete",
        placeholder: "Select a Provider",
        label: "Provider",
        defaultFilterCriteria :[{ "key": 'role', "value": config.doctorRole,"type": "regexOr"} ],
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "provider=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "provider",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1011,
        searchField: "name",
        hasDependency: true,
        fieldForKey: "clinicsId",
        hasDependencyField: "clinicId",
        hasDependencyFieldType:'in',
        fieldType: "relateAutoComplete",
        populteFields: [],
        searchApi: apiCalls.users,
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "fullID",
        type: "uniqueField",
        placeholder: "Patient ID",
        label: "Patient ID",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "PatientID=undefined",
        capitalizeTableText: false,
        sortable: false,
        id: "patientId",
        // customWidthClass: 'col-lg-4 col-md-6',
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: true,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        disabled: true,
        showOrHideFields: [],
        fieldName: "patientId",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "title",
        type: "dropDown",
        placeholder: "Title",
        label: "Title",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "title=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "title",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: titleOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "firstName",
        type: "text",
        placeholder: "First Name",
        label: "First Name",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "firstName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
        label: "Last Name",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "lastName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "preferredName",
        type: "text",
        placeholder: "Preferred Name/Middle Initial",
        label: "Preferred Name/Middle Initial",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "preferredName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "preferredName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "salutation",
        type: "text",
        placeholder: "Salutation",
        label: "Salutation",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "salutation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "salutation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "gender",
        type: "radio",
        placeholder: "Gender",
        label: "Gender",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "gender=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "gender",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: genderOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "dateOfBirth",
        type: "date",
        maxDate:true,
        placeholder: "Date Of Birth",
        label: "DOB",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "dateOfBirth=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "dateOfBirth",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateDBFormat,
        fieldType: "Date",
        ageCaluclationField: 'age',
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "age",
        type: "number",
        placeholder: "Age",
        label: "Age",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue: "age=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "age",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        disabled: true,
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },{
        name: "phoneNumber",
        type: "number",
        placeholder: "Phone",
        label: "Phone",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        derivedValue: "phoneNumber=undefined",
        maxLength: config.phoneNumberLength,
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        // customWidthClass: 'col-lg-4 col-md-6',
        fieldType: 'phone',
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      }, {
        name: "telePhoneNumber",
        type: "number",
        placeholder: "Wireless Phone Number",
        label: "Wireless Phone",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        derivedValue: "telePhoneNumber=undefined",
        maxLength: 10,
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "telePhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        // customWidthClass: 'col-lg-4 col-md-6',
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      }, {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        id: "email",
        width: 150,
        addFormOrder: 20,
        editFormOrder: 20,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        displayinregisterForm: "true",
        // customWidthClass: 'col-lg-4 col-md-6',
        disabled: false,
        isEditDisabled: true,
        show: true,
        globalSearchField: "true",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        required: true,
      },

      
      {
        name: "position",
        type: "dropDown",
        placeholder: "Position",
        label: "Position",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "position=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "position",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: positionOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "chartNumber",
        type: "number",
        placeholder: "Chart Number",
        label: "Chart Number",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue: "chartNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "chartNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
       {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 23,
        derivedValue: "address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "address2",
        type: "text",
        placeholder: "Address2",
        label: "Address2",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
        label: "City",
        width: "150px",
        addFormOrder: 25,
        editFormOrder: 25,
        derivedValue: "city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        customWidthClass: 'col-lg-2 col-md-2',
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "state",
        type: "dropDown",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: stateOptions,
        itemTemplate: true,
        filter: true,
        customWidthClass: 'col-lg-1 col-md-2',
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "ZIP Code",
        label: "ZIP Code",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        customWidthClass: 'col-lg-1 col-md-2',
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "preferContactMethod",
        type: "dropDown",
        placeholder: "Prefer Contact Method",
        label: "Prefer Contact Method",
        width: "130px",
        addFormOrder: 28,
        editFormOrder: 28,
        derivedValue: "preferContactMethod=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "preferContactMethod",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: preferContactMethodOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "preferConfirmMethod",
        type: "dropDown",
        placeholder: "Prefer Confirm Method",
        label: "Prefer Confirm Method",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 29,
        derivedValue: "preferConfirmMethod=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "preferConfirmMethod",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: preferConfirmMethodOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "excludeAutomatedMsgs",
        type: "dropDown",
        placeholder: "Exclude Automated Msgs",
        label: "Exclude Automated Msgs",
        width: "130px",
        addFormOrder: 30,
        editFormOrder: 30,
        derivedValue: "excludeAutomatedMsgs=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "excludeAutomatedMsgs",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: excludeAutomatedMsgsOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "preferRecallMethod",
        type: "dropDown",
        placeholder: "Prefer Recall Method",
        label: "Prefer Recall Method",
        width: "130px",
        addFormOrder: 31,
        editFormOrder: 31,
        derivedValue: "preferRecallMethod=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "preferRecallMethod",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: preferRecallMethodOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "language",
        type: "dropDown",
        placeholder: "Language",
        label: "Language",
        width: "130px",
        addFormOrder: 32,
        editFormOrder: 32,
        derivedValue: "language=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "language",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        options: languageOptions,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "referredFrom",
        type: "dropDown",
        placeholder: "Referred From",
        label: "Referred From",
        width: "130px",
        addFormOrder: 33,
        editFormOrder: 33,
        derivedValue: "referredFrom=referredFrom=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "referredFrom",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: referredFromOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "specialty",
        type: "dropDown",
        placeholder: "Specialty",
        label: "Specialty",
        width: "130px",
        addFormOrder: 32,
        editFormOrder: 34,
        derivedValue: "specialty=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "specialty",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        options: specialtyOptions,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "creditType",
        type: "dropDown",
        placeholder: "Credit Type",
        label: "Credit Type",
        width: "130px",
        addFormOrder: 35,
        editFormOrder: 35,
        derivedValue: "creditType=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "creditType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: creditTypeOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "billingType",
        type: "dropDown",
        placeholder: "Billing Type",
        label: "Billing Type",
        width: "130px",
        addFormOrder: 36,
        editFormOrder: 36,
        derivedValue: "billingType=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "billingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: billingTypeOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "primaryProvider",
        type: "dropDown",
        placeholder: "Primary Provider",
        label: "Primary Provider",
        width: "130px",
        addFormOrder: 37,
        editFormOrder: 37,
        derivedValue: "primaryProvider=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "primaryProvider",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: primaryProviderOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "secondaryProvider",
        type: "dropDown",
        placeholder: "Secondary Provider",
        label: "Secondary Provider",
        width: "130px",
        addFormOrder: 38,
        editFormOrder: 38,
        derivedValue: "secondaryProvider=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "secondaryProvider",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: secondaryProviderOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "feeSchedule",
        type: "dropDown",
        placeholder: "Fee Schedule",
        label: "Fee Schedule",
        width: "130px",
        addFormOrder: 39,
        editFormOrder: 39,
        derivedValue: "feeSchedule=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "feeSchedule",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: feeScheduleOptions,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "image",
        fieldName: "image",
        type: "profile",
        placeholder: "Image",
        value: "",
        label: "Image",
        width: "120px",
        maxFileSize: 1,
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Image",
        derivedValue: "image=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: false,
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        fieldType: "fileUpload",
        imagePath: "patients",
        textAlign: "center",
        show: true,
        field: "image",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        showImageRadioButtons:true,
        customWidthClass:'col-12'
      },
      {
        name: "familyMember",
        type: 'customType',
        customType:getFamilyRelations,
        placeholder: "Family Member",
        label: "Family Member",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "familyMember=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "familyMember",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        customWidthClass: 'col-12',
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "clinicId",
        type: "relateAutoComplete",
        placeholder: "Clinic ID",
        label: "Clinic ID",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Clinic ID",
        derivedValue: "clinicId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clinicId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1011,
        searchField: "abbrevation",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["abbrevation"],
        controllerName: "Clinics",
        searchApi: "clinics",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "clinicId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Clinics_abbrevation",
        type: "text",
        placeholder: "Clinics_abbrevation",
        label: "Clinics_abbrevation",
        header: "Clinics abbrevation",
        width: 110,
        parentField: "clinicId",
        id: "Clinics_abbrevation",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "abbrevation",
        show: true,
        field: "Clinics_abbrevation",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "title",
        type: "dropDown",
        placeholder: "Title",
        label: "Title",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Title",
        derivedValue: "title=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "title",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Mr", value: "Mr", color: "primary" },
          { label: "Mrs", value: "Mrs", color: "primary" },
          { label: "Miss", value: "Miss", color: "primary" },
          { label: "Ms", value: "Ms", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "title",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "firstName",
        type: "text",
        placeholder: "First Name",
        label: "First Name",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "First Name",
        derivedValue: "firstName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "firstName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
        label: "Last Name",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Last Name",
        derivedValue: "lastName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "lastName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "derivedFields",
        placeholder: "Name",
        label: "Name",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: "string",
        header: "Name",
        derivedValue: "name=firstName+lastName",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: ["firstName", "lastName"],
        actionsNumber: [],
        id: "name",
        displayinaddForm: false,
        displayineditForm: false,
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "preferredName",
        type: "text",
        placeholder: "Preferred Name/Middle Initial",
        label: "Preferred Name/Middle Initial",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Preferred Name/Middle Initial",
        derivedValue: "preferredName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "preferredName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "preferredName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "salutation",
        type: "text",
        placeholder: "Salutation",
        label: "Salutation",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Salutation",
        derivedValue: "salutation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "salutation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "salutation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "gender",
        type: "dropDown",
        placeholder: "Gender",
        label: "Gender",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Gender",
        derivedValue: "gender=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "gender",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Male", value: "Male", color: "primary" },
          { label: "Female", value: "Female", color: "primary" },
          { label: "Other", value: "Other", color: "primary" },
          { label: "UnKnown", value: "UnKnown", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "gender",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "dateOfBirth",
        type: "date",
        placeholder: "Date Of Birth",
        label: "Date Of Birth",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Date Of Birth",
        derivedValue: "dateOfBirth=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "dateOfBirth",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "MM-DD-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "dateOfBirth",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "age",
        type: "number",
        placeholder: "Age",
        label: "Age",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Age",
        derivedValue: "age=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "age",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "age",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "position",
        type: "dropDown",
        placeholder: "Position",
        label: "Position",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Position",
        derivedValue: "position=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "position",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Single", value: "Single", color: "primary" },
          { label: "Married", value: "Married", color: "primary" },
          { label: "Child", value: "Child", color: "primary" },
          { label: "Widowed", value: "Widowed", color: "primary" },
          { label: "Divorced", value: "Divorced", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "position",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "chartNumber",
        type: "number",
        placeholder: "Chart Number",
        label: "Chart Number",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Chart Number",
        derivedValue: "chartNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "chartNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "chartNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "askToArriveEarly",
        type: "number",
        placeholder: "Ask To Arrive Early",
        label: "Ask To Arrive Early",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Ask To Arrive Early",
        derivedValue: "askToArriveEarly=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "askToArriveEarly",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "askToArriveEarly",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phoneNumber",
        type: "number",
        placeholder: "Work Phone",
        label: "Work Phone",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "Work Phone",
        derivedValue: "phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "telePhoneNumber",
        type: "number",
        placeholder: "Wireless Phone",
        label: "Wireless Phone",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "Wireless Phone",
        derivedValue: "telePhoneNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "telePhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "telePhoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "password",
        type: "password",
        placeholder: "Password",
        label: "Password",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        header: "Password",
        derivedValue: "password=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "password",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "Address",
        derivedValue: "address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address2",
        type: "text",
        placeholder: "Address2",
        label: "Address2",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Address2",
        derivedValue: "address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address2",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        type: "email",
        placeholder: "City",
        label: "City",
        width: "150px",
        addFormOrder: 25,
        editFormOrder: 25,
        header: "City",
        derivedValue: "city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        type: "text",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "State",
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "state",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "ZipCode",
        label: "ZipCode",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "ZipCode",
        derivedValue: "zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "zipCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "preferContactMethod",
        type: "dropDown",
        placeholder: "Prefer Contact Method",
        label: "Prefer Contact Method",
        width: "130px",
        addFormOrder: 28,
        editFormOrder: 28,
        header: "Prefer Contact Method",
        derivedValue: "preferContactMethod=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "preferContactMethod",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          {
            label: " Contact Method1",
            value: " Contact Method1",
            color: "primary",
          },
          {
            label: " Contact Method2",
            value: " Contact Method2",
            color: "primary",
          },
          {
            label: " Contact Method3",
            value: " Contact Method3",
            color: "primary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "preferContactMethod",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "preferConfirmMethod",
        type: "dropDown",
        placeholder: " Prefer Confirm Method",
        label: " Prefer Confirm Method",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 29,
        header: " Prefer Confirm Method",
        derivedValue: "preferConfirmMethod=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "preferConfirmMethod",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          {
            label: "Confirm Method",
            value: "Confirm Method",
            color: "primary",
          },
          {
            label: "Confirm Method1",
            value: "Confirm Method1",
            color: "primary",
          },
          {
            label: "Confirm Method2",
            value: "Confirm Method2",
            color: "primary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "preferConfirmMethod",
        showOrHideFields: [],
        mobile: true,
        required: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "excludeAutomatedMsgs",
        type: "dropDown",
        placeholder: "Exclude Automated Msgs",
        label: "Exclude Automated Msgs",
        width: "130px",
        addFormOrder: 30,
        editFormOrder: 30,
        header: "Exclude Automated Msgs",
        derivedValue: "excludeAutomatedMsgs=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "excludeAutomatedMsgs",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Msgs1", value: "Msgs1", color: "primary" },
          { label: "Msgs2", value: "Msgs2", color: "primary" },
          { label: "Msgs3", value: "Msgs3", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "excludeAutomatedMsgs",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "preferRecallMethod",
        type: "dropDown",
        placeholder: "Prefer Recall Method",
        label: "Prefer Recall Method",
        width: "130px",
        addFormOrder: 31,
        editFormOrder: 31,
        header: "Prefer Recall Method",
        derivedValue: "preferRecallMethod=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "preferRecallMethod",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          {
            label: "Recall Method 1",
            value: "Recall Method 1",
            color: "primary",
          },
          {
            label: "Recall Method 2",
            value: "Recall Method 2",
            color: "primary",
          },
          {
            label: "Recall Method 3",
            value: "Recall Method 3",
            color: "primary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "preferRecallMethod",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "language",
        type: "text",
        placeholder: "Language",
        label: "Language",
        width: "130px",
        addFormOrder: 32,
        editFormOrder: 32,
        header: "Language",
        derivedValue: "language=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "language",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "language",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "referredFrom",
        type: "dropDown",
        placeholder: "Referred From",
        label: "Referred From",
        width: "130px",
        addFormOrder: 33,
        editFormOrder: 33,
        header: "Referred From",
        derivedValue: "referredFrom=referredFrom=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "referredFrom",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Form ", value: "Form 1", color: "primary" },
          { label: "Form ", value: "Form ", color: "primary" },
          { label: "Form ", value: "Form ", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "referredFrom",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "specialty",
        type: "text",
        placeholder: "Specialty",
        label: "Specialty",
        width: "130px",
        addFormOrder: 32,
        editFormOrder: 34,
        header: "Specialty",
        derivedValue: "specialty=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "specialty",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "specialty",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "creditType",
        type: "dropDown",
        placeholder: "Credit Type",
        label: "Credit Type",
        width: "130px",
        addFormOrder: 35,
        editFormOrder: 35,
        header: "Credit Type",
        derivedValue: "creditType=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "creditType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Credit Type 1", value: "Credit Type 1", color: "primary" },
          { label: "Credit Type 2", value: "Credit Type 2", color: "primary" },
          { label: "Credit Type 3", value: "Credit Type 3", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "creditType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "billingType",
        type: "dropDown",
        placeholder: "Billing Type",
        label: "Billing Type",
        width: "130px",
        addFormOrder: 36,
        editFormOrder: 36,
        header: "Billing Type",
        derivedValue: "billingType=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "billingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          {
            label: "Billing Type 1",
            value: "Billing Type 1",
            color: "primary",
          },
          {
            label: "Billing Type 2",
            value: "Billing Type 2",
            color: "primary",
          },
          {
            label: "Billing Type 3",
            value: "Billing Type 3",
            color: "primary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "billingType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "primaryProvider",
        type: "dropDown",
        placeholder: "Primary Provider",
        label: "Primary Provider",
        width: "130px",
        addFormOrder: 37,
        editFormOrder: 37,
        header: "Primary Provider",
        derivedValue: "primaryProvider=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "primaryProvider",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          {
            label: "Primary Provider 1",
            value: "Primary Provider 1",
            color: "primary",
          },
          {
            label: "Primary Provider 2",
            value: "Primary Provider  2",
            color: "primary",
          },
          {
            label: "Primary Provider  3",
            value: "Primary Provider 3",
            color: "primary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "primaryProvider",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "secondaryProvider",
        type: "dropDown",
        placeholder: "Secondary Provider",
        label: "Secondary Provider",
        width: "130px",
        addFormOrder: 38,
        editFormOrder: 38,
        header: "Secondary Provider",
        derivedValue: "secondaryProvider=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "secondaryProvider",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          {
            label: "Secondary Provider1",
            value: "Secondary Provider1",
            color: "primary",
          },
          {
            label: "Secondary Provider 2",
            value: "Secondary Provider 2",
            color: "primary",
          },
          {
            label: "Secondary Provider 3",
            value: "Secondary Provider 3",
            color: "primary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "secondaryProvider",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "feeSchedule",
        type: "text",
        placeholder: "Fee Schedule",
        label: "Fee Schedule",
        width: "130px",
        addFormOrder: 39,
        editFormOrder: 39,
        header: "Fee Schedule",
        derivedValue: "feeSchedule=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "feeSchedule",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "feeSchedule",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        header: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        options: [],
        textAlign: "Center",
        show: true,
        disabled: true,
        field: "role",
        mobile: true,
        globalSearchField: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };
  // submit1 = item => {
  //   let x = patientsFields
  //   let objpatients = {}

  //   objpatients["_id"] = item[0]["_id"]
  //   for (let x2 of x) {
  //     objpatients[x2] = item[0][x2]
  //   }
  //   let formFields = this.getFormFields()

  //   formFields = formFields.filter(y => x.includes(y.name))

  //   this.setState({
  //     openNewUserModal: true,
  //     item: objpatients,
  //     newFormFields: formFields
  //   });
  // }

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    // this.setState({
    //   isLoading: true
    // });
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.patients}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          // let sessionexpired = await localStorage.getItem('sessionexpired')
          // if (sessionexpired === "true") {
          //   await this.setState({ sessionExpiryModal: true })
          // }
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
          // this.setState({
          //   isLoading: false
          // });
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  /**
    * 
    * @param {Object} rowData 
    * @param {Object} item 
    * @returns 
    */
  const customActionButtons = (data) => {//Custom icons
    const { item, tableItem } = data;
    if (item?.insurancedetails?.length === 0 || !item?.insurancedetails?.some(ins => ins.active)) {
      return <span>
        <FontAwesomeIcon
          className='genderIconAlignment '
          color='white'
          icon='plus'  // Change this line to use the copy icon
          data-toggle="tool-tip"
          title="Create Insurance"
          style={{ color: '#3c6177', width: '16', marginLeft: 10, cursor: 'pointer' }}
          onClick={() => insuranceOnClick(item)} />
      </span>
    }
    return;
  }

  return (
    <span>
      {stateOptions &&
        < DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={true}
          PrintRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          // globalSearch={'Display Name/Email'}
          // type='Patients'
          // apiUrl={apiCalls.Patients}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={[
            {
              name: "Delete",
              options: [
                {
                  label: "Delete",
                  value: "Delete",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },

            // {
            //   'name': 'Block',
            //   "options": [
            //     { 'label': 'Block', 'value': 'Block', 'show': true, "multiple": false, }
            //   ]
            // },
            // {
            //   'name': 'ResetPassword',
            //   "options": [
            //     { 'label': 'ResetPassword', 'value': 'ResetPassword', 'show': true, "multiple": false, }
            //   ]
            // },
            // {
            //   'name': 'Submit',
            //   'action': this.submit,
            //   "options": [
            //     { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false },
            //   ]
            // },
            // {
            //   name: "newModel",
            //   action: this.submit1,
            //   options: [
            //     { label: "newModel", value: "newModel", show: true, multiple: false }
            //   ]
            // }
          ]}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.patients}
          globalSearch={"Name/Phone/DOB/Patient ID"}
          displayName="Patients"
          type="Patients"
          routeTo={apiCalls.patients}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.patients.toLowerCase()}
          apiUrl={apiCalls.patients}
          selectedId={params.id}
          customActionButtons={customActionButtons}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="patients"
          apiUrl={apiCalls.patients}
        />
      ) : null}
    </span>
  );
};

export default Patients;
