import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Button as ReactStrapButton } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import dateFormats from '../../../UI/FormatDate/formatDate';

import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';
import AccountsFormModal from './AccountsForm/AccountsFormModal';

function Accounts() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [type, setType] = useState();
  const getDataFromServer = Debounce(fetchData, 300);//To Avoid the over iterating
  useEffect(() => {
    const success = searchParams.get('success');
    const pId = searchParams.get('patientId');
    if (success) {
      // fetchMethodRequest('GET', apiCalls.patients + '/' + pId).then(res => {
      //   if (res?.details) {
      //     setTimeout(() => {
      //       localStorage.setItem('PatientData', JSON.stringify(res.details));
      //     }, 100);
          showToasterMessage('Payment success', 'success');
          navigate('/accounts');
      //   }
      // })
    }
    getDataFromServer(localStorage.PatientData && !success ? true : false);

  }, []);

  useEffect(() => {
    window.addEventListener('PatientBar', getDataFromServer);

    return () => {
      window.removeEventListener('PatientBar', getDataFromServer);
    };
  }, []);

  /**
   * 
   * @param {Function} func 
   * @param {Number} delay 
   * @returns 
   */

  function Debounce(func, delay) {//To Avoid the over iterating
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };


  function fetchData(displayToasterMsg) {
    const storedPatientData = localStorage.getItem('PatientData');
    const patientData = JSON.parse(storedPatientData);

    setIsLoading(true);
    let filterCriteria = {
      sortfield: "created",
      direction: "desc",
      criteria: []
    };

    if (patientData && patientData._id) {
      filterCriteria.criteria.push({
        key: "patientId",
        value: patientData._id,
        type: "eq"
      });
    } else {
      showToasterMessage('Please Select a Patient', 'warning');
      setTreatments([]);
      setAccounts([]);
      setIsLoading(false);
      return;
    }
    const encodedFilter = encodeURIComponent(JSON.stringify(filterCriteria));
    const accountsRequestUrl = `${apiCalls.accounts}?searchFrom=autoComplete&filter=${encodedFilter}&type=exportToCsv`;

    fetchMethodRequest('GET', accountsRequestUrl)
      .then(response => {
        let flattenedTreatments = [];
        let accounts = [];
        if (response && response.accounts && response.accounts.length > 0) {
          accounts = response.accounts;
          flattenedTreatments = response.accounts.reduce((acc, account) => {
            const treatments = account.patientTreatmentDetails.map(treatment => ({
              ...treatment,
              patientId: account.patientId, // Assuming there's a patientId field
              patientName: account.patientId.name,
              createdByName: account.createdByName,
              created: account.created,
              createdDate: moment(account.created).format('MM/DD/YYYY'),
              accountId: account._id
            }));
            return [...acc, ...treatments];
          }, []);
          displayToasterMsg ? showToasterMessage('Data fetched successfully', 'success') : null;

        } else {
          showToasterMessage('No data found', 'error');
        }
        setAccounts(accounts);
        setTreatments(flattenedTreatments);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        showToasterMessage('Failed to fetch data', 'error');
        setIsLoading(false);
      });
  }

  const descriptionStyle = (data, column) => {
    let colors = { paypal: "#22c55e", cash:"#22c55e", cheque: "#22c55e"}
      for(let key of Object.keys(colors)) {
        if(data[column.field] && data[column.field].toLowerCase().includes(key)){
          return <span style={{color: `${colors[key]}`, fontWeight:"bold"}}>{data[column.field]}</span>
        }
      }
      return data[column.field] && data[column.field] != "-" ? <span>{data[column.field]}</span> : hyphenStyle(data,column);
  }
  const hyphenStyle = (data, column) => {
    if(data[column.field] && data[column.field] != "-") return <div style={{textTransform:'capitalize'}}>{data[column.field]}</div>
    else return <span style={{fontSize:"1.2rem", fontWeight:"bolder", color:"#1e40af",textTransform:'capitalize'}}>{data[column.field]}</span>
  }
  const dollarStyle = (data, column) =>{
    if(data[column.field] && data[column.field] != "-") {
      return <div style={{textAlign:"center"}}>
        <FontAwesomeIcon
          className='genderIconAlignment'
          color='white'
          icon='dollar-sign'
          title="Delete"
          style={{ color: '#85BB65', width: '10'}}
          />
        <span className="ms-1">{data[column.field]}</span>
        </div>
    }
    else{
      return hyphenStyle(data,column);
    }
  }

  // Define columns in an array
  const columns = [
    { field: 'createdDate', header: 'Date', width: '100px', align: 'center' },
    // { field: 'patientName', header: 'Patient Name', width: 'auto', align: 'left', className: 'custom-header-left' },
    { field: 'code', header: 'Code', width: '100px', align: 'center', customStyle: hyphenStyle },
    { field: 'description', header: 'Description', width: 'auto', align: 'left', className: 'custom-header-left', customStyle: descriptionStyle },
    { field: 'teethNumber', header: 'TTH', width: '100px', align: 'center', customStyle: hyphenStyle },
    { field: 'amountCharges', header: 'Charges', width: 'auto', align: 'center', customStyle: dollarStyle },
    { field: 'amountCredits', header: 'Credits', width: 'auto', align: 'center', customStyle: dollarStyle },
    { field: 'paymentAmount', header: 'Payment', width: 'auto', align: 'center', customStyle: dollarStyle },
    { field: 'fee', header: 'Fee', width: 'auto', align: 'center', customStyle: dollarStyle },
  ];

  function renderDataTable() {
    return (
      <DataTable value={treatments} loading={isLoading} responsiveLayout="stack">
        {columns.map(col => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={col.customStyle && col.customStyle}
            style={{ width: col.width, textAlign: col.align }}
            className={col.className || ''}
          />
        ))}
      </DataTable>
    );
  }

  const openFormModal = (type) => {
    setType(type);
    setIsOpenFormModal(true);
  };

  const downloadStatements = async () => {
    const obj = treatments[0];
    const payload = {};

    // const filterKeys = ['patientId', 'accountId']; // Specify the keys you want to keep
    // const payload = Object.fromEntries(Object.entries(obj).filter(([key]) => filterKeys.includes(key)));

    payload.total = accounts[0]?.totalAmount;
    payload.patientId = obj.patientId;
    payload.accountId = obj.accountId;
    payload.statement = treatments;
    payload.date = moment().format(config.dateFormat);

    try {
      const response = await axios.post(`${config.apiUrl}${apiCalls.accountsStatement}`, payload, {
        responseType: 'blob',
      });

      // Create a blob URL
      const pdfBlobUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

      // Create and click a temporary link to download the file
      const link = document.createElement('a');
      link.href = pdfBlobUrl;
      link.download = `${payload?.patientId?.name ? payload?.patientId?.name+'_' : ''}statement.pdf`; // Specify the filename
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Revoke the blob URL
      URL.revokeObjectURL(pdfBlobUrl);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  }

  const closeFormModal = () => {
    setType();
    setIsOpenFormModal(false);
    getDataFromServer();
  };

  const getUrlData = (type) => {
    const data = {};
    data.method = type == 'payment' ? "POST" : "PUT";
    data.parentField = type == 'payment' ? "paymentInfo" : "adjustment";
    data.url = type == 'payment' ? apiCalls.makePayments : `${apiCalls.accountsAdjustment}/${treatments[0]?.accountId}`;
    data.addData = { accountId: treatments[0]?.accountId };
    return data;
  }

  return (
    <div className='bg-white p-2 round'>
      <div className="flex justify-content-between align-items-center button-group">
        <div>
          <ReactStrapButton color="primary" onClick={() => openFormModal('payment')} disabled={treatments?.length == 0}>Payment</ReactStrapButton>
          <ReactStrapButton color="primary" onClick={() => openFormModal('adjustment')} disabled={treatments?.length == 0}>Adjustment</ReactStrapButton>
          <ReactStrapButton color="primary" disabled={treatments?.length == 0}>New Claim</ReactStrapButton>
          <ReactStrapButton color="primary" onClick={downloadStatements} disabled={treatments?.length == 0}>Statement</ReactStrapButton>
        </div>
        {
          accounts && accounts[0]?.totalAmount && 
          <div>
            <p style={{ fontSize: '1.2rem', fontWeight: "bold", marginRight: "3rem" }}>Total Amount: <span style={{ color: "#075985", fontSize: "1.5rem", marginLeft: "5px" }}>$&nbsp;{accounts[0]?.totalAmount}</span> </p>
          </div>
        }
      </div>
      {renderDataTable()}

      {isOpenFormModal && <AccountsFormModal
        isOpenFormModal={isOpenFormModal}
        type={type}
        closeFormModal={closeFormModal}
        getUrlData={getUrlData}
        accounts={accounts}
      />
      }
    </div>
  );

}

export default Accounts;
