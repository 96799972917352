import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Button as ReactStrapButton } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import classnames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import DeleteRowDialog from './DeleteRowDialog';
const PatientNotesDialog = ({ isOpen, onClose, patientId, patientName, patientObj, isAdmin, patients }) => {
    const [activeTab, setActiveTab] = useState('1');
    const [patientNotes, setPatientNotes] = useState('');
    const [notesHistory, setNotesHistory] = useState([]);
    const [currentNoteIndex, setCurrentNoteIndex] = useState(-1);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [rowToDelete, setRowToDelete] = useState(null);

    useEffect(() => {
        if (isOpen) {
            fetchPatientNotes();
        }
    }, [isOpen]);

    const fetchPatientNotes = () => {
        fetchMethodRequest('GET', apiCalls.patients)
            .then((response) => {
                if (response && response.patients) {
                    const patient = response.patients.find(p => p._id === patientId);
                    if (patient && patient.patientNotes) {
                        const updatedNotes = patient.patientNotes.map(note => {
                            const tempDiv = document.createElement("div");
                            tempDiv.innerHTML = note.patientNote;  // Insert HTML content into the div
                            return {
                                notes: tempDiv.textContent || tempDiv.innerText || "No content", // Extract text content
                                createdDate: new Date(note.created).toISOString(),  // Ensure ISO format
                                updatedDate: note.updated ? new Date(note.updated).toISOString() : null,
                                author: note.createdByName || " ",
                                _id: note._id,
                                createdTime: note.created?.split('T')[1].split('.')[0],
                                updatedTime:note.updated?.split('T')[1].split('.')[0],
                                updatedAuthor:note.updatedByName || " "
                            };
                        });
                        setNotesHistory(updatedNotes);
                    }
                }
            }).catch(error => {
                console.error('Failed to fetch patient notes:', error);
            });
    };


    const savePatientNotes = () => {
        fetchMethodRequest('POST', apiCalls.patientNotes, { patientNote: patientNotes, patientID: patientObj, patientIDSearch: patientObj.patientId })
            .then((response) => {
                if (response.respMessage) {
                    // Assuming the response contains the saved note with its timestamp
                    const newNote = {
                        notes: patientNotes,
                        date: response.savedNoteDate || new Date().toLocaleDateString(), // Use the date from the response if available
                    };
                    setNotesHistory([...notesHistory, newNote]);
                }
                setIsEditing(false);
                setCurrentNoteIndex(-1);
                toggleTab('2');
                fetchPatientNotes();
                setPatientNotes('');
            });
    };
    

    const updatePatientNotes = () => {
        if (currentNoteIndex >= 0 && isEditing) {
            const noteToUpdate = notesHistory[currentNoteIndex];
            const updatedNote = {
                patientNote: patientNotes,
                date: new Date().toISOString(), // Better to use ISO string for consistency
                patientID: patientObj,
                patientIDSearch: patientObj.patientId,
                _id: noteToUpdate._id
            };

            // Include the _id in the API endpoint
            const updateUrl = `${apiCalls.patientNotes}/${noteToUpdate._id}`;

            fetchMethodRequest('PUT', updateUrl, updatedNote)
                .then(response => {
                    if (response.respCode) {
                        const updatedNotes = [...notesHistory];
                        updatedNotes[currentNoteIndex] = {
                            ...noteToUpdate,
                            ...updatedNote,
                            notes: patientNotes,
                            date: new Date().toLocaleDateString()
                        };
                        showToasterMessage('Patient Note updated successfully', 'success');
                        setNotesHistory(updatedNotes);
                        setPatientNotes('');
                        setIsEditing(false);
                        setCurrentNoteIndex(-1);
                        toggleTab('2');
                        fetchPatientNotes();
                    }
                })
                .catch(error => {
                    console.error('Failed to update patient notes:', error);
                });
        }
    };

    const openDeleteModal = (id) => {
        setRowToDelete(id);
        setIsDeleteModalOpen(true);
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleEdit = (note, index) => {
        setActiveTab('1');
        setPatientNotes(note.notes);
        setCurrentNoteIndex(index);
        setIsEditing(true);
    };

    const handleClose = () => {
        setPatientNotes('');
        setNotesHistory('');
        onClose();
    };
    const deleteSelectedRow = () => {
        if (rowToDelete) {
            handleDelete(rowToDelete)
        }
    }
    const handleDelete = (index) => {
        const deleteUrl = `${apiCalls.patientNotes}/${index}`;

        fetchMethodRequest('DELETE', deleteUrl)
            .then(response => {
                if (response.respCode) {
                    const updatedNotes = notesHistory.filter((_, i) => i !== index);
                    setNotesHistory(updatedNotes);
                    showToasterMessage('Patient Note deleted successfully', 'success');
                    setIsDeleteModalOpen(false);
                    toggleTab('2');
                    fetchPatientNotes();
                }
            })
            .catch(error => {
                console.error('Failed to delete patient notes:', error);
            });
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
        return formattedDate;
    }

    const iconStyle = {
        cursor: 'pointer',
        marginLeft: '10px'
    };

    const badgeStyle = {
        display: 'inline-block',
        fontSize: '0.75em',
        color: '#fff',
        backgroundColor: '#007bff',
        borderRadius: '10px',
        padding: '2px 8px',
        marginLeft: '10px'
    };
    const dialogClass = classnames('patient-notes-dialog', {
        'dialog-z-index-unset': isDeleteModalOpen
    });

    function formatDateTime(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return `${formattedDate} ${formattedTime}`;
    }
    const tabHeading = isEditing ? "Edit" : "Add"; 
    return (
        <div className="patient_notes">
            <div className="custum_index">
            <Dialog header={`Patient Notes - ${patientName}`} visible={isOpen} onHide={onClose} className={dialogClass}>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggleTab('1'); }}
                        >
                            {tabHeading}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggleTab('2'); }}
                        >
                            Notes History
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="ckeditor-notes-container">
                            <CKEditor
                                editor={ClassicEditor}
                                data={patientNotes}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setPatientNotes(data);
                                }}
                            />
                        </div>
                        <div className="dialog-buttons mt-3 d-flex justify-content-end">
                            <ReactStrapButton color="primary" onClick={handleClose} className="me-2">Cancel</ReactStrapButton>
                            <ReactStrapButton color="primary" onClick={isEditing ? updatePatientNotes : savePatientNotes}>
                                {isEditing ? 'Update' : 'Submit'}
                            </ReactStrapButton>
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                    <div className="notes-history">
                                {notesHistory.length > 0 ? (
                                    notesHistory.map((note, index) => (
                                        <div key={index} className="patient-card mb-3">
                                            <div className="card-body d-flex justify-content-between">
                                                <div>
                                                    <h6 className="card-title">{note.author}</h6>
                                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: note.notes }}></p>
                                                </div>
                                                <div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                                        <FontAwesomeIcon icon={faEdit} style={{color:'#0000FF',marginLeft: '10px',cursor: 'pointer'}} onClick={() => handleEdit(note, index)} data-toggle="tool-tip" title="Edit" />
                                                        <FontAwesomeIcon icon={faTrashAlt} style={{color:'#FF0000',marginLeft: '10px',cursor: 'pointer'}} onClick={() => openDeleteModal(note._id)} data-toggle="tool-tip" title="Delete" />
                                                    </div>
                                                    <div className="date-time">
                                                        {note.updatedDate && note.updatedDate !== note.createdDate ? (
                                                            <>
                                                                <p className="card-text"><small className="text-muted">Updated: {formatDateTime(note.updatedDate)}</small></p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {note.createdDate && (
                                                                    <>
                                                                        <p className="card-text"><small className="text-muted">Created: {formatDateTime(note.createdDate)}</small></p>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-notes">
                                        <p>No patient notes available.</p>
                                    </div>
                                )}
                            </div>
                    </TabPane>
                </TabContent>
            </Dialog>
            </div>
            <div className='patient-note-delete-row-modal'>
                <DeleteRowDialog
                    openDeleteModal={isDeleteModalOpen}
                    closeDeleteModal={() => { setIsDeleteModalOpen(false) }}
                    deleteSelectedRow={deleteSelectedRow}
                />
            </div>
        </div>
    );
};

export default PatientNotesDialog;
