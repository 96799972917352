import React, { useState, useEffect ,useImperativeHandle,useRef,forwardRef} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';
import { Calendar } from 'react-big-calendar';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import ColorPickerWithTooltip from '../../Form/Fields/ColorPickerWithTooltip';
import UpdateSlotModal from './UpdateSlotModal';
import { Dialog } from 'primereact/dialog';
import UpdateAppointmentModal from './UpdateAppointment';
import CustomTimePicker from '../../Form/Fields/CustomTimePicker';
import moment from 'moment';
import AppointmentForm from './AppointmentForm';
import { AutoComplete } from "primereact/autocomplete";
import config from '../../../config/config';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const TabbedInterface = forwardRef(({
  eventDate,
  startTime,
  endTime,
  control,
  onSubmit,
  setSelectedEvent,
  slots,
  setSelectedEventId,
  onHandleSlotModal,
  currentAction,
  slotModelTrue,
  handleStatusChange,
  handleStartTimeChange,
  getValues
}, ref )=> {

  // const { control, handleSubmit } = useForm();
  const [proceduresData, setProceduresData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientID, setSelectedPatientID] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null); 


  const {
    handleSubmit,
    register,
    reset,
    setValue,
    // getValues,
    formState: { errors },
    // control,
  } = useForm(
      // {resolver: yupResolver(schema),}
    );
    const formatTimeFromMoment = (value) => {
      const format = "HH:mm";
      const regex = /^\d{2}:\d{2}$/;
      const formattedTime = regex.test(value) ? value:moment(value).format(format);   
      return formattedTime;
    };
  useEffect(() => {
    if (slotModelTrue === 0) {
      setActiveIndex(0);
    }else if (slotModelTrue === 1){
      setActiveIndex(1);
    }
  }, [slotModelTrue]);
  useEffect(() => {
    const patientDetails = localStorage.getItem('PatientData');
  
    if (patientDetails) {
      const parsedDetails = JSON.parse(patientDetails);
      setSelectedPatient(parsedDetails);
    } else {
      setSelectedPatientID(null);
      setSelectedPatient(null);
    }
  }, [localStorage.getItem('PatientData')]);

  useEffect(() => {
    const elements = document.getElementsByClassName('sketch-picker color-picker');

    if (elements.length > 0) {
      const element = elements[0];

      if (element.childNodes.length > 0) {
        element.childNodes[0].style.display = 'none';
        element.childNodes[1].style.display = 'none';
        element.childNodes[2].style.display = 'none';
      }
    }
  });


  useImperativeHandle(ref, () => ({
    resetSelectedColor: () => {
      setSelectedColor(null);
    },
  }));
  const onTabChange = (e) => {

      setActiveIndex(e.index);
     
      onHandleSlotModal(e.index);
    
  };

  const renderErrorDialog = () => (
    <Dialog
      visible={showErrorDialog}
      onHide={() => setShowErrorDialog(false)}
      header="Error"
      modal
      closeOnEscape
      draggable={false}>
      <p>Please select a patient ID before proceeding.</p>
    </Dialog>
  );


  const handleFormSubmit = data => {
    console.log(data);
  };

  const onPatientSelect = (selectedOption) => {

  if (!selectedOption || !selectedOption.patientId) {
  setSelectedPatient(null);
    return;
  }
    const patientId = selectedOption.patientId;
    const patientDetailsUrl = `patients`;

    fetchMethodRequest('GET', apiCalls.patients)
  .then(response => {
  const patientDetails = response.patients.find(patient => patient.patientId === patientId);
  if (patientDetails) {
  setSelectedPatient(patientDetails);
  } else {
  console.error('Patient not found');
  setSelectedPatient(null);
  }
      })
  .catch(error => {
  console.error('Error fetching patient details:', error);
  setSelectedPatient(null);
  });
  };
  let getAutoComplete = (i, item) => {
    const itemTemplate = (option) => {
      const displayText = item.showField
        ? `${option[item.searchField]} (Name: ${option[item.showField]})`
        : option[item.searchField];

      return (
        <div className="autocomplete-item">
          <div>{displayText}</div>
        </div>
      );
    };

    const handlePatientSelect = (value, name, props) => {
      if(name === "reasons"){ 
        props.field.onChange(value);                 
        const colorNameLowercase = value.color ? value.color.toLowerCase() : null;              
        const hexCode = nameToHex[colorNameLowercase] || '#265985';                      
        setSelectedColor(hexCode);
      }
      
      setSelectedPatientID(value);

      onPatientSelect(value);

    };
  
    return (
      <div>
        <Controller
          name={item.name}
          rules={{ required: `${item.label} is required` }}
          control={control}
          render={({ field, fieldState }) => (
            <>
              <DoAutoCompleteField
                markReq={item.required}
                input={field}
                id={field.id}
                name={field.name}
                field={field}
                filterField={item.filterField}
                filterValue={item.filterValue}
                filterType={item.isNotEq}
                multiple={item.isMultiple}
                fieldState={fieldState}
                errors={errors}
                screen={'Appointments'}
                searchApi={item.searchApi}
                itemTemplate={itemTemplate}
                searchField={item.searchField}
                onSelectionChange={onPatientSelect}
                // allow={props.allowDuplicates}
                filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                placeholder={item.placeholder}
                label={item.label}
                item={item}
                onPatientSelect={handlePatientSelect}
                formType={"add"}
                disabled={item.disabled}
                populateValue={item.searchField}
                populateField={item.showField}
              />
              {fieldState.error && (
                <small style={{ color: 'red' }}>{fieldState.error.message}</small>
              )}
            </>
          )}
        />
      </div>
    )
  }
  const fetchProcedures = () => {



    fetchMethodRequest('GET', apiCalls.procedures)
      .then(async (response) => {
        if (response && response.procedures) {
          let proceduresData = response.procedures;
          setProceduresData(proceduresData);

        }
      }).catch(error => {
        console.error('Error fetching procedures:', error);
      });
  };


  useEffect(() => {
    fetchProcedures();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // getMonth() returns a 0-indexed month, so adding 1
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };



  const patientDetailsToDataTable = (patient) => {
    if (!patient) return [];

    return [
      { label: 'Name', value: `${patient.name}` },
      { label: 'DOB', value:  moment(patient.dateOfBirth).format('MM-DD-YYYY') },
      { label: 'Phone', value: patient.phoneNumber },
      { label: 'Email', value: patient.email },
      { label: 'Address', value: patient.fullAddress },
    ];
  };

  const patientDetailsData = selectedPatient ? patientDetailsToDataTable(selectedPatient) : [];

  let minDate=new Date();
  const PatientDetails = ({ patient }) => {
    if (!patient) return null;
  
    const detailStyle = { display: 'flex', flexDirection: 'row', marginBottom: '0.3em' };
    const valueStyle = { fontWeight: 'bold',marginLeft: '4px', textTransform:'Capitalize' };
    const firstName = patient.firstName ? patient.firstName : " ";
    const lastName = patient.lastName ? patient.lastName : null;
    const DOB = patient.dateOfBirth ? moment(patient.dateOfBirth).format(config.dateDBFormat) : " ";
    const fullAddress2 = patient.fullAddress2 ? patient.fullAddress2 : " ";
    const fullAddress = patient.fullAddress ? patient.fullAddress : " ";
    const phoneNumber = patient.phoneNumber ? patient.phoneNumber : " ";
    return (
      <div className="patient-details" style={{paddingTop:'20px'}}>
        <div style={detailStyle}>
          <span >Patient Name:</span>
          {firstName && lastName && <span style={valueStyle}>{`${firstName} ${lastName}`}</span>}
        </div>
        <div style={detailStyle}>
          <span>DOB:</span>
          {DOB && <span style={valueStyle}>{DOB}</span>}
        </div>
        <div style={detailStyle}>
          <span>Address:</span>
          <div style={{display:'flex',flexDirection:'column'}}>
          {fullAddress2 && <div style={valueStyle}>{`${fullAddress2}`}</div>}
          {fullAddress && <div style={valueStyle}>{`${fullAddress}`}</div>}
          {phoneNumber && <div style={valueStyle}>{phoneNumber}</div>}
          </div>
        </div>
      </div>
    );
  }; 
  const search = (event) => {
    let filterCriteria = { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' }
    if (event.query) {
      filterCriteria.criteria.push({ key: 'reasons', value: event.query, type: 'regexOr' })
    }
    fetchMethodRequest('GET', `reasons?filter=${JSON.stringify(filterCriteria)}`).then((res) => {
      setItems(res.reasons)
    })
  }

  const updateStartTime = (newStartTime) => {
    handleStartTimeChange(newStartTime);
  };

  const colorNames = {
    '#D32F2F': 'Red',
    '#C2185B': 'Pink',
    '#7B1FA2': 'Purple',
    '#512DA8': 'Dark Purple',
    '#303F9F': 'Indigo',
    '#1976D2': 'Blue',
    '#0288D1': 'Light Blue',
    '#0097A7': 'Cyan',
    '#00796B': 'Teal',
    '#388E3C': 'Green',
    '#689F38': 'Lime',
    '#AFB42B': 'Yellow',
    '#F57C00': 'Orange',
    '#E64A19': 'Deep Orange',
    '#5D4037': 'Brown',
    '#795548': 'Dark Brown',
    '#004D40': 'Deep Teal',
    '#1B5E20': 'Dark Green',
    '#BF360C': 'Deep Red',
    '#3E2723': 'Dark Sienna',
    '#212121': 'Charcoal',
    '#000080': 'Navy Blue',
    '#8B0000': 'Dark Red',
    '#006400': 'Dark Green'
  };
   
  const nameToHex = Object.entries(colorNames).reduce((acc, [hex, name]) => {
    acc[name.toLowerCase()] = hex;
    return acc;
  }, {});


  /**
   * 
   * @param {String} time 
   * @returns Number
   */
  const convertIntoMin = (time) => {
    console.log('timee1' , time);
    const regex = /^\d{2}:\d{2}$/;
    time = formatTimeFromMoment(time);
    if(time && regex.test(time)){
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    }
    return 0;
  }

  /**
   * 
   * @param {String} startTime 
   * @param {String} endTime 
   * @returns 
   */
  const validateTimeRange = (startTime, endTime) =>{
    console.log('getValues',getValues());
    console.log('------------',startTime , endTime);
    startTime = convertIntoMin(startTime);
    endTime = convertIntoMin(endTime);
    console.log('------------',startTime , endTime);
    if(!startTime || !endTime || startTime < endTime){
      return true;
    }
    return false;
  }

    return (
    <div>
      {renderErrorDialog()}
      <TabView onTabChange={(e) => onTabChange(e)} activeIndex={activeIndex}>

        {currentAction === 'createAppointment' && (
        <TabPanel header="Appointments">
          {/* <form onSubmit={handleSubmit(handleFormSubmit)} className="calendar-form"> */}
                <div>
                  {getAutoComplete(1, { name: 'patientId', type: 'text', label: 'Patient Id', searchApi: "patients", searchField: "patientId", showField: "firstName", disabled: "true" })}
                </div>
                <div>
                {selectedPatient ? (
                  <PatientDetails patient={selectedPatient} />
                ) : localStorage.getItem('PatientData') ? (
                  <PatientDetails patient={JSON.parse(localStorage.getItem('PatientData'))} />
                ) : null}
                </div>
          <div className="calendar-form-group calendar-form-group-date">
            <label htmlFor="eventDate">Date</label>
            <Controller
              name="eventDate"
              control={control}
              rules={{ required: 'Event Date is required' }}
              render={({ field,fieldState }) => (
              <>
                <PrimeCalendar id="eventDate" {...field} dateFormat="yy-mm-dd" minDate={minDate} style={{width:'100%'}} showIcon />
                {fieldState.error && <small style={{ color: 'red' }}>{fieldState.error.message}</small>}
              </>
              )}
            />
          </div>

          <div className="calendar-form-group-row">
            <div className="calendar-form-group calendar-form-group-time">
              <label htmlFor="startTime">From Time</label>
              <Controller
                name="startTime"
                control={control}
                rules={{ required: 'From Time is required' }}
                render={({ field,fieldState }) => (
                  <>
                  <CustomTimePicker id="startTime" {...field} timeOnly hourFormat="12" showIcon 
                   onChange={(startTimeValue) => {
                    field.onChange(startTimeValue);
                    updateStartTime(startTimeValue);
                  }}
                  />
                  {fieldState.error && <small style={{ color: 'red' }}>{fieldState.error.message}</small>}
                  </>
                )}
              />
            </div>

            <div className="calendar-form-group calendar-form-group-time">
              <label htmlFor="endTime">To Time</label>
              <Controller
                name="endTime"
                control={control}
                rules={{ 
                  required: 'To Time is required',
                  validate: value => validateTimeRange(getValues && getValues('startTime'),value) || 'To time must be greater than the from time.',
               }}
                render={({ field ,fieldState}) => (
                <>
                  <CustomTimePicker id="endTime" {...field} timeOnly hourFormat="12" showIcon />
                  {fieldState.error && <small style={{ color: 'red' }}>{fieldState.error.message}</small>}
                </>
                )}
              />
            </div>
          </div>
          <div>
            {getAutoComplete(1, { name: 'provider', type: 'text', label: 'Provider', searchApi: "users", searchField: "name" })}
          </div>
            <div className="calendar-form-group calendar-form-group-date">
              <label> Reason for your Scheduled appointment?</label>
              {getAutoComplete(1, { name: 'reasons', type: 'text', label: 'Reasons', searchApi: "reasons", searchField: "reasons" })}

              {/* <div style={{width:'100%'}}>
                <Controller
                  name="reasons"
                  control={control}
                  rules={{ required: 'Operative is required' }}
                  render={({ field, fieldState }) => (
                    <AutoComplete
                      value={field.value}
                      suggestions={items}
                      field="reasons"
                      completeMethod={search}
                      onChange={(e) => {
                        field.onChange(e.value);                     
                        const colorNameLowercase = e.value.color ? e.value.color.toLowerCase() : null;              
                        const hexCode = nameToHex[colorNameLowercase] || '#265985';                      
                        setSelectedColor(hexCode);
                      }}                     
                      dropdown
                      placeholder='Select a reason'
                      style={{width:'100%'}}
                    />
                  )}
                />
                {errors.reasons && <p style={{ color: 'red' }}>{errors.reasons.message}</p>}
              </div> */}
            </div>
            {selectedColor && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
              <label>Appointment Color: </label>
              <div style={{
                width: '20px',
                height: '20px',
                backgroundColor: selectedColor,
                marginLeft: '10px',
                borderRadius: '5px'
              }}>   
              </div>
            </div>
            )}
          {/* </form> */}
        </TabPanel>
        )}
        {currentAction === 'updateAppointment' && (
        <TabPanel header="Update Appointment">
          <UpdateAppointmentModal
            eventDate={eventDate}
            startTime={startTime}
            endTime={endTime}
            control={control}
            handleStatusChange={handleStatusChange}
            getValues={getValues}
            validateTimeRange={validateTimeRange}
          />
        </TabPanel>
        )}
        <TabPanel header="Procedures">
          <DataTable value={proceduresData} responsiveLayout="scroll">
            <Column field="code" header="Code" sortable></Column>
            <Column field="description" header="Description" sortable filter filterPlaceholder="Search by description"></Column>
            <Column field="fee" header="Fee" sortable></Column>
          </DataTable>
        </TabPanel>
        
        <TabPanel header="Forms">
          <AppointmentForm />
        </TabPanel>
     

      </TabView>

    </div>

  );
});

export default TabbedInterface;


