import React, { useState, useEffect, useRef } from 'react';
import {
    Modal, ModalHeader, ModalBody,
    Card, CardBody, ButtonToolbar, ButtonGroup, Row, Col, Badge
} from 'reactstrap';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import DoSelectField from '../../../Form/Fields/DoSelectField';
import { Dialog } from 'primereact/dialog';

import * as yup from "yup";


function InsuranceSubscriberModal(props) {

    const { formType, } = props;
    const [patientsDetails, setPatientsDetails] = useState();
    const [familyOptions, setFamilyOptions] = useState();



    let [colored, setColored] = useState(false);
    let [header, setHeader] = useState(true);
    let modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });
    const {
        handleSubmit,
        register,
        reset,
        setValue,
        formState: { errors },
        control,
    } = useForm(
        {
            resolver: yupResolver(yup.object().shape({
                // employer: yup.string().required('Employeer is required'),

            })),
        }
    );

    useEffect(() => {
        const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
        if (modal) {
            modal.style.zIndex = '400';
        }
        getPatientDetails();

    }, []);

    const getPatientDetails = () => {

        let patientData = JSON.parse(localStorage.getItem('PatientData'));
        console.log('patientsData', patientData.familyMember);
        let options = []
        if (patientData && patientData.familyMember) {
            options = patientData.familyMember.map(patient => ({
                value: `${patient.firstName} ${patient.lastName}`,
                label: `${patient.firstName} ${patient.lastName}`
            }));
            setPatientsDetails(patientData.familyMember);
            setFamilyOptions(options);
            console.log('options', options);

        }

    }
    const familyRelations = () => {
        return [
            {
                name: "familyMembers",
                type: "dropDown",
                placeholder: "Family Insurance Details",
                label: "Family Insurance Details",
                width: "130px",
                header: "Family Members",
                derivedValue: "familyMembers=undefined",
                capitalizeTableText: false,
                id: "familyMembers",
                required: true,
                displayOptionsInActions: false,
                globalSearchField: "true",
                controllerId: null,
                textAlign: "Center",
                show: true,
                options: familyOptions,
                filter: true,
                field: "familyMembers",
                showOrHideFields: [],
                mobile: true,
                displayInSettings: true,
            }
        ]
    }


    let getModalHeader = () => {
        let { t } = props;
        return (
            <ModalHeader className="modal__header">
                {/* <button className="lnr lnr-cross modal__close-btn" type="button" onClick={props.closeConfirmationModal} /> */}
                <p className="bold-text  modal__title" style={{ fontWeight: "bold" }}>
                    Select Subscriber
                </p>
            </ModalHeader>
        )
    }

    const handleDropDown = (e) =>{
        if(e){ 
            props.confirm()
        }
    }

    let getDropdown = (i, item) => {
        return (
            <div className='col-md-6  mt-1 ml-2'>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoSelectField
                            input={field}
                            id={field.id}
                            item={item}
                            name={field.name}
                            field={field}
                            label={item.label}
                            fieldState={fieldState}
                            errors={errors}
                            options={item.options && item.options}
                            optionLabel={item.optionLabel ? item.optionLabel : 'label'}
                            placeholder={item.placeholder}
                        onChange={e => handleDropDown(e, item)}
                        />)}
                />
            </div>
        )
    }

    const getViewBody = () => {
        return (<>
            <div className='mt-2'>
                <h4> If subscriber has not been entered, cancel and add them before continuing </h4>
            </div>
            <div className='mt-4 mb-4 d-flex justify-content-center'>
                
                {
                    //Displaying family details in dropdown
                    familyRelations && familyRelations().map((item, index) => getDropdown(index, item))

                    //  Displaying family details in UL 
                    // <ul>
                    //     {patientsDetails && patientsDetails.map((item, index) => (
                    //         <li key={index} className='mt-2' style={{ color: '#3C6177', fontWeight: 'bold', textTransform: 'capitalize' }}
                    //             onClick={props.confirm}>
                    //             {`${item.firstName}${item.lastName}`}
                    //         </li>
                    //     ))}
                    // </ul>                   
                }
            </div>

        </>)
    }

    return (

        <div>

            <Dialog
                visible={props.openConfirmationModal}
                onHide={props.closeConfirmationModal}
                draggable={false}
                style={{ width: '40vw' }}
                header={getModalHeader()}
                closeOnEscape={false}
            >
                {
                    <ModalBody className="modal__body mb-0 pt-4">
                        <Card className='pb-0 cardForListMargin'>
                            <CardBody className='tableCardBody'>
                                {getViewBody()}
                            </CardBody>
                        </Card>
                    </ModalBody>
                }
            </Dialog>

        </div>
    );
}

export default InsuranceSubscriberModal
