import React from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import {  Modal,ModalBody } from 'reactstrap';

function WeekendConfirmModal(props) {
  return (
    <div>
      <Dialog
          header={props.header}
          visible={props.isOpenWeekendModal}
          onHide={() => props.closeWeekendModal()}
          footer={
            <>
            
              <Button label="Yes" onClick={() => {props.confirm()}} />
              <Button label="No" onClick={() => {props.closeWeekendModal()}} />
            </>
          }>
          <p>{props.label}</p>
        </Dialog>
    </div>
  )
}

export default WeekendConfirmModal






