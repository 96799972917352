import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useState, useEffect } from 'react'
import fetchMethodRequest from '../../../config/service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import apiCalls from '../../../config/apiCalls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage"
import { faTrash, faEdit, faInfoCircle, faUser, } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Button } from 'reactstrap';
import config from '../../../config/config';

function RefferealModal(props) {

  const [refferals, setRefferals] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const patientData =props.getValues('patientId');
  const [patientDetails, setPatientDetails] = useState({});
  const [rowData, setRowData] = useState({});

  const tableFields = [
    { field: "SNo", header: "SNo" },
    { field: 'firstName', header: 'First Name', style: "" },
    { field: 'lastName', header: 'Last Name', style: "" },
    { field: 'middleName', header: 'Middle Name', style: "" },
    { field: 'title', header: 'Tittle', style: "" },
    { field: 'address', header: 'Address', style: "" },
    { field: 'address2', header: 'Address2', style: "" },
    { field: 'city', header: 'City', style: "" },
    { field: 'state', header: 'State', style: "" },
    { field: 'zipCode', header: 'Zip Code', style: "" },
    { field: 'phone', header: 'Phone', style: "" },
    { field: 'otherPhone', header: 'Other Phone', style: "" },
    { field: 'email', header: 'Email', style: "" },
    { field: 'city', header: 'City', style: "" },
    { field: 'action', header: "Action", body: 'Action', style: "" }
  ]


  useEffect(() => {
    getReffferal();
  }, []);

  useEffect(() => {
    if(localStorage?.PatientData){
      let patientDetails = JSON.parse(localStorage.PatientData );
      setPatientDetails(patientDetails)
    }
  }, [localStorage.PatientData]);

  const getReffferal = () => {
    let apiUrl = apiCalls.referrals
    let method = "GET"
    return fetchMethodRequest(method, apiUrl)
      .then(async (response) => {
        if (response && response.referrals.length > 0) {

          let referralsWithSNo = response.referrals.map((referral, index) => ({
            ...referral,
            SNo: index + 1
          }));

          setRefferals(referralsWithSNo);
        }
      })

  }

  const getRefferalSlipBody = (rowData) => {

    let referrals = rowData;
    let patient = (patientDetails && Object.keys(patientDetails).length > 0) ? patientDetails : patientData;
   
    return {
      referral: referrals,
      patient: patient
    };

  }

  const fetchPdf = async (rowData) => {
    let payLoad = getRefferalSlipBody(rowData)
    try {
      const response = await axios.post(`${config.apiUrl}referrals/generateReferralPdf`, payLoad, {
        responseType: 'blob',
      });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfBlobUrl);
      setIsOpen(true);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const columnBody = (rowData, col) => {

    if (col.field === "action") {
      return (
        <div className='d-flex justify-content-center'>
          <FontAwesomeIcon onClick={() => {setRowData(rowData) ; fetchPdf(rowData)}} icon={'upload'} style={{ alignItems: "center" }} />
        </div>
      )
    }
    return (
      <div>{rowData[col.field]}</div>
    )

  }

  const getRefferalTable = () => {
    return (<>
      {<div className='form mt-4 mb-2' >
        {true &&
          <DataTable value={refferals} style={{ width: "100%", marginLeft: "" }}>
            {tableFields.map((col) => {
              return (
                <Column field={col.field} header={col.header} body={(rowData) => columnBody(rowData, col)} style={col.style} />
              )
            }
            )}

          </DataTable>}
      </div>}
    </>)
  }

  const getRefferalDilog = () => {

    return (<>
    
      <Dialog
        header="Referral"
        visible={isOpen}
        headerClassName="modal-header"
        draggable={false}
        style={{ width: '60vw', height: '90vh' }}
        onHide={()=> setIsOpen(false)}
        modal
      >
        <div className={`row`} style={{height:"100%"}}>
          <IFrameContainer pdfUrl={pdfUrl}
           patientDetails={patientDetails} 
           patientData={patientData}
           getRefferalSlipBody={getRefferalSlipBody}
           rowData={rowData}
           setIsOpen={setIsOpen}
           
           
          />
        </div>
      </Dialog>
    </>)

  }


  return (
    <>
      <Dialog
        header="Referral"
        visible={props.isOpenReffereal}
        headerClassName="modal-header"
        draggable={false}
        style={{ width: '95vw', height: '100vh' }}
        onHide={props.onHide}
        modal
      >
        <div className={`row`}>
          {getRefferalTable()}

        </div>


      </Dialog>

      {isOpen ? getRefferalDilog() : null}
    </>
  )
}

const IFrameContainer = ({pdfUrl , getRefferalSlipBody ,  rowData , setIsOpen}) => {
  let apiUrl="referrals/sendEmailToPatient";
  let method = 'POST'

  let payLoad = getRefferalSlipBody(rowData)
  
  const getSendEmail = () =>{

    return fetchMethodRequest(method, apiUrl, payLoad)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, 'success');
            setIsOpen(false);
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
          setIsLoading(false);
        }).catch((err) => {
          return err;
        });
  }

	return (
		<>
		<iframe
			src={pdfUrl}
          style={{ width: '100%', height: '90%', border: 'none' }}
          title="PDF Viewer"
		/>
		<div className="container">
    <Button color="primary" onClick={getSendEmail}   className='deleteModalBtn marginRight'>Email</Button>
		</div>
		</>
	)
}

export default RefferealModal