/* eslint indent: "off" */
import React from 'react';
import { Route, Routes, createBrowserRouter, Outlet } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Register from '../Register/index';

// Login
import Landing from '../Landing/LogIn';
// import LoginCheck from '../Landing/loginCheck';

// Dashboard
import Home from '../Dashboard/index';

// Profile
import ChangePassword from '../Account/ChangePassword/index';
import ChangeRecoverPassword from '../Account/ChangeRecoverPassword/index';
import ForgotPassword from '../Account/ForgotPassword/index';
import LoginChangePassword from '../Account/LoginChangePassword/index';
import Profile from '../Account/Profile';
import ReleaseNotes from '../Account/ReleaseNotes'

import EmailTemplate from '../Cruds/EmailTemplates';
import Roles from '../Cruds/Roles';
import Activities from '../Cruds/Activities'
import EmailStatus from '../Cruds/EmailStatus/index';
// Tables
import Patients from '../Cruds/Patients/index';
import Templates from '../Cruds/Templates/index';
import Forms from '../Cruds/Forms/index';
import Schedules from '../Cruds/Schedules/index';
import Chairs from '../Cruds/Chairs/index';
import Appointments from '../Cruds/Appointments/index';
import Treatments from '../Cruds/Treatments/index';
import Users from '../Cruds/Users/index';
import Procedures from '../Cruds/Procedures/index';
import TreatmentPlan from '../Cruds/TreatmentsPlan/index';
import TxPlans from '../Cruds/TxPlans/index';
import Prescriptions from '../Cruds/Prescriptions/index';
import Clinics from '../Cruds/Clinics/index';

import Pharmacies from '../Cruds/Pharmacies/index';
import TreatmentPlans from '../Cruds/TreatmentPlans/index';
import Drugs from '../Cruds/Drugs/index';
import Reasons from '../Cruds/Reasons/index';
import ClockInClockOut from '../Cruds/ClockInClockOut/index';
// import Employees from '../Cruds/Employees/index';

import Settings from '../Cruds/Settings/index'
import AdminSettings from '../Settings/index'
import Uploads from '../Cruds/Uploads/index';
// 404 page not found 
import ErrorNotFound from '../Account/404/index';
import config from '../../config/config';
import SampleForm from '../../Sample/SampleForm.jsx';
import Insurence from '../Cruds/Insurence/index';
import FeeSchedules from '../Cruds/FeeShedules/index';
import Charts from '../Cruds/Charts/index';
import Referrals from '../Cruds/Referrals/index';
import Reports from '../Cruds/Reports/index.jsx';


import ScheduleCalendar from '../Form/Fields/ScheduleCalendar';
import AvailablePrompts from '../Cruds/AvailablePrompts/index';
import AutoNotes from '../Cruds/AutoNotes/index'
import Accounts from '../Cruds/Accounts/index.jsx';
import Imaging from '../Cruds/Imaging/index.jsx';

const Tables = () => {
  let roles = localStorage.rolePermissions
    ? JSON.parse(localStorage.rolePermissions)
    : false;
  if (roles) {
    return (
      <>
        <Routes>
          <Route path='/sampleform'
            element={<SampleForm />}
          ></Route>

          <Route exact
            path="/users"
            element={
              roles['Users'] &&
                (roles['Users'] === "Edit" || roles['Users'] === "View")
                ?
                <Users />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_users"
            element={
              roles['Users'] &&
                (roles['Users'] === "Edit")
                ?
                <Users />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_users/:id"
            element={
              roles['Users'] &&
                (roles['Users'] === "Edit")
                ? <Users />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_users/:id"
            element={
              roles['Users'] &&
                (roles['Users'] === "Edit" || roles['Users'] === "View")
                ? <Users />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/clinics"
            element={
              roles['Clinics'] &&
                (roles['Clinics'] === "Edit" || roles['Clinics'] === "View")
                ? <Clinics />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_clinics"
            element={
              roles['Clinics'] &&
                (roles['Clinics'] === "Edit")
                ? <Clinics />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_clinics/:id"
            element={
              roles['Clinics'] &&
                (roles['Clinics'] === "Edit")
                ? <Clinics />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_clinics/:id"
            element={
              roles['Clinics'] &&
                (roles['Clinics'] === "Edit" || roles['Clinics'] === "View")
                ? <Clinics />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/patients"
            element={
              roles['Patients'] &&
                (roles['Patients'] === "Edit" || roles['Patients'] === "View")
                ? <Patients />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_patients"
            element={
              roles['Patients'] &&
                (roles['Patients'] === "Edit")
                ? <Patients />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_patients/:id"
            element={
              roles['Patients'] &&
                (roles['Patients'] === "Edit")
                ? <Patients />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_reasons/:id"
            element={
              roles['Services'] &&
                (roles['Services'] === "Edit" || roles['Services'] === "View")
                ? <Reasons />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/reasons"
            element={
              roles['Services'] &&
                (roles['Services'] === "Edit" || roles['Services'] === "View")
                ? <Reasons />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_reasons"
            element={
              roles['Services'] &&
                (roles['Services'] === "Edit")
                ? <Reasons />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_reasons/:id"
            element={
              roles['Services'] &&
                (roles['Services'] === "Edit")
                ? <Reasons />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_clockify/:id"
            element={
              roles['Clockify'] &&
                (roles['Clockify'] === "Edit" || roles['Clockify'] === "View")
                ? <ClockInClockOut />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/clockify"
            element={
              roles['Clockify'] &&
                (roles['Clockify'] === "Edit" || roles['Clockify'] === "View")
                ? <ClockInClockOut />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_clockify"
            element={
              roles['Clockify'] &&
                (roles['Clockify'] === "Edit")
                ? <ClockInClockOut />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_clockify/:id"
            element={
              roles['Clockify'] &&
                (roles['Clockify'] === "Edit")
                ? <ClockInClockOut />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/feeSchedules"
            element={
              roles['Fee Schedules'] &&
                (roles['Fee Schedules'] === "Edit")
                ? <FeeSchedules />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/create_feeSchedules"
            element={
              roles['Fee Schedules'] &&
                (roles['Fee Schedules'] === "Edit")
                ? <FeeSchedules />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_feeSchedules/:id"
            element={
              roles['Fee Schedules'] &&
                (roles['Fee Schedules'] === "Edit")
                ? <FeeSchedules />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_patients/:id"
            element={
              roles['Patients'] &&
                (roles['Patients'] === "Edit" || roles['Patients'] === "View")
                ? <Patients />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/forms"
            element={
              roles['Forms'] &&
                (roles['Forms'] === "Edit" || roles['Forms'] === "View")
                ? <Forms />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_forms"
            element={
              roles['Forms'] &&
                (roles['Forms'] === "Edit")
                ? <Forms />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_forms/:id"
            element={
              roles['Forms'] &&
                (roles['Forms'] === "Edit")
                ? <Forms />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_forms/:id"
            element={
              roles['Forms'] &&
                (roles['Forms'] === "Edit" || roles['Forms'] === "View")
                ? <Forms />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/appointments"
            element={
              roles['Appointments'] &&
                (roles['Appointments'] === "Edit" || roles['Appointments'] === "View")
                ? <Appointments />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_appointments"
            element={
              roles['Appointments'] &&
                (roles['Appointments'] === "Edit")
                ? <Appointments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_appointments/:id"
            element={
              roles['Appointments'] &&
                (roles['Appointments'] === "Edit")
                ? <Appointments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_appointments/:id"
            element={
              roles['Appointments'] &&
                (roles['Appointments'] === "Edit" || roles['Appointments'] === "View")
                ? <Appointments />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/schedules"
            element={
              roles['Schedules'] &&
                (roles['Schedules'] === "Edit" || roles['Schedules'] === "View")
                ? <ScheduleCalendar />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_schedules"
            element={
              roles['Schedules'] &&
                (roles['Schedules'] === "Edit")
                ? <ScheduleCalendar />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_schedules/:id"
            element={
              roles['Schedules'] &&
                (roles['Schedules'] === "Edit")
                ? <ScheduleCalendar />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_schedules/:id"
            element={
              roles['Schedules'] &&
                (roles['Schedules'] === "Edit" || roles['Schedules'] === "View")
                ? <ScheduleCalendar />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/treatments"
            element={
              roles['Treatments'] &&
                (roles['Treatments'] === "Edit" || roles['Treatments'] === "View")
                ? <Treatments />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_treatments"
            element={
              roles['Treatments'] &&
                (roles['Treatments'] === "Edit")
                ? <Treatments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_treatments/:id"
            element={
              roles['Treatments'] &&
                (roles['Treatments'] === "Edit")
                ? <Treatments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_treatments/:id"
            element={
              roles['Treatments'] &&
                (roles['Treatments'] === "Edit" || roles['Treatments'] === "View")
                ? <Treatments />
                : <ErrorNotFound />
            }
          />

          {/* <Route exact
        id="employee"
        path="/employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees />
            : <ErrorNotFound />
        } />

      <Route
        id="create-employee"
        path="/create_employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees/>
            : <ErrorNotFound />
        }
      />

      <Route
        id="edit-employee"
        path="/edit_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees  />
            : <ErrorNotFound />
        }
      />

      <Route
        key="view-employee"
        path="/view_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees  />
            : <ErrorNotFound />
        }
      /> */}

          {/* <Route path="/settings" element={Settings} /> */}
          {/* <Route path="/adminSettings" element={AdminSettings} /> */}
          <Route
            path="/adminSettings"
            element={
              config.displaySettings
                ? <AdminSettings />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/uploads"
            element={
              roles["Upload Histories"] &&
                (roles["Upload Histories"] === "Edit" || roles["Upload Histories"] === "View")
                ? <Uploads />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/activities"
            element={
              roles["Activities"] &&
                (roles["Activities"] === "Edit" || roles["Activities"] === "View")
                ? <Activities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/activities/:id"
            element={
              roles["Activities"] &&
                (roles["Activities"] === "Edit" || roles["Activities"] === "View")
                ? <Activities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_activities/:id"
            element={
              roles["Activities"] &&
                (roles["Activities"] === "Edit" || roles["Activities"] === "View")
                ? <Activities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/roles"
            element={
              roles["Roles"] &&
                (roles["Roles"] === "Edit" || roles["Roles"] === "View")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/create_roles"
            element={
              roles["Roles"] &&
                (roles["Roles"] === "Edit")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_roles/:id"
            element={
              roles["Roles"] &&
                (roles["Roles"] === "Edit")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_roles/:id"
            element={
              roles["Roles"] &&
                (roles["Roles"] === "Edit" || roles["Roles"] === "View")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/emailStatuses"
            element={
              roles["Email Statuses"] &&
                (roles["Email Statuses"] === "Edit" || roles["Email Statuses"] === "View")
                ? <EmailStatus />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/templates"
            element={
              roles['Templates'] &&
                (roles['Templates'] === "Edit" || roles['Templates'] === "View")
                ? <Templates />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_templates"
            element={
              roles['Templates'] &&
                (roles['Templates'] === "Edit")
                ? <Templates />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_templates/:id"
            element={
              roles['Templates'] &&
                (roles['Templates'] === "Edit")
                ? <Templates />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_templates/:id"
            element={
              roles['Templates'] &&
                (roles['Templates'] === "Edit" || roles['Templates'] === "View")
                ? <Templates />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/create_chairs"
            element={
              roles['Chairs'] &&
                (roles['Chairs'] === "Edit")
                ? <Chairs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_chairs/:id"
            element={
              roles['Chairs'] &&
                (roles['Chairs'] === "Edit")
                ? <Chairs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_chairs/:id"
            element={
              roles['Chairs'] &&
                (roles['Chairs'] === "Edit" || roles['Chairs'] === "View")
                ? <Chairs />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/procedures"
            element={
              roles['Procedures'] &&
                (roles['Procedures'] === "Edit" || roles['Procedures'] === "View")
                ? <Procedures />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_procedures"
            element={
              roles['Procedures'] &&
                (roles['Procedures'] === "Edit")
                ? <Procedures />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_procedures/:id"
            element={
              roles['Procedures'] &&
                (roles['Procedures'] === "Edit")
                ? <Procedures />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_procedures/:id"
            element={
              roles['Procedures'] &&
                (roles['Procedures'] === "Edit" || roles['Procedures'] === "View")
                ? <Procedures />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/treatmentPlan"
            element={
              roles['Treatment Plan'] &&
                (roles['Treatment Plan'] === "Edit" || roles['Treatment Plan'] === "View")
                ? <TreatmentPlan />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_treatmentPlan"
            element={
              roles['Treatment Plan'] &&
                (roles['Treatment Plan'] === "Edit")
                ? <TreatmentPlan />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_treatmentPlan/:id"
            element={
              roles['Treatment Plan'] &&
                (roles['Treatment Plan'] === "Edit")
                ? <TreatmentPlan />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_treatmentPlan/:id"
            element={
              roles['Treatment Plan'] &&
                (roles['Treatment Plan'] === "Edit" || roles['Treatment Plan'] === "View")
                ? <TreatmentPlan />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/insurance"
            element={
              roles['Insurance'] &&
                (roles['Insurance'] === "Edit" || roles['Insurance'] === "View")
                ? <Insurence />
                : <ErrorNotFound />
            } />
            <Route 
            path="/create_insurance"
            element={
              roles['Insurance'] &&
                (roles['Insurance'] === "Edit" || roles['Insurance'] === "View")
                ? <Insurence />
                : <ErrorNotFound />
            } />
            <Route 
             path="/edit_insurance/:id"
            element={
              roles['Insurance'] &&
                (roles['Insurance'] === "Edit" || roles['Insurance'] === "View")
                ? <Insurence />
                : <ErrorNotFound />
            } />


          <Route exact
            path="/drugs"
            element={
              roles['Drugs'] &&
                (roles['Drugs'] === "Edit" || roles['Drugs'] === "View")
                ? <Drugs />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_drugs"
            element={
              roles['Drugs'] &&
                (roles['Drugs'] === "Edit")
                ? <Drugs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_drugs/:id"
            element={
              roles['Drugs'] &&
                (roles['Drugs'] === "Edit")
                ? <Drugs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_drugs/:id"
            element={
              roles['Drugs'] &&
                (roles['Drugs'] === "Edit" || roles['Drugs'] === "View")
                ? <Drugs />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/treatmentPlans"
            element={
              roles['Treatment Plans'] &&
                (roles['Treatment Plans'] === "Edit" || roles['Treatment Plans'] === "View")
                ? <TreatmentPlans />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_treatmentPlans"
            element={
              roles['Treatment Plans'] &&
                (roles['Treatment Plans'] === "Edit")
                ? <TreatmentPlans />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_treatmentPlans/:id"
            element={
              roles['Treatment Plans'] &&
                (roles['Treatment Plans'] === "Edit")
                ? <TreatmentPlans />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_treatmentPlans/:id"
            element={
              roles['Treatment Plans'] &&
                (roles['Treatment Plans'] === "Edit" || roles['Treatment Plans'] === "View")
                ? <TreatmentPlans />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/prescriptions"
            element={
              roles['Prescriptions'] &&
                (roles['Prescriptions'] === "Edit" || roles['Prescriptions'] === "View")
                ? <Prescriptions />
                : <ErrorNotFound />
            } />
          <Route
            path="/create_prescriptions"
            element={
              roles['Prescriptions'] &&
                (roles['Prescriptions'] === "Edit")
                ? <Prescriptions />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_prescriptions/:id"
            element={
              roles['Prescriptions'] &&
                (roles['Prescriptions'] === "Edit")
                ? <Prescriptions />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_prescriptions/:id"
            element={
              roles['Prescriptions'] &&
                (roles['Prescriptions'] === "Edit" || roles['Prescriptions'] === "View")
                ? <Prescriptions />
                : <ErrorNotFound />
            }
          />

          <Route exact
            path="/txPlans"
            element={
              roles['Tx Plan'] &&
                (roles['Tx Plan'] === "Edit" || roles['Tx Plan'] === "View")
                ? <TxPlans />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_txPlan"
            element={
              roles['Tx Plan'] &&
                (roles['Tx Plan'] === "Edit")
                ? <TxPlans />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_txPlan/:id"
            element={
              roles['Tx Plan'] &&
                (roles['Tx Plan'] === "Edit")
                ? <TxPlans />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_txPlan/:id"
            element={
              roles['Tx Plans'] &&
                (roles['Tx Plans'] === "Edit" || roles['Tx Plans'] === "View")
                ? <TxPlans />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/pharmacies"
            element={
              roles['Pharmacies'] &&
                (roles['Pharmacies'] === "Edit" || roles['Pharmacies'] === "View")
                ? <Pharmacies />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_pharmacies"
            element={
              roles['Pharmacies'] &&
                (roles['Pharmacies'] === "Edit")
                ? <Pharmacies />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_pharmacies/:id"
            element={
              roles['Pharmacies'] &&
                (roles['Pharmacies'] === "Edit")
                ? <Pharmacies />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_pharmacies/:id"
            element={
              roles['Pharmacies'] &&
                (roles['Pharmacies'] === "Edit" || roles['Pharmacies'] === "View")
                ? <Pharmacies />
                : <ErrorNotFound />
            }
          />


          <Route exact
            path="/reasons"
            element={
              roles['Reasons'] &&
                (roles['Reasons'] === "Edit" || roles['Reasons'] === "View")
                ? <Reasons />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_reasons"
            element={
              roles['Reasons'] &&
                (roles['Reasons'] === "Edit")
                ? <Reasons />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_reasons/:id"
            element={
              roles['Reasons'] &&
                (roles['Reasons'] === "Edit")
                ? <Reasons />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_reasons/:id"
            element={
              roles['Reasons'] &&
                (roles['Reasons'] === "Edit" || roles['Reasons'] === "View")
                ? <Reasons />
                : <ErrorNotFound />
            }
          />
          
          <Route exact
            path="/accounts"
            element={
              roles['Accounts'] &&
                (roles['Accounts'] === "Edit" || roles['Accounts'] === "View")
                ? <Accounts />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_accounts"
            element={
              roles['Accounts'] &&
                (roles['Accounts'] === "Edit")
                ? <Accounts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_accounts/:id"
            element={
              roles['Accounts'] &&
                (roles['Accounts'] === "Edit")
                ? <Accounts />
                : <ErrorNotFound />
            }
          />
         
          <Route
            path="/view_accounts/:id"
            element={
              roles['Accounts'] &&
                (roles['Accounts'] === "Edit" || roles['Accounts'] === "View")
                ? <Accounts />
                : <ErrorNotFound />
            }
          />
          
          <Route exact
            path="/imaging"
            element={
              roles['Imaging'] &&
                (roles['Imaging'] === "Edit" || roles['Imaging'] === "View")
                ? <Imaging />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_imaging"
            element={
              roles['Imaging'] &&
                (roles['Imaging'] === "Edit")
                ? <Imaging />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_imaging/:id"
            element={
              roles['Imaging'] &&
                (roles['Imaging'] === "Edit")
                ? <Imaging />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_imaging/:id"
            element={
              roles['Imaging'] &&
                (roles['Imaging'] === "Edit" || roles['Imaging'] === "View")
                ? <Imaging />
                : <ErrorNotFound />
            }
          />
          
          <Route
            path="/templates"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit" || roles["Email Templates"] === "View")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/create_templates"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_templates/:id"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_templates/:id"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit" || roles["Email Templates"] === "View")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/autoNotes"
            element={
              roles['Auto Notes'] &&
                (roles['Auto Notes'] === "Edit" || roles['Auto Notes'] === "View")
                ? <AutoNotes />
                : <ErrorNotFound />
            }
          />
           <Route
          path="/create_autoNotes"
          element={
            roles['Auto Notes'] &&
              (roles['Auto Notes'] === "Edit" )
              ? <AutoNotes/>  
              : <ErrorNotFound/>
          }
        />
          <Route
          path="/edit_autoNotes/:id"
          element={
            roles['Auto Notes'] &&
              (roles['Auto Notes'] === "Edit" )
              ? <AutoNotes/>  
              : <ErrorNotFound/>
          }
        />
          <Route
          path="/view_autoNotes/:id"
          element={
            roles['Auto Notes'] &&
              (roles['Auto Notes'] === "Edit" || roles['Auto Notes'] === "View")
              ? <AutoNotes  />  
              : <ErrorNotFound/>
          }
        />



          <Route
            path="/charts"
            element={
              roles['Charts'] &&
                (roles['Charts'] === "Edit" || roles['Charts'] === "View")
                ? <Charts />
                : <ErrorNotFound />
            }
          />
          <Route exact
       path="/referrals"
      element={
              roles['Referrals'] &&
                (roles['Referrals'] === "Edit" || roles['Referrals'] === "View")
                ? <Referrals/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_referrals"
          element={
            roles['Referrals'] &&
              (roles['Referrals'] === "Edit" )
              ? <Referrals/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_referrals/:id"
          element={
            roles['Referrals'] &&
              (roles['Referrals'] === "Edit" )
              ? <Referrals/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_referrals/:id"
          element={
            roles['Referrals'] &&
              (roles['Referrals'] === "Edit" || roles['Referrals'] === "View")
              ? <Referrals  />  
              : <ErrorNotFound/>
            }
            />



<Route exact
         path="/availablePrompts"
          element={
              roles['Available Prompts'] &&
                (roles['Available Prompts'] === "Edit" || roles['Available Prompts'] === "View")
                ? <AvailablePrompts/>
                  : <ErrorNotFound/>
                } />
          <Route
          path="/create_availablePrompts"
          element={
            roles['Available Prompts'] &&
              (roles['Available Prompts'] === "Edit" )
              ? <AvailablePrompts/>  
              : <ErrorNotFound/>
          }
        />
          <Route
          path="/edit_availablePrompts/:id"
          element={
            roles['Available Prompts'] &&
              (roles['Available Prompts'] === "Edit" )
              ? <AvailablePrompts/>  
              : <ErrorNotFound/>
          }
        />
          <Route
          path="/view_availablePrompts/:id"
          element={
            roles['Available Prompts'] &&
              (roles['Available Prompts'] === "Edit" || roles['Available Prompts'] === "View")
              ? <AvailablePrompts  />  
              : <ErrorNotFound/>
          }
        />
        <Route exact
         path="/reports"
          element={
              roles['Reports'] &&
                (roles['Reports'] === "Edit" || roles['Reports'] === "View")
                ? <Reports/>
                  : <ErrorNotFound/>
          } />
          {/* <Route path="/uploads" element={Uploads} />
    <Route path="/activities" element={Activities} />
    <Route path="/roles" element={Roles} />
    <Route path="/templates" element={EmailTemplate} /> */}
        </Routes >
        <Outlet />
      </>
    )
  }
}

const Account = () => (
  <Routes>
    <Route path="/changepassword" element={<ChangePassword />} />
    <Route path="/profile" element={<Profile />} />

  </Routes>

);

const WrappedRoutes = () => {
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  if (loginCredentials) {
    return <div>
      <h1>Hello</h1>
      <Layout />
      <div className="container__wrap">
        <Routes>
          {/* <Route path="/" element={<Account />} /> */}
          <Route path="/*" index={true} element={<Tables />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashBoard" element={<Home />} />
          <Route path="/releasenotes" element={<ReleaseNotes />} />
        </Routes>

      </div>
      <Outlet />
    </div>
  }
  else {
    return <ErrorNotFound />
  }
}

const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/changeRecoverPassword/:enEmail" element={<ChangeRecoverPassword />} />
        <Route path="/loginChangePassword" element={<LoginChangePassword />} />
        <Route exact path="/" element={<Landing />} />
        <Route path="/log_in" element={<Landing />} />
        <Route path="/logout" element={<Landing />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />

        <Route path="/*" element={<WrappedRoutes />} />

        <Route element={<ErrorNotFound />} />


      </Routes>
      <Outlet />
    </main>
  </MainWrapper>
);

export default Router;
