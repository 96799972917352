import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from '../App/Loader';
import Buttons from 'react-bootstrap/Button';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import {
  Modal, ModalHeader, ModalBody,
  Card, CardBody, ButtonToolbar, ButtonGroup, Row, Col, Badge
} from 'reactstrap';
import DoSelectField from './Fields/DoSelectField';
import DoInputField from './Fields/DoInputField';
import DoTextareaField from './Fields/DoTextareaField';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import fetchMethodRequest from '../../config/service';
import DoAutoCompleteField from './Fields/DoAutoCompleteField';
import apiCalls from '../../config/apiCalls';



const AutoNotesFormModal = (props) => {

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm(
    {
      resolver: yupResolver(yup.object().shape({
        name: yup.string().required('Name is a required field'),
        text: yup.string().required('Text is a required field'),
      })),
    }
  );

  let [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState(props.formFields);
  const [tableRowData, setTableRowData] = useState(props.tableRowData);
  const [formType, setFormType] = useState(props.formType);
  const [availablePromptsIds, setAvailablePromptsIds] = useState([]);
  const [availablePromptsData, setAvailablePromptsData] = useState([]);



  useEffect(() => {

    if (props.formType === "edit") {
      console.log('tableRowData', tableRowData);
      getRowData(tableRowData);
      setAvailablePromptsIds(tableRowData?.availablePromptsIds)

    }
    getAvailablePrompts()
  }, []);

  const getAvailablePrompts = () => {
    const method = "GET";
    const apiUrl = apiCalls.availablePrompts;
    return fetchMethodRequest(method, apiUrl).then(async (response) => {
      if (response && response.availableprompts && response.availableprompts.length > 0) {
        setAvailablePromptsData(response.availableprompts);
      }
    });
  }

  const getRowData = async (selectedRowInfo) => {

    let fields = await props.formFields();
    fields.forEach((item) => {
      if (selectedRowInfo) {
        setValue(item.name, selectedRowInfo[item.name]);
      }
    });
  }


  // UPDATING AUTO NOTES IDS BASED ON AVAILABLE PROMPTS  
  const getModifiedPromptData = (formValues, availablePromptsData) => {
    let availablePromptsIds = [];
    // Remove the last character and split formValues.text by commas
    const textWithoutLastChar = formValues.text.substring(0, formValues.text.length - 1);
    const textArray = textWithoutLastChar.split(',');

    if (textArray.length > 0) {
      // Check each string in the textArray
      textArray.forEach((text) => {
        let textToCheck = text.trim();

        // If the text contains a colon, check only the part after the colon
        if (textToCheck.includes(':')) {
          textToCheck = textToCheck.split(':')[1].trim();
        }

        // Check if textToCheck contains any bracket and extract the value inside
        if (textToCheck.includes('[') || textToCheck.includes(']')) {
          const match = textToCheck.match(/\[([^\]]*)\]/);
          if (match) {
            textToCheck = match[1].trim();
          } else {
            // If brackets are not properly closed, use the whole text inside
            textToCheck = textToCheck.replace('[', '').replace(']', '').trim();
          }
        }

        availablePromptsData.forEach((record) => {
          if (textToCheck === record.description) {
            availablePromptsIds.push(record._id);
          }
        });
      });
    }
    return availablePromptsIds;
  };


  const sendDataToServer = (formValues, availablePromptsData) => {
    let userBody = Object.assign({}, formValues);

    const modifiedIds = getModifiedPromptData(formValues, availablePromptsData)

    if (props.formType === 'add') {
      userBody.availablePromptsIds = availablePromptsIds ? availablePromptsIds : [];
    } else if (props.formType === 'edit') {
      userBody.availablePromptsIds = modifiedIds ? modifiedIds : [];
    }

    setIsLoading(true);

    if (formValues) {
      let method, apiUrl;
      if (formType === 'edit') {
        method = 'PUT';
        apiUrl = `${props.apiUrl}/${props.tableRowData._id}`;
      } else {
        method = 'POST';
        apiUrl = props.apiUrl;
      }

      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            await props.getDataFromServer(props.filterCriteria);
            showToasterMessage(response.respMessage, 'success');
            if (props.formType === 'edit') {
              props.closeFormModal('edit', response);
            } else {
              props.closeFormModal('save', response);
            }
            reset();
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
          setIsLoading(false);
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };



  const submit = (formValues) => {
    if (formValues) {
      sendDataToServer(formValues, availablePromptsData)
    }
  }

  let closeFormModal = async () => {
    // clearFormFields();
    setTableRowData({});
    // reset();
    // props.getDataFromServer(props.filterCriteria);
    props.closeFormModal();
  }

  let getHeader = () => {
    const { type, routeTo } = props;
    return (
      <div className='d-flex mx-2'>
        <div className='col-12 px-0 pb-1'>
          <span className='float-left pt-2'>
            <h4 style={{ textTransform: 'capitalize' }}><b>
              <div className='text-white'>
                {type}
              </div>
            </b> </h4>
          </span>
        </div>
      </div>
    )
  }

  let getButtonToolbar = () => {
    let { t, formType } = props;
    return (
      <div className='d-flex mt-5'>
        <div className='col-12 px-0'>
          <span className='float-end'>
            <ButtonToolbar>
              {formType === 'add' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                {('Cancel') ? ('Cancel') : 'Cancel'}
              </Button> : formType !== 'add' ? <Button color='primary' type="button" onClick={() =>
                // setFormType("view")
                closeFormModal()
              }>
                {('Cancel') ? ('Cancel') : 'Cancel'}
              </Button> : formType === 'add' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                {('Cancel') ? ('Cancel') : 'Cancel'}
              </Button> : null}
              <Button color='primary' outline type="submit">
                {formType && formType === 'add' ?
                  ('Save') ? ('Save') : 'Save'
                  : ('Update') ? ('Update') : 'Update'
                }
              </Button>
            </ButtonToolbar>
          </span>
        </div>
      </div>
    )
  }

  const onInsert = () => {
    let inputData = getValues();
    // const text = `${inputData.text ? inputData.text : ''}${inputData.promptInput ? inputData.promptInput : ''}${inputData.availablePrompts?.description ? '[Prompt:' + inputData.availablePrompts?.description + ']'  : ''} ${','}`;
    let existingText = inputData.text ? inputData.text : '';
    let promptInput = inputData.promptInput ? inputData.promptInput : '';
    let promptDescription = inputData.availablePrompts?.description ? `[Prompt: ${inputData.availablePrompts.description}]` : '';

    let text = `${existingText}${existingText ? ',' : ''}${promptInput}${promptDescription}`;

    setAvailablePromptsIds([...availablePromptsIds, inputData.availablePrompts._id])
    setValue('text', text);
    setValue('availablePrompts', null);
    setValue('promptInput', '');
  }

  const getInsert = (i, item) => {
    const isDisable = watch('availablePrompts') && watch('promptInput')
    return (
      <div className=' col-md-2 p-1 mt-1'>
        <Button onClick={onInsert} type="button" disabled={!isDisable} className="mt-4 mx-4" style={{ fontSize: "14px" }}> Insert </Button>
      </div>
    )
  }

  const getEmptySpace = (i, item) => {
    return (
      <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6 `}`}></div>
    )
  }




  const getAutoComplete = (i, item) => {
    return (
      <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-3 `} p-1`}>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoAutoCompleteField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              item={item}
              filterField={item.filterField}
              filterValue={item.filterValue}
              filterType={item.isNotEq}
              multiple={item.isMultiple}
              fieldState={fieldState}
              errors={errors}
              screen={props.type}
              searchApi={item.searchApi}
              searchField={item.searchField}
              allow={props.allowDuplicates}
              filterFieldType={item.filterFieldType ? item.filterFieldType : null}
              placeholder={item.placeholder}
              label={item.label}
              setValue={setValue}
              // getValues={getValues}
              formType={'add'}
              populateValue={item.populateValue}
              populateField={item.populateField}
            />)}
        />
      </div>
    )
  }



  let getDropdown = (i, item) => {
    return (
      <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6 `} p-1 mt-1 ml-2`}>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              id={field.id}
              markReq={item.required}
              item={item}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
            // onChange={e => handleFnEnableControlsBasedOnValue2(e, item)}
            />)}
        />
      </div>
    )
  }

  const getAddFunctionnality = () => {
    return <div className="text-center pl-2">
      <FontAwesomeIcon
        icon="plus"
        size="lg"
        className=""
        data-toggle="tool-tip"
        title="Add"
        style={{
          // backgroundColor:
          // config.templateColor,
        }}
      // onClick={item.addFunctionality}
      />
    </div>
  }

  let getDefault = (i, item) => {
    let { t } = props;
    return (
      <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6 `} p-1 `}>
        {/* {item.addFunctionnality ?
          <div>{item.header} {getAddFunctionnality()}</div>
          : null} */}
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoInputField
              input={field}
              item={item}
              markReq={item.required}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              type={item.type ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }

  let getTextArea = (i, item) => {
    return (
      <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6 `}  p-1`}>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoTextareaField
              input={field}
              id={field.id}
              markReq={item.required}
              label={item.label}
              item={item}
              rows={8}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )

  }

  const getFields = () => {
    return (<>

      <div className='form  mb-2'>
        {
          formFields && formFields.map(getField)
        }
      </div>
    </>)
  }

  const getField = (item, i) => {
    return item.type === "relateAutoComplete" ? getAutoComplete(i, item)
      // : item.type === "dropDown" ? getDropdown(i, item)

      : item.type === "textarea" ?
        getTextArea(i, item) :
        item.type === "isButton" ?
          getInsert(i, item)
          : item.type === "isEmpty" ?
            getEmptySpace(i, item)
            : getDefault(i, item)
  }


  const getScreenBody = () => {
    return (
      <>
        <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
          <Loader loader={isLoading} />
          <div
            className={`row form mx-2 ${props.formType === 'add' ? "pt-3" : ""}`}
          >
            {getFields()}
          </div>
          {getButtonToolbar()}
        </form>
      </>
    )
  }



  //getScreenView
  let getScreenView = () => {
    let { handleSubmit, type, openFormModal } = props;

    const headerClassName = 'd-flex justify-content-between pb-2 mb-2 form_form-header';
    return (
      <div>
        {props.openFormModal ?
          <div>
            <div className={headerClassName}>
              <div >{getHeader()}</div>
            </div>
            {getScreenBody()}
          </div> : null

        }
      </div>
    );
  }


  return (
    <div>
      {getScreenView()}
    </div>
  )
}

export default AutoNotesFormModal
