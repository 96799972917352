import { React, useState } from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import config from '../../../config/config';

const CustomClockTimePicker = ({ label, field, getValues, value, onChange, fieldState, errors, isDurationPicker, format = 'h:mm A', use12Hours = true, item, type, }) => {
  // Helper function to extract time from date-time string
  const extractTime = (dateTime) => {
    return moment(dateTime).format('HH:mm');
  };
  const [error, setError] = useState("");

  const handleTimeChange = (value) => {
    console.log("valuess",value)
    const formattedTime = value ? value.format(isDurationPicker ? 'HH:mm' : 'h:mm A') : '';
    let dateWithTime = '';

    if (value && !isDurationPicker) {
      const selectedDate = getValues('date'); // Assume this returns a date string or moment object
          const dateMoment = moment(selectedDate);
          dateMoment.set({
        hour: value.hour(),
        minute: value.minute(),
              second: 0,
          });
          dateWithTime = dateMoment.toISOString();
    } else if (isDurationPicker) {
          dateWithTime = formattedTime;
      }

    if (item.validateField) {
      const otherFieldValue = getValues(item.validateField);
      const isInvalid = item.name === "clockOutDateAndTime" ?
        moment(dateWithTime).isBefore(moment(otherFieldValue)) :
        moment(dateWithTime).isAfter(moment(otherFieldValue));

      if (isInvalid) {
        setError(item.name === "clockOutDateAndTime" ?
          "Clock-out time must not be earlier than clock-in time." :
          "Clock-in time must not be later than clock-out time.");
      } else {
        setError("");
      }
    }
    onChange(dateWithTime);

  };

  item ? item : {};

  const timePickerClassName = type === "Clockify" ? "clock-in-out-picker" : "";
  const timeValue = value ? (moment(value, moment.ISO_8601, true).isValid() ? extractTime(value) : value) : null;
  return (
    <>
      <div>
        <label style={{ fontWeight: "lighter" }} htmlFor={field.name}>
          {label}
          {item?.required && (
            <FontAwesomeIcon
              color="red"
              icon={faAsterisk}
              style={{ width: '7px', marginLeft: "2px" }}
            />
          )}
        </label>
      </div>
      <TimePicker
        className={timePickerClassName}
        showSecond={false}
        value={timeValue ? moment(timeValue, isDurationPicker ? 'HH:mm' : 'h:mm A') : null}
        onChange={handleTimeChange}
        format={isDurationPicker ? 'HH:mm' : 'h:mm A'}
        use12Hours={!isDurationPicker}
        inputReadOnly
        minuteStep={isDurationPicker ? 1 : 15}
        {...(isDurationPicker ? { defaultOpenValue: moment(moment().format(config.dateFormat), config.dateFormat) } : {})}
      />
      <small className="text-danger">
        {errors[field.name] && <span>{errors[field.name].message}</span>}
        {error && <small className="text-danger">{error}</small>}
      </small>
    </>
  );
};

export default CustomClockTimePicker;
